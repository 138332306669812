import { Modal } from "antd";
import _ from "lodash";
import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";
export const cursor_to_offset = (cursor: string): number | undefined => {
  const x = _.nth(window.atob(cursor).split(":"), 1);
  return x ? parseInt(x, 10) : undefined;
};

export const offset_to_cursor = (offset: number): string => {
  return window.btoa(`arrayconnection:${offset}`);
};

export const extractNonNullableNodesOnConnection = <
  T extends { edges: readonly any[] | null }
>(
  cnn: T
) => {
  return _.filter(
    _.map(cnn.edges, (e) => e?.node),
    (n) => n
  ) as NonNullable<NonNullable<NonNullable<T["edges"]>[0]>["node"]>[];
};

export const openJSONErrorModal = (errors: any) => {
  Modal.error({
    title: "Error",
    width: 800,
    content: (
      <SyntaxHighlighter language="json" style={darcula} wrapLongLines>
        {JSON.stringify(errors, null, 2)}
      </SyntaxHighlighter>
    ),
  });
};

export const fromGlobalId = (global_id: string): string[] => {
  return atob(global_id).split(":");
};

export const toGlobalId = (type: string, id: string): string => {
  return btoa(`${type}:${id}`);
};

export const mergeWhereInputWithAND = <
  T extends { AND?: ReadonlyArray<T> | null }
>(
  ...wheres: (T | null | undefined)[]
) => {
  const all = _.filter(wheres, (w) => w !== null && w !== undefined);
  if (all.length === 0) {
    return undefined;
  } else if (all.length === 1) {
    return all[0];
  } else {
    //@ts-ignore
    const result: T = {
      AND: all as T[],
    };
    return result;
  }
};

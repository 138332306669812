/**
 * @generated SignedSource<<1069ab3af226b790ff8ed7d43d080041>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerOrder = "ACL_ASC" | "ACL_DESC" | "author_ASC" | "author_DESC" | "createdAt_ASC" | "createdAt_DESC" | "description_ASC" | "description_DESC" | "id_ASC" | "id_DESC" | "name_ASC" | "name_DESC" | "objectId_ASC" | "objectId_DESC" | "updatedAt_ASC" | "updatedAt_DESC" | "%future added value";
export type CustomerWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<CustomerWhereInput> | null;
  NOR?: ReadonlyArray<CustomerWhereInput> | null;
  OR?: ReadonlyArray<CustomerWhereInput> | null;
  author?: UserRelationWhereInput | null;
  createdAt?: DateWhereInput | null;
  description?: StringWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  updatedAt?: DateWhereInput | null;
};
export type ObjectWhereInput = {
  equalTo?: KeyValueInput | null;
  exists?: boolean | null;
  greaterThan?: KeyValueInput | null;
  greaterThanOrEqualTo?: KeyValueInput | null;
  in?: ReadonlyArray<KeyValueInput | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: KeyValueInput | null;
  lessThanOrEqualTo?: KeyValueInput | null;
  notEqualTo?: KeyValueInput | null;
  notIn?: ReadonlyArray<KeyValueInput | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
  key: string;
  value: any;
};
export type SelectInput = {
  key: string;
  query: SubqueryInput;
};
export type SubqueryInput = {
  className: string;
  where: any;
};
export type UserRelationWhereInput = {
  exists?: boolean | null;
  have?: UserWhereInput | null;
  haveNot?: UserWhereInput | null;
};
export type UserWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<UserWhereInput> | null;
  NOR?: ReadonlyArray<UserWhereInput> | null;
  OR?: ReadonlyArray<UserWhereInput> | null;
  authData?: ObjectWhereInput | null;
  createdAt?: DateWhereInput | null;
  email?: StringWhereInput | null;
  emailVerified?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  password?: StringWhereInput | null;
  protectedFields?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
  username?: StringWhereInput | null;
};
export type DateWhereInput = {
  equalTo?: any | null;
  exists?: boolean | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type StringWhereInput = {
  equalTo?: string | null;
  exists?: boolean | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: ReadonlyArray<string | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  matchesRegex?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
  notInQueryKey?: SelectInput | null;
  options?: string | null;
  text?: TextInput | null;
};
export type TextInput = {
  search: SearchInput;
};
export type SearchInput = {
  caseSensitive?: boolean | null;
  diacriticSensitive?: boolean | null;
  language?: string | null;
  term: string;
};
export type BooleanWhereInput = {
  equalTo?: boolean | null;
  exists?: boolean | null;
  inQueryKey?: SelectInput | null;
  notEqualTo?: boolean | null;
  notInQueryKey?: SelectInput | null;
};
export type IdWhereInput = {
  equalTo?: string | null;
  exists?: boolean | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: ReadonlyArray<string | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type CustomerListQuery$variables = {
  first?: number | null;
  order?: ReadonlyArray<CustomerOrder> | null;
  skip?: number | null;
  where?: CustomerWhereInput | null;
};
export type CustomerListQuery$data = {
  readonly customers: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly author: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"UserNameWithHoverDetailFragment">;
        } | null;
        readonly createdAt: any;
        readonly description: string | null;
        readonly id: string;
        readonly name: string;
        readonly objectId: string;
        readonly updatedAt: any;
        readonly " $fragmentSpreads": FragmentRefs<"CustomerFormModalCustomerFragment">;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  };
};
export type CustomerListQuery = {
  response: CustomerListQuery$data;
  variables: CustomerListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "skip",
    "variableName": "skip"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CustomerConnection",
        "kind": "LinkedField",
        "name": "customers",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserNameWithHoverDetailFragment"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CustomerFormModalCustomerFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CustomerListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CustomerConnection",
        "kind": "LinkedField",
        "name": "customers",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "426c47427bdc11e36f91ba3bfbb14025",
    "id": null,
    "metadata": {},
    "name": "CustomerListQuery",
    "operationKind": "query",
    "text": "query CustomerListQuery(\n  $first: Int\n  $skip: Int\n  $where: CustomerWhereInput\n  $order: [CustomerOrder!]\n) {\n  customers(first: $first, skip: $skip, order: $order, where: $where) {\n    count\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n    edges {\n      node {\n        id\n        objectId\n        createdAt\n        updatedAt\n        name\n        description\n        author {\n          id\n          ...UserNameWithHoverDetailFragment\n        }\n        ...CustomerFormModalCustomerFragment\n      }\n    }\n  }\n}\n\nfragment CustomerFormModalCustomerFragment on Customer {\n  id\n  objectId\n  createdAt\n  updatedAt\n  author {\n    id\n    name\n  }\n  description\n  name\n}\n\nfragment UserNameWithHoverDetailFragment on User {\n  id\n  name\n  email\n}\n"
  }
};
})();

(node as any).hash = "2f5d52d3ff917cf0daa6862489b99755";

export default node;

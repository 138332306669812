/**
 * @generated SignedSource<<5a89c0b680abac78d5dde5f83ef286c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateCustomerInput = {
  clientMutationId?: string | null;
  fields?: CreateCustomerFieldsInput | null;
};
export type CreateCustomerFieldsInput = {
  ACL?: ACLInput | null;
  author?: UserPointerInput | null;
  description?: string | null;
  name: string;
};
export type ACLInput = {
  public?: PublicACLInput | null;
  roles?: ReadonlyArray<RoleACLInput> | null;
  users?: ReadonlyArray<UserACLInput> | null;
};
export type UserACLInput = {
  read: boolean;
  userId: string;
  write: boolean;
};
export type RoleACLInput = {
  read: boolean;
  roleName: string;
  write: boolean;
};
export type PublicACLInput = {
  read: boolean;
  write: boolean;
};
export type UserPointerInput = {
  createAndLink?: CreateUserFieldsInput | null;
  link?: string | null;
};
export type CreateUserFieldsInput = {
  ACL?: ACLInput | null;
  authData?: any | null;
  email?: string | null;
  emailVerified?: boolean | null;
  name?: string | null;
  password?: string | null;
  protectedFields?: string | null;
  username?: string | null;
};
export type CustomerFormModalMutation$variables = {
  input: CreateCustomerInput;
};
export type CustomerFormModalMutation$data = {
  readonly createCustomer: {
    readonly customer: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"CustomerFormModalCustomerFragment">;
    };
  } | null;
};
export type CustomerFormModalMutation = {
  response: CustomerFormModalMutation$data;
  variables: CustomerFormModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerFormModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCustomerPayload",
        "kind": "LinkedField",
        "name": "createCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CustomerFormModalCustomerFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerFormModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCustomerPayload",
        "kind": "LinkedField",
        "name": "createCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14c1b3beca1856ee9ece185d7e6e2909",
    "id": null,
    "metadata": {},
    "name": "CustomerFormModalMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerFormModalMutation(\n  $input: CreateCustomerInput!\n) {\n  createCustomer(input: $input) {\n    customer {\n      id\n      ...CustomerFormModalCustomerFragment\n    }\n  }\n}\n\nfragment CustomerFormModalCustomerFragment on Customer {\n  id\n  objectId\n  createdAt\n  updatedAt\n  author {\n    id\n    name\n  }\n  description\n  name\n}\n"
  }
};
})();

(node as any).hash = "3ea55a479ce9efea8e27af03d7d00780";

export default node;

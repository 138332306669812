/**
 * @generated SignedSource<<fde55fc313851da59e0980c4c96788e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteOrder = "ACL_ASC" | "ACL_DESC" | "assignee_ASC" | "assignee_DESC" | "author_ASC" | "author_DESC" | "contacts_ASC" | "contacts_DESC" | "contractExpirationDate_ASC" | "contractExpirationDate_DESC" | "contractProductType_ASC" | "contractProductType_DESC" | "contractStartDate_ASC" | "contractStartDate_DESC" | "createdAt_ASC" | "createdAt_DESC" | "customer_ASC" | "customer_DESC" | "deletedAt_ASC" | "deletedAt_DESC" | "deletedBy_ASC" | "deletedBy_DESC" | "description_ASC" | "description_DESC" | "id_ASC" | "id_DESC" | "isDeleted_ASC" | "isDeleted_DESC" | "mainLicense_ASC" | "mainLicense_DESC" | "name_ASC" | "name_DESC" | "numCPUs_ASC" | "numCPUs_DESC" | "numGPUs_ASC" | "numGPUs_DESC" | "numNPUs_ASC" | "numNPUs_DESC" | "objectId_ASC" | "objectId_DESC" | "purchaseOrderId_ASC" | "purchaseOrderId_DESC" | "supportExpirationDate_ASC" | "supportExpirationDate_DESC" | "updatedAt_ASC" | "updatedAt_DESC" | "%future added value";
export type SiteWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<SiteWhereInput> | null;
  NOR?: ReadonlyArray<SiteWhereInput> | null;
  OR?: ReadonlyArray<SiteWhereInput> | null;
  assignee?: UserRelationWhereInput | null;
  author?: UserRelationWhereInput | null;
  contacts?: ContactRelationWhereInput | null;
  contractExpirationDate?: DateWhereInput | null;
  contractProductType?: StringWhereInput | null;
  contractStartDate?: DateWhereInput | null;
  createdAt?: DateWhereInput | null;
  customer?: CustomerRelationWhereInput | null;
  deletedAt?: DateWhereInput | null;
  deletedBy?: UserRelationWhereInput | null;
  description?: StringWhereInput | null;
  id?: IdWhereInput | null;
  isDeleted?: BooleanWhereInput | null;
  mainLicense?: LicenseRelationWhereInput | null;
  name?: StringWhereInput | null;
  numCPUs?: NumberWhereInput | null;
  numGPUs?: NumberWhereInput | null;
  numNPUs?: NumberWhereInput | null;
  objectId?: IdWhereInput | null;
  purchaseOrderId?: StringWhereInput | null;
  supportExpirationDate?: DateWhereInput | null;
  updatedAt?: DateWhereInput | null;
};
export type ObjectWhereInput = {
  equalTo?: KeyValueInput | null;
  exists?: boolean | null;
  greaterThan?: KeyValueInput | null;
  greaterThanOrEqualTo?: KeyValueInput | null;
  in?: ReadonlyArray<KeyValueInput | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: KeyValueInput | null;
  lessThanOrEqualTo?: KeyValueInput | null;
  notEqualTo?: KeyValueInput | null;
  notIn?: ReadonlyArray<KeyValueInput | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
  key: string;
  value: any;
};
export type SelectInput = {
  key: string;
  query: SubqueryInput;
};
export type SubqueryInput = {
  className: string;
  where: any;
};
export type UserRelationWhereInput = {
  exists?: boolean | null;
  have?: UserWhereInput | null;
  haveNot?: UserWhereInput | null;
};
export type UserWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<UserWhereInput> | null;
  NOR?: ReadonlyArray<UserWhereInput> | null;
  OR?: ReadonlyArray<UserWhereInput> | null;
  authData?: ObjectWhereInput | null;
  createdAt?: DateWhereInput | null;
  email?: StringWhereInput | null;
  emailVerified?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  password?: StringWhereInput | null;
  protectedFields?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
  username?: StringWhereInput | null;
};
export type DateWhereInput = {
  equalTo?: any | null;
  exists?: boolean | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type StringWhereInput = {
  equalTo?: string | null;
  exists?: boolean | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: ReadonlyArray<string | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  matchesRegex?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
  notInQueryKey?: SelectInput | null;
  options?: string | null;
  text?: TextInput | null;
};
export type TextInput = {
  search: SearchInput;
};
export type SearchInput = {
  caseSensitive?: boolean | null;
  diacriticSensitive?: boolean | null;
  language?: string | null;
  term: string;
};
export type BooleanWhereInput = {
  equalTo?: boolean | null;
  exists?: boolean | null;
  inQueryKey?: SelectInput | null;
  notEqualTo?: boolean | null;
  notInQueryKey?: SelectInput | null;
};
export type IdWhereInput = {
  equalTo?: string | null;
  exists?: boolean | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: ReadonlyArray<string | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type ContactRelationWhereInput = {
  exists?: boolean | null;
  have?: ContactWhereInput | null;
  haveNot?: ContactWhereInput | null;
};
export type ContactWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<ContactWhereInput> | null;
  NOR?: ReadonlyArray<ContactWhereInput> | null;
  OR?: ReadonlyArray<ContactWhereInput> | null;
  author?: UserRelationWhereInput | null;
  createdAt?: DateWhereInput | null;
  customer?: CustomerRelationWhereInput | null;
  description?: StringWhereInput | null;
  email?: StringWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  phone?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
};
export type CustomerRelationWhereInput = {
  exists?: boolean | null;
  have?: CustomerWhereInput | null;
  haveNot?: CustomerWhereInput | null;
};
export type CustomerWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<CustomerWhereInput> | null;
  NOR?: ReadonlyArray<CustomerWhereInput> | null;
  OR?: ReadonlyArray<CustomerWhereInput> | null;
  author?: UserRelationWhereInput | null;
  createdAt?: DateWhereInput | null;
  description?: StringWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  updatedAt?: DateWhereInput | null;
};
export type LicenseRelationWhereInput = {
  exists?: boolean | null;
  have?: LicenseWhereInput | null;
  haveNot?: LicenseWhereInput | null;
};
export type LicenseWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<LicenseWhereInput> | null;
  NOR?: ReadonlyArray<LicenseWhereInput> | null;
  OR?: ReadonlyArray<LicenseWhereInput> | null;
  author?: UserRelationWhereInput | null;
  certificateOptions?: ObjectWhereInput | null;
  createdAt?: DateWhereInput | null;
  description?: StringWhereInput | null;
  disabled?: BooleanWhereInput | null;
  expirationDate?: DateWhereInput | null;
  hardwareInfoList?: ArrayWhereInput | null;
  hardwareSummary?: ObjectWhereInput | null;
  id?: IdWhereInput | null;
  licenseInfo?: ObjectWhereInput | null;
  licenseType?: StringWhereInput | null;
  name?: StringWhereInput | null;
  numCPUs?: NumberWhereInput | null;
  numGPUs?: NumberWhereInput | null;
  numNPUs?: NumberWhereInput | null;
  objectId?: IdWhereInput | null;
  pemInfo?: ObjectWhereInput | null;
  site?: SiteRelationWhereInput | null;
  status?: StringWhereInput | null;
  type?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
};
export type ArrayWhereInput = {
  containedBy?: ReadonlyArray<any | null> | null;
  contains?: ReadonlyArray<any | null> | null;
  equalTo?: any | null;
  exists?: boolean | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type NumberWhereInput = {
  equalTo?: number | null;
  exists?: boolean | null;
  greaterThan?: number | null;
  greaterThanOrEqualTo?: number | null;
  in?: ReadonlyArray<number | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type SiteRelationWhereInput = {
  exists?: boolean | null;
  have?: SiteWhereInput | null;
  haveNot?: SiteWhereInput | null;
};
export type SiteListQuery$variables = {
  activeWhere?: SiteWhereInput | null;
  expireInAMonthWhere?: SiteWhereInput | null;
  first?: number | null;
  order?: ReadonlyArray<SiteOrder> | null;
  skip?: number | null;
  where?: SiteWhereInput | null;
};
export type SiteListQuery$data = {
  readonly activeSites: {
    readonly count: number;
  };
  readonly expireInAMonthSites: {
    readonly count: number;
  };
  readonly sites: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly assignee: {
          readonly id: string;
          readonly username: string | null;
        } | null;
        readonly author: {
          readonly id: string;
          readonly name: string | null;
          readonly username: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"UserNameWithHoverDetailFragment">;
        } | null;
        readonly contacts: {
          readonly count: number;
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly name: string;
            } | null;
          } | null> | null;
        };
        readonly contractExpirationDate: any | null;
        readonly createdAt: any;
        readonly customer: {
          readonly id: string;
          readonly name: string;
        } | null;
        readonly description: string | null;
        readonly id: string;
        readonly mainLicense: {
          readonly " $fragmentSpreads": FragmentRefs<"LicenseMiniViewFragment">;
        } | null;
        readonly name: string;
        readonly numCPUs: number | null;
        readonly numGPUs: number | null;
        readonly numNPUs: number | null;
        readonly objectId: string;
        readonly supportExpirationDate: any | null;
        readonly updatedAt: any;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  };
};
export type SiteListQuery = {
  response: SiteListQuery$data;
  variables: SiteListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "activeWhere"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "expireInAMonthWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v6 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "skip",
    "variableName": "skip"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numCPUs",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numGPUs",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numNPUs",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = [
  (v9/*: any*/),
  (v14/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Customer",
  "kind": "LinkedField",
  "name": "customer",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    {
      "kind": "Literal",
      "name": "order",
      "value": "updatedAt_DESC"
    }
  ],
  "concreteType": "ContactConnection",
  "kind": "LinkedField",
  "name": "contacts",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Contact",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v15/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "contacts(first:1,order:\"updatedAt_DESC\")"
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "assignee",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v20/*: any*/)
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "supportExpirationDate",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractExpirationDate",
  "storageKey": null
},
v25 = [
  (v7/*: any*/)
],
v26 = {
  "alias": "activeSites",
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "activeWhere"
    }
  ],
  "concreteType": "SiteConnection",
  "kind": "LinkedField",
  "name": "sites",
  "plural": false,
  "selections": (v25/*: any*/),
  "storageKey": null
},
v27 = {
  "alias": "expireInAMonthSites",
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "expireInAMonthWhere"
    }
  ],
  "concreteType": "SiteConnection",
  "kind": "LinkedField",
  "name": "sites",
  "plural": false,
  "selections": (v25/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "SiteConnection",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v14/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v20/*: any*/),
                      (v14/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserNameWithHoverDetailFragment"
                      }
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "License",
                    "kind": "LinkedField",
                    "name": "mainLicense",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "LicenseMiniViewFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v26/*: any*/),
      (v27/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SiteListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "SiteConnection",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v14/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v20/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "License",
                    "kind": "LinkedField",
                    "name": "mainLicense",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expirationDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v26/*: any*/),
      (v27/*: any*/)
    ]
  },
  "params": {
    "cacheID": "eef79c59ce36e2031c10ec189a4e5ad0",
    "id": null,
    "metadata": {},
    "name": "SiteListQuery",
    "operationKind": "query",
    "text": "query SiteListQuery(\n  $first: Int\n  $skip: Int\n  $where: SiteWhereInput\n  $order: [SiteOrder!]\n  $activeWhere: SiteWhereInput\n  $expireInAMonthWhere: SiteWhereInput\n) {\n  sites(first: $first, skip: $skip, order: $order, where: $where) {\n    count\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n    edges {\n      node {\n        id\n        objectId\n        numCPUs\n        numGPUs\n        numNPUs\n        customer {\n          id\n          name\n        }\n        createdAt\n        updatedAt\n        name\n        description\n        author {\n          id\n          username\n          name\n          ...UserNameWithHoverDetailFragment\n        }\n        contacts(first: 1, order: updatedAt_DESC) {\n          count\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        assignee {\n          id\n          username\n        }\n        supportExpirationDate\n        contractExpirationDate\n        mainLicense {\n          ...LicenseMiniViewFragment\n          id\n        }\n      }\n    }\n  }\n  activeSites: sites(where: $activeWhere) {\n    count\n  }\n  expireInAMonthSites: sites(where: $expireInAMonthWhere) {\n    count\n  }\n}\n\nfragment LicenseMiniViewFragment on License {\n  id\n  objectId\n  expirationDate\n  status\n}\n\nfragment UserNameWithHoverDetailFragment on User {\n  id\n  name\n  email\n}\n"
  }
};
})();

(node as any).hash = "c6af82d9d9c6f9e60826300495eae989";

export default node;

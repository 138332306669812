import { Modal, ModalProps, Typography } from "antd";
import React from "react";
import UserSelect from "./UserSelect";
import Flex from "./Flex";
import { UserWhereInput } from "./__generated__/UserSelectQuery.graphql";

interface UserSelectModalProps extends Omit<ModalProps, "onOk"> {
  userScopeRoleId?: string;
  onOk: (userIds: string[]) => void;
  description?: string | React.ReactNode;
  baseWhere?: UserWhereInput;
  selectProps?: Omit<React.ComponentProps<typeof UserSelect>, "onChange">;
}

const UserSelectModal: React.FC<UserSelectModalProps> = ({
  onOk,
  description,
  baseWhere,
  ...modalProps
}) => {
  const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>([]);
  return (
    <Modal
      destroyOnClose
      onOk={() => {
        onOk(selectedUserIds);
      }}
      {...modalProps}
    >
      <Flex direction="column" align="stretch" style={{ flex: 1 }} gap="sm">
        {description && <Typography.Text>{description}</Typography.Text>}
        <Flex>
          <UserSelect
            style={{ flex: 1 }}
            baseWhere={baseWhere}
            onChange={setSelectedUserIds}
            {...modalProps.selectProps}
          />
          <div style={{ width: 160 }} />
        </Flex>
      </Flex>
    </Modal>
  );
};

export default UserSelectModal;

import _ from "lodash";

interface HardwareSummary {
  numCPUs: number;
  accelerators: {
    [key: string]: number;
  };
  modelNames: {
    [key: string]: number;
  };
  errors?: HardwareSummaryError[];
}

interface HardwareSummaryError {
  message: string;
  fileName: string;
}

export interface HardwareInfoFile {
  name?: string;
  content: {
    machineType?: "agent" | "manager";
    machineId: string;
    numCPUs: number;
    accelerators:
      | {
          [key: string]: any;
        }
      | {
          platform: string;
          modelName: string;
        }[];
  };
}
export const getHardwareSummary = (
  infoFileList: HardwareInfoFile[],
  type: "floating" | "fixed" = "fixed"
): HardwareSummary => {
  const result: HardwareSummary = infoFileList.reduce(
    (acc, file) => {
      _.isNumber(file.content.numCPUs) && (acc.numCPUs += file.content.numCPUs);
      if (_.isArray(file.content.accelerators)) {
        console.log(type);
        if (type === "floating") {
          acc.errors.push({
            fileName: file.name || "",
            message: "'accelerators' should not be an array with floating type",
          });
        }
        file.content.accelerators.forEach((accInfo) => {
          acc.accelerators[accInfo.platform] =
            (acc.accelerators[accInfo.platform] || 0) + 1;
          acc.modelNames[accInfo.modelName] =
            (acc.modelNames[accInfo.modelName] || 0) + 1;
        });
      } else {
        if (type === "fixed") {
          acc.errors.push({
            fileName: file.name || "",
            message: "'accelerators' should be an array with fixed type",
          });
        }
        _.each(file.content.accelerators, (value, key) => {
          acc.accelerators[key] = (acc.accelerators[key] || 0) + value;
        });
      }
      return acc;
    },
    {
      numCPUs: 0,
      accelerators: {},
      modelNames: {},
      errors: [],
    } as Required<HardwareSummary>
  );

  if (result.errors?.length == 0) {
    result.errors = undefined;
  }
  return result;
};

import { SmileOutlined } from "@ant-design/icons";
import { Result, Typography } from "antd";
import { useAuth } from "../hooks/auth";
import Flex from "../components/Flex";

const Home = () => {
  const { roleNames } = useAuth();
  return (
    <Result
      icon={<Typography.Title>🏀</Typography.Title>}
      title="Welcome to the BackOffice!"
      // extra={<Button type="primary">Next</Button>}
      subTitle={
        <Flex direction="column">
          {roleNames.length === 0 && (
            <Typography.Text type="danger">
              {'You are not granted any "ROLE" yet. '}
            </Typography.Text>
          )}
          <Typography.Text type="secondary">
            {
              'To use a specific feature in BackOffice, your account needs to be granted the required "ROLE". Please send a request regarding this matter to Jongeun Lee (jongeun@lablup.com).'
            }
          </Typography.Text>
        </Flex>
      }
    />
  );
};
export default Home;

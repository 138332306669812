/**
 * @generated SignedSource<<2f015b7ea7c1b950067c4fabba2d9153>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserViewQuery$variables = {};
export type UserViewQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"RoleUserViewFragment">;
  };
};
export type UserViewQuery = {
  response: UserViewQuery$data;
  variables: UserViewQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserViewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RoleUserViewFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserViewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roleNames",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e0ce4d5a4c7786260e5ad6c381a427d",
    "id": null,
    "metadata": {},
    "name": "UserViewQuery",
    "operationKind": "query",
    "text": "query UserViewQuery {\n  viewer {\n    ...RoleUserViewFragment\n  }\n}\n\nfragment RoleUserViewFragment on Viewer {\n  roleNames\n}\n"
  }
};

(node as any).hash = "1560aa4a347980140302fc4ee0c15648";

export default node;

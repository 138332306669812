import { RefSelectProps, Select, SelectProps } from "antd";
import React, { useDeferredValue, useState, useTransition } from "react";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  CustomerSelectQuery,
  CustomerWhereInput,
} from "./__generated__/CustomerSelectQuery.graphql";
import _ from "lodash";

interface CustomerSelectProps extends SelectProps {
  baseWhere?: CustomerWhereInput;
  onChangeSelectedWhere?: (whereInput: CustomerWhereInput | null) => void;
}

const CustomerSelect = React.forwardRef<RefSelectProps, CustomerSelectProps>(
  ({ baseWhere, onChangeSelectedWhere, onChange, ...selectProps }, ref) => {
    const [searchValue, setSearchValue] = useState("");

    const searchWhere: CustomerWhereInput = {
      name: {
        matchesRegex: "(?i)" + searchValue,
      },
    };

    const deferredValue = useDeferredValue(selectProps.value);
    const currentValueWhere: CustomerWhereInput | null =
      deferredValue && searchValue.length === 0
        ? {
            id: {
              equalTo: deferredValue,
            },
          }
        : null;

    const { customers } = useLazyLoadQuery<CustomerSelectQuery>(
      graphql`
        query CustomerSelectQuery($where: CustomerWhereInput) {
          customers(where: $where) {
            edges {
              node {
                id
                objectId
                name
              }
            }
          }
        }
      `,
      {
        where: {
          OR: [
            ...(currentValueWhere ? [currentValueWhere] : []),
            {
              AND: [
                ...(baseWhere ? [baseWhere] : []),
                ...(searchWhere ? [searchWhere] : []),
              ],
            },
          ],
        },
      },
      {
        fetchPolicy: "store-and-network",
      }
    );

    const [isPending, startTransition] = useTransition();
    return (
      <Select
        ref={ref}
        onChange={(value, options) => {
          onChange && onChange(value, options);
          onChangeSelectedWhere &&
            onChangeSelectedWhere(
              value
                ? {
                    id: {
                      equalTo: value,
                    },
                  }
                : null
            );
        }}
        {...selectProps}
        showSearch
        filterOption={false}
        onSearch={(value) => {
          startTransition(() => {
            setSearchValue(value);
          });
        }}
      >
        {_.map(customers.edges, (edge) => {
          return (
            <Select.Option key={edge?.node?.id} value={edge?.node?.id}>
              {edge?.node?.name}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
);

export default CustomerSelect;

import {
  FileOutlined,
  DownloadOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { useToggle } from "ahooks";
import { Button, message, Tooltip } from "antd";
import { ButtonProps } from "antd/es/button";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

type codeButtonTypes = "view" | "download" | "copy";
const CodeButtons: React.FC<{
  code: string;
  language: string;
  filename: string;
  modalTitle?: string;
  types?: codeButtonTypes[];
  buttonProps?: Pick<ButtonProps, "size" | "disabled">;
  downloadText?: string;
  viewText?: string;
  copyText?: string;
}> = ({
  code,
  language,
  filename,
  modalTitle,
  types = ["download", "copy"],
  buttonProps = {},
  downloadText,
  viewText = "View",
  copyText,
}) => {
  const [visibleSyntaxModal, { toggle: toggleSyntaxModal }] = useToggle(false);
  return (
    <>
      {/* {types.includes("view") && (
        <>
          <Button
            {...buttonProps}
            icon={<FileOutlined />}
            onClick={toggleSyntaxModal}
          >
            {viewText}
          </Button>
          <SyntaxHighlighterModal
            title={modalTitle || filename}
            width={800}
            open={visibleSyntaxModal}
            onCancel={toggleSyntaxModal}
            onOk={toggleSyntaxModal}
            code={code}
            language={language}
          />
        </>
      )} */}
      {types.includes("download") && (
        <Tooltip title="Download">
          <Button
            {...buttonProps}
            icon={<DownloadOutlined />}
            onClick={() => {
              const element = document.createElement("a");
              const file = new Blob([code], {
                type: "text/plain",
              });
              const url = URL.createObjectURL(file);
              element.href = url;
              element.download = filename;
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);
              URL.revokeObjectURL(url);
            }}
          >
            {downloadText}
          </Button>
        </Tooltip>
      )}
      {types.includes("copy") && (
        <Tooltip title="Copy to clipboard">
          <CopyToClipboard
            text={code}
            onCopy={() => {
              message.success("Copied to clipboard");
            }}
          >
            <Button {...buttonProps} icon={<CopyOutlined />}>
              {copyText}
            </Button>
          </CopyToClipboard>
        </Tooltip>
      )}
    </>
  );
};

export default CodeButtons;

import { ClearOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import _ from "lodash";
import React from "react";
import Flex from "./Flex";

interface Props {
  whereInputs: any[];
  onRemove: (index: number) => void;
}
const WhereInputTagList: React.FC<Props> = ({ onRemove, whereInputs }) => {
  return (
    <Flex direction="row" wrap="wrap">
      {_.map(whereInputs, (where, index) => (
        <Tag
          key={`${index}/${whereInputs.length}`}
          closable
          onClose={() => onRemove(index)}
        >
          {_.map(
            where,
            (value, key) => `${key}: ${value.matchesRegex.replace("(?i)", "")}`
          )}
        </Tag>
      ))}
    </Flex>
  );
};

export default WhereInputTagList;

/**
 * @generated SignedSource<<01a8aae5bff6e085d83332e83b459f4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserNameWithHoverDetailFragment$data = {
  readonly email: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentType": "UserNameWithHoverDetailFragment";
};
export type UserNameWithHoverDetailFragment$key = {
  readonly " $data"?: UserNameWithHoverDetailFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserNameWithHoverDetailFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserNameWithHoverDetailFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "339d42fb74d92400e44d30c8dbdab472";

export default node;

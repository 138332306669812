/**
 * @generated SignedSource<<026b79ffcedd08c80197a46e04351a7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateCustomerInput = {
  clientMutationId?: string | null;
  fields?: UpdateCustomerFieldsInput | null;
  id: string;
};
export type UpdateCustomerFieldsInput = {
  ACL?: ACLInput | null;
  author?: UserPointerInput | null;
  description?: string | null;
  name?: string | null;
};
export type ACLInput = {
  public?: PublicACLInput | null;
  roles?: ReadonlyArray<RoleACLInput> | null;
  users?: ReadonlyArray<UserACLInput> | null;
};
export type UserACLInput = {
  read: boolean;
  userId: string;
  write: boolean;
};
export type RoleACLInput = {
  read: boolean;
  roleName: string;
  write: boolean;
};
export type PublicACLInput = {
  read: boolean;
  write: boolean;
};
export type UserPointerInput = {
  createAndLink?: CreateUserFieldsInput | null;
  link?: string | null;
};
export type CreateUserFieldsInput = {
  ACL?: ACLInput | null;
  authData?: any | null;
  email?: string | null;
  emailVerified?: boolean | null;
  name?: string | null;
  password?: string | null;
  protectedFields?: string | null;
  username?: string | null;
};
export type CustomerFormModalUpdateMutation$variables = {
  input: UpdateCustomerInput;
};
export type CustomerFormModalUpdateMutation$data = {
  readonly updateCustomer: {
    readonly customer: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"CustomerFormModalCustomerFragment">;
    };
  } | null;
};
export type CustomerFormModalUpdateMutation = {
  response: CustomerFormModalUpdateMutation$data;
  variables: CustomerFormModalUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerFormModalUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCustomerPayload",
        "kind": "LinkedField",
        "name": "updateCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CustomerFormModalCustomerFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerFormModalUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCustomerPayload",
        "kind": "LinkedField",
        "name": "updateCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db33cb4381d213e8295f80a00b38188e",
    "id": null,
    "metadata": {},
    "name": "CustomerFormModalUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerFormModalUpdateMutation(\n  $input: UpdateCustomerInput!\n) {\n  updateCustomer(input: $input) {\n    customer {\n      id\n      ...CustomerFormModalCustomerFragment\n    }\n  }\n}\n\nfragment CustomerFormModalCustomerFragment on Customer {\n  id\n  objectId\n  createdAt\n  updatedAt\n  author {\n    id\n    name\n  }\n  description\n  name\n}\n"
  }
};
})();

(node as any).hash = "03cbc68ba0dc374125577159d6ca5429";

export default node;

import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  Select,
  Typography,
  theme,
} from "antd";
import React, { Suspense, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import WhiteSpace from "../WhiteSpace";
import graphql from "babel-plugin-relay/macro";
import {
  CreateSiteInput,
  SiteFormModalMutation,
} from "./__generated__/SiteFormModalMutation.graphql";
import CustomerSelect from "../CustomerSelect";
import { openJSONErrorModal } from "../../helpers";
import UserSelect from "../UserSelect";
import ContactSelect from "../ContactSelect";
import { SiteFormModalUpdateMutation } from "./__generated__/SiteFormModalUpdateMutation.graphql";
import { SiteFormModalSiteFragment$key } from "./__generated__/SiteFormModalSiteFragment.graphql";
import Flex from "../Flex";
import { values } from "lodash";
import dayjs, { Dayjs } from "dayjs";
import {
  LoadingOutlined,
  PhoneOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import ContactFormModal from "./ContactFormModal";
import _ from "lodash";

interface SiteFormModalProps extends ModalProps {
  siteFrgmt?: SiteFormModalSiteFragment$key | null;
  onRequestClose: (completed: boolean) => void;
}
interface ContactPointerInput {
  id: string;
  links?: Array<{ id: string }>;
}

interface SiteFormInput {
  name: string;
  description: string;
  customerId: string;
  assigneeId: string;
  contactIds: string[];
  numCPUs: number;
  numGPUs: number;
  numNPUs: number;
  // contact: { id: string }[];
  purchaseOrderId: string;
  contractProductType: string;
  contractStartDate: Dayjs;
  contractExpirationDate: Dayjs;
  supportExpirationDate: Dayjs;
}

const SiteFormModal: React.FC<SiteFormModalProps> = ({
  onRequestClose,
  siteFrgmt = null,
  ...modalProps
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm<SiteFormInput>();
  // const numCPUs = Form.useWatch("numCPUs", form);
  // const numGPUs = Form.useWatch("numGPUs", form);

  const site = useFragment(
    graphql`
      fragment SiteFormModalSiteFragment on Site {
        id
        name
        description
        assignee {
          id
          username
        }
        customer {
          id
          name
        }
        contacts(first: 100, order: updatedAt_DESC) {
          count
          edges {
            node {
              id
              name
            }
          }
        }
        author {
          id
          username
        }
        # mainLicense {
        #   numCPUs
        #   numGPUs
        # }
        contractProductType
        contractStartDate
        contractExpirationDate
        supportExpirationDate
        purchaseOrderId
        numCPUs
        numGPUs
        numNPUs
      }
    `,
    siteFrgmt
  );

  const [commitCreate, isInFlightCrete] =
    useMutation<SiteFormModalMutation>(graphql`
      mutation SiteFormModalMutation($input: CreateSiteInput!) {
        createSite(input: $input) {
          site {
            ...SiteFormModalSiteFragment
          }
        }
      }
    `);

  const [commitUpdate, isInFlightUpdate] =
    useMutation<SiteFormModalUpdateMutation>(graphql`
      mutation SiteFormModalUpdateMutation($input: UpdateSiteInput!) {
        updateSite(input: $input) {
          site {
            ...SiteFormModalSiteFragment
          }
        }
      }
    `);

  return (
    <>
      <Modal
        title="Site Editor"
        okText={"Save"}
        destroyOnClose={true}
        width={600}
        onOk={() => {
          form
            .validateFields()
            .then(
              ({
                customerId,
                assigneeId,
                contractStartDate,
                contractExpirationDate,
                supportExpirationDate,
                contactIds,
                ...values
              }) => {
                // find add or remove contact
                const originContactIds = _.map(
                  site?.contacts.edges,
                  (e) => e?.node?.id as string
                ).filter((id) => !!id);

                const contactIdsToAdd = _.filter(
                  contactIds,
                  (id) => !originContactIds.includes(id)
                );
                const contactIdsToRemove = _.without(
                  originContactIds,
                  ...contactIds
                );
                const input: CreateSiteInput = {
                  fields: {
                    ...values,
                    customer: {
                      link: customerId,
                    },
                    assignee: assigneeId
                      ? {
                          link: assigneeId,
                        }
                      : undefined,
                    numCPUs: values.numCPUs || 0,
                    numGPUs: values.numGPUs || 0,
                    numNPUs: values.numNPUs || 0,
                    contacts:
                      // empty array and empty object are not allowed
                      contactIdsToAdd.length || contactIdsToRemove.length
                        ? {
                            add:
                              contactIdsToAdd.length > 0
                                ? contactIdsToAdd
                                : undefined,
                            remove:
                              contactIdsToRemove.length > 0
                                ? contactIdsToRemove
                                : undefined,
                          }
                        : undefined,
                    contractStartDate:
                      contractStartDate &&
                      dayjs(contractStartDate)
                        .tz("Asia/Seoul")
                        .startOf("day")
                        .toISOString(),
                    contractExpirationDate:
                      contractExpirationDate &&
                      dayjs(contractExpirationDate)
                        .tz("Asia/Seoul")
                        .endOf("day")
                        .toISOString(),
                    supportExpirationDate:
                      supportExpirationDate &&
                      dayjs(supportExpirationDate)
                        .tz("Asia/Seoul")
                        .endOf("day")
                        .toISOString(),
                  },
                };

                site?.id
                  ? commitUpdate({
                      variables: {
                        input: {
                          id: site.id,
                          ...input,
                        },
                      },
                      onCompleted(response, errors) {
                        if (errors) {
                          openJSONErrorModal(errors);
                        } else {
                          onRequestClose(true);
                        }
                      },
                      onError: openJSONErrorModal,
                    })
                  : commitCreate({
                      variables: {
                        input,
                      },
                      onCompleted(response, errors) {
                        if (errors) {
                          openJSONErrorModal(errors);
                        } else {
                          onRequestClose(true);
                        }
                      },
                      onError: openJSONErrorModal,
                    });
              }
            );
        }}
        maskClosable={false}
        onCancel={() => onRequestClose(false)}
        {...modalProps}
      >
        <WhiteSpace />
        <Form
          form={form}
          preserve={false}
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={
            site
              ? ({
                  name: site.name,
                  description: site.description,
                  customerId: site.customer?.id,
                  // assigneeId: site.assignee?.id,
                  // contactIds: site.contact?.id,
                  contactIds: _.map(site.contacts.edges, (e) => e?.node?.id),
                  contractProductType: site.contractProductType,
                  contractStartDate:
                    site.contractStartDate && dayjs(site.contractStartDate),
                  contractExpirationDate:
                    site.contractExpirationDate &&
                    dayjs(site.contractExpirationDate),
                  supportExpirationDate:
                    site.supportExpirationDate &&
                    dayjs(site.supportExpirationDate),
                  purchaseOrderId: site.purchaseOrderId,
                  numCPUs: site.numCPUs || 0,
                  numGPUs: site.numGPUs || 0,
                  numNPUs: site.numNPUs || 0,
                } as SiteFormInput)
              : {
                  contactIds: [],
                  numCPUs: 0,
                  numGPUs: 0,
                  numNPUs: 0,
                }
          }
        >
          <Form.Item
            label="Customer"
            name="customerId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <CustomerSelect />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item label="Product Type" name="contractProductType">
            <Input />
          </Form.Item>
          <Form.Item label="# PO" name="purchaseOrderId">
            <Input />
          </Form.Item>
          <Form.Item
            label="Number of CPU(s)"
            name="numCPUs"
            // extra={
            //   site?.mainLicense && site.mainLicense.numCPUs !== numCPUs ? (
            //     <Button
            //       type="text"
            //       style={{
            //         color: token.colorLink,
            //         paddingLeft: 0,
            //         paddingRight: 0,
            //       }}
            //       icon={<WarningOutlined />}
            //       onClick={() => {
            //         form.setFieldValue("numCPUs", site.mainLicense?.numCPUs);
            //       }}
            //     >
            //       Click here to match with main license (
            //       {site.mainLicense.numCPUs})
            //     </Button>
            //   ) : null
            // }
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Number of GPU(s)"
            name="numGPUs"
            // extra={
            //   site?.mainLicense && site.mainLicense.numGPUs !== numGPUs ? (
            //     <Button
            //       type="text"
            //       style={{
            //         color: token.colorLink,
            //         paddingLeft: 0,
            //         paddingRight: 0,
            //       }}
            //       icon={<WarningOutlined />}
            //       onClick={() => {
            //         form.setFieldValue("numGPUs", site.mainLicense?.numGPUs);
            //       }}
            //     >
            //       Click here to match with main license (
            //       {site.mainLicense.numGPUs})
            //     </Button>
            //   ) : null
            // }
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Number of NPU(s)"
            name="numNPUs"
            // extra={
            //   site?.mainLicense && site.mainLicense.numGPUs !== numGPUs ? (
            //     <Button
            //       type="text"
            //       style={{
            //         color: token.colorLink,
            //         paddingLeft: 0,
            //         paddingRight: 0,
            //       }}
            //       icon={<WarningOutlined />}
            //       onClick={() => {
            //         form.setFieldValue("numGPUs", site.mainLicense?.numGPUs);
            //       }}
            //     >
            //       Click here to match with main license (
            //       {site.mainLicense.numGPUs})
            //     </Button>
            //   ) : null
            // }
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item label="Contract start" name="contractStartDate">
            <DatePicker />
          </Form.Item>
          <Form.Item label="Contract expiration" name="contractExpirationDate">
            <DatePicker />
          </Form.Item>
          <Form.Item label="Support expiration" name="supportExpirationDate">
            <DatePicker />
          </Form.Item>
          {/* 
        <Form.Item label="Assignee" name="assigneeId">
          <UserSelect allowClear />
        </Form.Item> */}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.customerId != curValues.customerId
            }
          >
            {({ getFieldValue }) => {
              const selectedCustomerId = getFieldValue("customerId");
              return (
                <Suspense
                  fallback={
                    <Form.Item
                      label={
                        <>
                          <PhoneOutlined />
                          &nbsp;Contact
                        </>
                      }
                      name="contactIds"
                      help={
                        selectedCustomerId
                          ? ""
                          : "After selecting a customer, you can select a contact."
                      }
                    ></Form.Item>
                  }
                >
                  <Form.Item
                    label={
                      <>
                        <PhoneOutlined />
                        &nbsp;Contact
                      </>
                    }
                    name="contactIds"
                    help={
                      selectedCustomerId
                        ? ""
                        : "After selecting a customer, you can select a contact."
                    }
                  >
                    <ContactSelect
                      mode="multiple"
                      disabled={!selectedCustomerId}
                      baseWhere={
                        selectedCustomerId
                          ? {
                              customer: {
                                have: {
                                  id: {
                                    equalTo: selectedCustomerId,
                                  },
                                },
                              },
                            }
                          : undefined
                      }
                    />
                  </Form.Item>
                </Suspense>
              );
            }}
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea placeholder="Description" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SiteFormModal;

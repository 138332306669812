import { Badge, BadgeProps, Calendar, Spin, theme, Typography } from "antd";
import React, { useDeferredValue, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import Flex from "./Flex";
import graphql from "babel-plugin-relay/macro";
import {
  SiteCalendarQuery,
  SiteWhereInput,
} from "./__generated__/SiteCalendarQuery.graphql";
import { mergeWhereInputWithAND } from "../helpers";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import "./SiteCalendar.css";
import {
  SafetyCertificateTwoTone,
  ToolOutlined,
  ToolTwoTone,
} from "@ant-design/icons";
import WhiteSpace from "./WhiteSpace";
import { useNavigate } from "react-router-dom";

interface SiteCalendarProps {
  whereInput: SiteWhereInput | null;
}
const SiteCalendar: React.FC<SiteCalendarProps> = ({ whereInput }) => {
  const { token } = theme.useToken();
  const [value, setValue] = useState<dayjs.Dayjs>(dayjs().startOf("month"));
  const deferredValue = useDeferredValue(value);
  const { sites } = useLazyLoadQuery<SiteCalendarQuery>(
    graphql`
      query SiteCalendarQuery($where: SiteWhereInput) {
        sites(where: $where) {
          count
          edges {
            node {
              id
              objectId
              name
              supportExpirationDate
              contractExpirationDate
              customer {
                name
                id
              }
            }
          }
        }
      }
    `,
    {
      where: mergeWhereInputWithAND<SiteWhereInput>(whereInput, {
        OR: [
          {
            AND: [
              {
                supportExpirationDate: {
                  greaterThan: deferredValue
                    .startOf("month")
                    .startOf("week")
                    .toISOString(),
                },
              },
              {
                supportExpirationDate: {
                  lessThanOrEqualTo: deferredValue
                    .endOf("month")
                    .endOf("week")
                    .toISOString(),
                },
              },
            ],
          },
          {
            AND: [
              {
                contractExpirationDate: {
                  greaterThan: deferredValue
                    .startOf("month")
                    .startOf("week")
                    .toISOString(),
                },
              },
              {
                contractExpirationDate: {
                  lessThanOrEqualTo: deferredValue
                    .endOf("month")
                    .endOf("week")
                    .toISOString(),
                },
              },
            ],
          },
        ],
      }),
    },
    {
      fetchPolicy: "store-and-network",
    }
  );

  const dateDataMap = _.chain(sites.edges)
    .map((e) => e?.node)
    .filter((n) => !!n)
    .map((e) => [
      {
        date: e?.supportExpirationDate,
        type: "supportExpiration",
        siteId: e?.id,
        siteObjectId: e?.objectId,
        siteName: e?.name,
        customerName: e?.customer?.name,
        badgeType: "error",
      },
      {
        date: e?.contractExpirationDate,
        type: "contractExpiration",
        siteId: e?.id,
        siteObjectId: e?.objectId,
        siteName: e?.name,
        customerName: e?.customer?.name,
        badgeType: "warning",
      },
    ])
    .flatten()
    .groupBy((e) => e.date)
    .mapKeys((v, k) =>
      k ? dayjs(k).endOf("day").format("YYYY-MM-DD") : "none"
    )
    .value();

  const navigate = useNavigate();
  return (
    <Flex
      style={{
        padding: token.paddingMD,
        backgroundColor: token.colorBgContainer,
      }}
    >
      <Spin spinning={value !== deferredValue}>
        <Calendar
          value={value}
          onSelect={(newValue) => {
            setValue(newValue as dayjs.Dayjs);
          }}
          dateCellRender={(value) => {
            // _.map(sites.edges, (edge) =>
            //   edge?.node;
            const listData =
              dateDataMap[value.endOf("day").format("YYYY-MM-DD")];
            return (
              <ul className="events">
                {_.map(listData, (item) => (
                  <li key={item.siteId + item.type}>
                    <Flex
                      direction="row"
                      onClick={() => {
                        navigate(`/sites/${item.siteObjectId}`);
                      }}
                    >
                      {item.type === "supportExpiration" && (
                        <ToolTwoTone twoToneColor="#eb2f96" />
                      )}
                      {item.type === "contractExpiration" && (
                        <SafetyCertificateTwoTone twoToneColor="#eb2f96" />
                      )}
                      <WhiteSpace direction="row" size="xs" />
                      <Typography.Text ellipsis>
                        {item.siteName}
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: token.fontSizeSM }}
                        >
                          @{item.customerName}
                        </Typography.Text>
                      </Typography.Text>
                    </Flex>
                  </li>
                ))}
              </ul>
            );
          }}
          mode="month"
        />
      </Spin>
    </Flex>
  );
};

export default SiteCalendar;

/**
 * @generated SignedSource<<46685f1f489b2b128ca5327ee217ef64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type authLogoutMutation$variables = {};
export type authLogoutMutation$data = {
  readonly logOut: {
    readonly ok: boolean;
  } | null;
};
export type authLogoutMutation = {
  response: authLogoutMutation$data;
  variables: authLogoutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {}
      }
    ],
    "concreteType": "LogOutPayload",
    "kind": "LinkedField",
    "name": "logOut",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": "logOut(input:{})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "authLogoutMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "authLogoutMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "267d768ae25eb7e49ea20590854e6a40",
    "id": null,
    "metadata": {},
    "name": "authLogoutMutation",
    "operationKind": "mutation",
    "text": "mutation authLogoutMutation {\n  logOut(input: {}) {\n    ok\n  }\n}\n"
  }
};
})();

(node as any).hash = "431d61c9c1c0b9418202c6d34293286c";

export default node;

/**
 * @generated SignedSource<<65ca10224150c8113ed4861ad5dddafb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerFormModalCustomerFragment$data = {
  readonly author: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly createdAt: any;
  readonly description: string | null;
  readonly id: string;
  readonly name: string;
  readonly objectId: string;
  readonly updatedAt: any;
  readonly " $fragmentType": "CustomerFormModalCustomerFragment";
};
export type CustomerFormModalCustomerFragment$key = {
  readonly " $data"?: CustomerFormModalCustomerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerFormModalCustomerFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerFormModalCustomerFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "Customer",
  "abstractKey": null
};
})();

(node as any).hash = "6808a360dd98df627d94b3c19a90581d";

export default node;

import { RefSelectProps, Select, SelectProps } from "antd";
import React, { useDeferredValue, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  UserSelectQuery,
  UserWhereInput,
} from "./__generated__/UserSelectQuery.graphql";
import _ from "lodash";
import TextHighlighter from "./TextHighlighter";

interface UserSelectProps extends Omit<SelectProps, "value"> {
  baseWhere?: UserWhereInput;
  value?: string;
  onChangeSelectedWhere?: (whereInput: UserWhereInput | null) => void;
}

const UserSelect = React.forwardRef<RefSelectProps, UserSelectProps>(
  (
    { baseWhere, onChangeSelectedWhere, onChange, value, ...selectProps },
    ref
  ) => {
    const [searchValue, setSearchValue] = useState("");
    const deferredSearchValue = useDeferredValue(searchValue);

    const searchWhere: UserWhereInput | undefined =
      deferredSearchValue.length > 0
        ? {
            OR: [
              {
                name: {
                  matchesRegex: "(?i)" + deferredSearchValue,
                },
              },
              {
                email: {
                  matchesRegex: "(?i)" + deferredSearchValue,
                },
              },
            ],
          }
        : undefined;

    const deferredValue = useDeferredValue(value);

    const { users, user } = useLazyLoadQuery<UserSelectQuery>(
      graphql`
        query UserSelectQuery(
          $where: UserWhereInput
          $skipCurrentUser: Boolean!
          $selectedUserId: ID!
        ) {
          users(where: $where) {
            edges {
              node {
                id
                objectId
                username
                name
                email
              }
            }
          }

          user(id: $selectedUserId) @skip(if: $skipCurrentUser) {
            id
            objectId
            username
            name
            email
          }
        }
      `,
      {
        where: baseWhere
          ? {
              AND: [...[baseWhere], ...(searchWhere ? [searchWhere] : [])],
            }
          : searchWhere,
        selectedUserId: deferredValue || "",
        skipCurrentUser: !deferredValue,
      },
      {
        fetchPolicy: "network-only",
      }
    );

    const currentUser = _.find(
      [...(users?.edges || []), { node: user }],
      (edge) => {
        const user = edge?.node;
        return user?.id === value;
      }
    )?.node;

    const currentValueWithLabel = value && {
      value: value,
      label: currentUser ? `${currentUser.name} (${currentUser.email})` : value,
    };

    return (
      <Select
        ref={ref}
        onChange={(value, options) => {
          console.log(value);
          onChange && onChange(value, options);
          onChangeSelectedWhere &&
            onChangeSelectedWhere(
              value
                ? {
                    id: {
                      equalTo: value,
                    },
                  }
                : null
            );
        }}
        value={currentValueWithLabel}
        {...selectProps}
        showSearch
        filterOption={false}
        onSearch={(value) => {
          setSearchValue(value);
        }}
      >
        {_.map(users?.edges, (edge) => {
          return (
            <Select.Option key={edge?.node?.id} value={edge?.node?.id}>
              <TextHighlighter keyword={deferredSearchValue}>
                {`${edge?.node?.name} (${edge?.node?.email})`}
              </TextHighlighter>
            </Select.Option>
          );
        })}
      </Select>
    );
  }
);

export default UserSelect;

import {
  CarOutlined,
  ContactsOutlined,
  PhoneOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Row,
  Button,
  Col,
  Table,
  Typography,
  Input,
  Select,
  message,
  theme,
} from "antd";
import dayjs from "dayjs";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  CustomerListQuery,
  CustomerOrder,
  CustomerWhereInput,
} from "./__generated__/CustomerListQuery.graphql";
import _ from "lodash";
import { extractNonNullableNodesOnConnection } from "../helpers";
import { useState, useTransition } from "react";
import {
  useParseAntdTablePagination,
  useWhereInputList,
} from "../hooks/parseQueryAntdTable";
import Flex from "../components/Flex";
import WhiteSpace from "../components/WhiteSpace";
import WhereInputTagList from "../components/WhereInputTagList";
import { useToggle, useUpdateEffect } from "ahooks";
import CustomerFormModal from "../components/modals/CustomerFormModal";
import { useUpdatableState } from "../hooks";
import BackOfficeBreadcrumb from "../components/BackOfficeBreadcrumb";
import { JsonParam, useQueryParams, withDefault } from "use-query-params";
import { CustomerFormModalCustomerFragment$key } from "../components/modals/__generated__/CustomerFormModalCustomerFragment.graphql";
import { Link, useNavigate } from "react-router-dom";
import UserNameWithHoverDetail from "../components/UserNameWithHoverDetail";
const { Title } = Typography;

const paginationParams = withDefault(JsonParam, {
  after: 0,
  skip: 0,
  first: 10,
  order: ["updatedAt_DESC"],
});
const whereParams = withDefault(JsonParam, []);
const CustomerList = () => {
  const [query, setQuery] = useQueryParams({
    pagination: paginationParams,
    where: whereParams,
  });
  const { token } = theme.useToken();

  const [fetchKey, updateFetchKey] = useUpdatableState("default");
  const [paginationState, paginationAction] =
    useParseAntdTablePagination<CustomerOrder>(query.pagination);
  const [whereInputs, whereInputAction] = useWhereInputList<CustomerWhereInput>(
    query.where
  );
  const navigate = useNavigate();

  useUpdateEffect(() => {
    setQuery({ where: whereInputs });
  }, [JSON.stringify(whereInputs), JSON.stringify(paginationState.parse)]);

  const { customers } = useLazyLoadQuery<CustomerListQuery>(
    graphql`
      query CustomerListQuery(
        $first: Int
        $skip: Int
        $where: CustomerWhereInput
        $order: [CustomerOrder!]
      ) {
        customers(first: $first, skip: $skip, order: $order, where: $where) {
          count
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
          }
          edges {
            node {
              id
              objectId
              createdAt
              updatedAt
              name
              description
              author {
                id
                ...UserNameWithHoverDetailFragment
              }
              ...CustomerFormModalCustomerFragment
            }
          }
        }
      }
    `,
    {
      where:
        whereInputs.length > 0
          ? {
              AND: whereInputs,
            }
          : undefined,
      ...paginationState.parse,
    },
    {
      fetchKey,
      fetchPolicy: "store-and-network",
    }
  );

  const [isPendingLoading, startLoadingTransition] = useTransition();
  const [isWherePending, startWhereTransition] = useTransition();
  const [searchField, setSearchField] = useState("name");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isOpenForm, { toggle: toggleOpenForm, set: setIsOpenForm }] =
    useToggle(false);
  const [editingCustomerFrgmt, setEditingCustomerFrgmt] =
    useState<CustomerFormModalCustomerFragment$key>();
  return (
    <>
      <Title level={2}>
        <ContactsOutlined /> Customer
      </Title>
      <Flex direction="column" align="stretch">
        <BackOfficeBreadcrumb />
        <WhiteSpace />
        <Flex direction="row" justify="between">
          <Flex>
            <Input.Search
              loading={isWherePending}
              addonBefore={
                <Select value={searchField} onSelect={setSearchField}>
                  <Select.Option value="name">Name</Select.Option>
                  <Select.Option value="description">Desc</Select.Option>
                </Select>
              }
              placeholder={`Search by ${searchField}`}
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              onSearch={(value, event) => {
                if (value.length > 0) {
                  startWhereTransition(() => {
                    whereInputAction.push({
                      [searchField]: {
                        matchesRegex: "(?i)" + value,
                      },
                    });
                  });
                  setSearchKeyword("");
                }
              }}
            />
          </Flex>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              toggleOpenForm();
            }}
          >
            Create New
          </Button>
        </Flex>
        <WhiteSpace />
        {whereInputs.length > 0 && (
          <>
            <WhereInputTagList
              whereInputs={whereInputs}
              onRemove={(i) => {
                startWhereTransition(() => {
                  whereInputAction.remove(i);
                });
              }}
            />
            <WhiteSpace />
          </>
        )}
        <Row>
          <Col span={24}>
            <Table
              rowKey={(record) => record?.id}
              loading={isPendingLoading}
              showSorterTooltip={false}
              pagination={{
                pageSize: paginationState.antd.pageSize,
                current: paginationState.antd.current,
                total: customers?.count || 0,
                showSizeChanger: true,
                showTotal(total, range) {
                  return `${range[0]}-${range[1]} of ${total} items`;
                },
              }}
              onChange={(pagination, filter, sorter) => {
                startLoadingTransition(() => {
                  paginationAction.onChangeAntdTable(pagination, sorter);
                });
              }}
              columns={[
                {
                  title: "Name",
                  dataIndex: "name",
                  sorter: true,
                  sortOrder: paginationState.antd.sortOrder["name"],
                  render(value, record, index) {
                    return (
                      <Flex direction="column" align="start">
                        <Typography.Link
                          onClick={() => {
                            console.log(record);
                            // setEditingCustomerFrgmt(record);
                            navigate("/customers/" + record.objectId);
                          }}
                        >
                          {value}
                        </Typography.Link>
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: token.fontSizeSM }}
                        >
                          {record.objectId}
                        </Typography.Text>
                      </Flex>
                    );
                  },
                },
                {
                  title: "Description",
                  dataIndex: "description",
                  render(value, record, index) {
                    return (
                      <Typography.Text
                        ellipsis
                        style={{ maxWidth: 150 }}
                        title={value}
                      >
                        {value}
                      </Typography.Text>
                    );
                  },
                  responsive: ["xl"],
                },
                {
                  title: "Sites / Contacts",
                  render(value, record, index) {
                    const customerQuery = encodeURIComponent(
                      JSON.stringify({
                        id: {
                          equalTo: record.id,
                        },
                      })
                    );
                    return (
                      <>
                        <Link to={`/sites?customer=${customerQuery}`}>
                          <CarOutlined />
                        </Link>{" "}
                        /{" "}
                        <Link to={`/contacts?customer=${customerQuery}`}>
                          <PhoneOutlined />
                        </Link>
                      </>
                    );
                  },
                },
                {
                  title: "Created",
                  dataIndex: "createdAt",
                  render(value, record, index) {
                    return dayjs(value).format("ll");
                  },
                  sorter: true,
                  sortOrder: paginationState.antd.sortOrder["createdAt"],
                  responsive: ["xl"],
                },
                {
                  title: "Updated",
                  dataIndex: "updatedAt",
                  render(value, record, index) {
                    return dayjs(value).format("lll");
                  },
                  sorter: true,
                  sortOrder: paginationState.antd.sortOrder["updatedAt"],
                },
                {
                  title: "Author",
                  render(value, record, index) {
                    return (
                      <UserNameWithHoverDetail userFrgmt={record.author} />
                    );
                  },
                },
              ]}
              dataSource={extractNonNullableNodesOnConnection(customers)}
              sortDirections={["descend", "ascend", null]}
            />
          </Col>
        </Row>
      </Flex>
      <CustomerFormModal
        open={isOpenForm || editingCustomerFrgmt !== undefined}
        customerFrgmt={editingCustomerFrgmt}
        onRequestClose={(completed) => {
          setEditingCustomerFrgmt(undefined);
          setIsOpenForm(false);
          if (completed) {
            updateFetchKey();
            message.success("save successfully");
          }
        }}
      />
    </>
  );
};

export default CustomerList;

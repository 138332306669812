/**
 * @generated SignedSource<<ae13df36fdf0f323d4a04b3dba9dd482>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsersOfObjectRoleViewQuery$variables = {
  readerRoleName: string;
  writerRoleName: string;
};
export type UsersOfObjectRoleViewQuery$data = {
  readonly readerRoles: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly objectId: string;
        readonly users: {
          readonly count: number;
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly email: string | null;
              readonly id: string;
              readonly objectId: string;
              readonly " $fragmentSpreads": FragmentRefs<"UserNameWithHoverDetailFragment">;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
  readonly writerRoles: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly objectId: string;
        readonly users: {
          readonly count: number;
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly email: string | null;
              readonly id: string;
              readonly objectId: string;
              readonly " $fragmentSpreads": FragmentRefs<"UserNameWithHoverDetailFragment">;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};
export type UsersOfObjectRoleViewQuery = {
  response: UsersOfObjectRoleViewQuery$data;
  variables: UsersOfObjectRoleViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "readerRoleName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "writerRoleName"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "equalTo",
            "variableName": "readerRoleName"
          }
        ],
        "kind": "ObjectValue",
        "name": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "RoleEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserNameWithHoverDetailFragment"
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "equalTo",
            "variableName": "writerRoleName"
          }
        ],
        "kind": "ObjectValue",
        "name": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v9 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "RoleEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersOfObjectRoleViewQuery",
    "selections": [
      {
        "alias": "readerRoles",
        "args": (v1/*: any*/),
        "concreteType": "RoleConnection",
        "kind": "LinkedField",
        "name": "roles",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": "writerRoles",
        "args": (v8/*: any*/),
        "concreteType": "RoleConnection",
        "kind": "LinkedField",
        "name": "roles",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UsersOfObjectRoleViewQuery",
    "selections": [
      {
        "alias": "readerRoles",
        "args": (v1/*: any*/),
        "concreteType": "RoleConnection",
        "kind": "LinkedField",
        "name": "roles",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      {
        "alias": "writerRoles",
        "args": (v8/*: any*/),
        "concreteType": "RoleConnection",
        "kind": "LinkedField",
        "name": "roles",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f906f927bb0556dfd2b5f313d96f3372",
    "id": null,
    "metadata": {},
    "name": "UsersOfObjectRoleViewQuery",
    "operationKind": "query",
    "text": "query UsersOfObjectRoleViewQuery(\n  $readerRoleName: String!\n  $writerRoleName: String!\n) {\n  readerRoles: roles(where: {name: {equalTo: $readerRoleName}}) {\n    count\n    edges {\n      node {\n        id\n        objectId\n        name\n        users {\n          count\n          edges {\n            node {\n              id\n              objectId\n              ...UserNameWithHoverDetailFragment\n              email\n            }\n          }\n        }\n      }\n    }\n  }\n  writerRoles: roles(where: {name: {equalTo: $writerRoleName}}) {\n    count\n    edges {\n      node {\n        id\n        objectId\n        name\n        users {\n          count\n          edges {\n            node {\n              id\n              objectId\n              ...UserNameWithHoverDetailFragment\n              email\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment UserNameWithHoverDetailFragment on User {\n  id\n  name\n  email\n}\n"
  }
};
})();

(node as any).hash = "88c7ffd7c89630b28af6c4ab0351dda6";

export default node;

import {
  Button,
  Card,
  Spin,
  Statistic,
  Tooltip,
  Typography,
  theme,
} from "antd";
import React, { useMemo, useTransition } from "react";
import Flex from "./Flex";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { StatisticNumberOfXPUMutation } from "./__generated__/StatisticNumberOfXPUMutation.graphql";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { useCounter } from "ahooks";
import dayjs from "dayjs";
const StatisticNumberOfXPU = () => {
  const { token } = theme.useToken();

  const [isPending, startTransition] = useTransition();
  const [fetchKey, { inc: refresh }] = useCounter(0);

  const { callCloudCode } = useLazyLoadQuery<StatisticNumberOfXPUMutation>(
    graphql`
      mutation StatisticNumberOfXPUMutation($input: CallCloudCodeInput!) {
        callCloudCode(input: $input) {
          clientMutationId
          result
        }
      }
    `,
    {
      input: {
        functionName: "getStatisticsActiveNumberOfXPUs",
        // params:
      },
    },
    {
      fetchPolicy: "network-only",
      fetchKey: fetchKey,
    }
  );

  const { totalCPUs, totalGPUs, totalNPUs } = callCloudCode?.result || {
    totalCPUs: "-",
    totalGPUs: "-",
    totalNPUs: "-",
  };

  const updatedTimeDayjs = useMemo(() => {
    return dayjs();
  }, [fetchKey]);
  return (
    <Card
      style={{
        backgroundColor: "transparent",
        borderColor: token.colorBorder,
        width: 360,
      }}
    >
      <Flex direction="column" gap="xxs" align="stretch">
        <Flex direction="row" justify="between">
          <Typography.Text type="secondary">
            On active T/S sites
          </Typography.Text>
          <Tooltip
            title={"As of " + updatedTimeDayjs.format("YYYY-MM-DD HH:mm:ss")}
          >
            <Button
              icon={
                <ReloadOutlined
                  style={{
                    color: token.colorTextSecondary,
                    fontSize: token.fontSizeSM,
                  }}
                />
              }
              type="text"
              loading={isPending}
              onClick={() => {
                startTransition(() => {
                  refresh();
                });
              }}
            />
          </Tooltip>
        </Flex>
        <Flex direction="row" gap="lg">
          <Statistic prefix="CPU" value={totalCPUs} />
          /
          <Statistic prefix="GPU" value={totalGPUs} />
          /
          <Statistic prefix="NPU" value={totalNPUs} />
        </Flex>
      </Flex>
    </Card>
  );
};

export default StatisticNumberOfXPU;

export const FallbackStatisticNumberOfXPU = () => {
  const { token } = theme.useToken();
  return (
    <Card
      style={{
        backgroundColor: "transparent",
        borderColor: token.colorBorder,
        width: 360,
      }}
    >
      <Spin indicator={<LoadingOutlined />} spinning>
        <Flex direction="column" gap="xxs" align="stretch">
          <Flex direction="row" justify="between">
            <Typography.Text type="secondary">
              On active T/S sites
            </Typography.Text>
            <Button
              type="text"
              disabled
              icon={
                <ReloadOutlined
                  style={{
                    color: token.colorTextSecondary,
                    fontSize: token.fontSizeSM,
                  }}
                />
              }
            />
          </Flex>
          <Flex direction="row" gap="lg">
            <Statistic prefix="" value={""} style={{ visibility: "hidden" }} />
          </Flex>
        </Flex>
      </Spin>
    </Card>
  );
};

/**
 * @generated SignedSource<<fe617c950537fc41e4f08bd80edd7d27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SiteWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<SiteWhereInput> | null;
  NOR?: ReadonlyArray<SiteWhereInput> | null;
  OR?: ReadonlyArray<SiteWhereInput> | null;
  assignee?: UserRelationWhereInput | null;
  author?: UserRelationWhereInput | null;
  contacts?: ContactRelationWhereInput | null;
  contractExpirationDate?: DateWhereInput | null;
  contractProductType?: StringWhereInput | null;
  contractStartDate?: DateWhereInput | null;
  createdAt?: DateWhereInput | null;
  customer?: CustomerRelationWhereInput | null;
  deletedAt?: DateWhereInput | null;
  deletedBy?: UserRelationWhereInput | null;
  description?: StringWhereInput | null;
  id?: IdWhereInput | null;
  isDeleted?: BooleanWhereInput | null;
  mainLicense?: LicenseRelationWhereInput | null;
  name?: StringWhereInput | null;
  numCPUs?: NumberWhereInput | null;
  numGPUs?: NumberWhereInput | null;
  numNPUs?: NumberWhereInput | null;
  objectId?: IdWhereInput | null;
  purchaseOrderId?: StringWhereInput | null;
  supportExpirationDate?: DateWhereInput | null;
  updatedAt?: DateWhereInput | null;
};
export type ObjectWhereInput = {
  equalTo?: KeyValueInput | null;
  exists?: boolean | null;
  greaterThan?: KeyValueInput | null;
  greaterThanOrEqualTo?: KeyValueInput | null;
  in?: ReadonlyArray<KeyValueInput | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: KeyValueInput | null;
  lessThanOrEqualTo?: KeyValueInput | null;
  notEqualTo?: KeyValueInput | null;
  notIn?: ReadonlyArray<KeyValueInput | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
  key: string;
  value: any;
};
export type SelectInput = {
  key: string;
  query: SubqueryInput;
};
export type SubqueryInput = {
  className: string;
  where: any;
};
export type UserRelationWhereInput = {
  exists?: boolean | null;
  have?: UserWhereInput | null;
  haveNot?: UserWhereInput | null;
};
export type UserWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<UserWhereInput> | null;
  NOR?: ReadonlyArray<UserWhereInput> | null;
  OR?: ReadonlyArray<UserWhereInput> | null;
  authData?: ObjectWhereInput | null;
  createdAt?: DateWhereInput | null;
  email?: StringWhereInput | null;
  emailVerified?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  password?: StringWhereInput | null;
  protectedFields?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
  username?: StringWhereInput | null;
};
export type DateWhereInput = {
  equalTo?: any | null;
  exists?: boolean | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type StringWhereInput = {
  equalTo?: string | null;
  exists?: boolean | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: ReadonlyArray<string | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  matchesRegex?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
  notInQueryKey?: SelectInput | null;
  options?: string | null;
  text?: TextInput | null;
};
export type TextInput = {
  search: SearchInput;
};
export type SearchInput = {
  caseSensitive?: boolean | null;
  diacriticSensitive?: boolean | null;
  language?: string | null;
  term: string;
};
export type BooleanWhereInput = {
  equalTo?: boolean | null;
  exists?: boolean | null;
  inQueryKey?: SelectInput | null;
  notEqualTo?: boolean | null;
  notInQueryKey?: SelectInput | null;
};
export type IdWhereInput = {
  equalTo?: string | null;
  exists?: boolean | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: ReadonlyArray<string | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type ContactRelationWhereInput = {
  exists?: boolean | null;
  have?: ContactWhereInput | null;
  haveNot?: ContactWhereInput | null;
};
export type ContactWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<ContactWhereInput> | null;
  NOR?: ReadonlyArray<ContactWhereInput> | null;
  OR?: ReadonlyArray<ContactWhereInput> | null;
  author?: UserRelationWhereInput | null;
  createdAt?: DateWhereInput | null;
  customer?: CustomerRelationWhereInput | null;
  description?: StringWhereInput | null;
  email?: StringWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  phone?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
};
export type CustomerRelationWhereInput = {
  exists?: boolean | null;
  have?: CustomerWhereInput | null;
  haveNot?: CustomerWhereInput | null;
};
export type CustomerWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<CustomerWhereInput> | null;
  NOR?: ReadonlyArray<CustomerWhereInput> | null;
  OR?: ReadonlyArray<CustomerWhereInput> | null;
  author?: UserRelationWhereInput | null;
  createdAt?: DateWhereInput | null;
  description?: StringWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  updatedAt?: DateWhereInput | null;
};
export type LicenseRelationWhereInput = {
  exists?: boolean | null;
  have?: LicenseWhereInput | null;
  haveNot?: LicenseWhereInput | null;
};
export type LicenseWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<LicenseWhereInput> | null;
  NOR?: ReadonlyArray<LicenseWhereInput> | null;
  OR?: ReadonlyArray<LicenseWhereInput> | null;
  author?: UserRelationWhereInput | null;
  certificateOptions?: ObjectWhereInput | null;
  createdAt?: DateWhereInput | null;
  description?: StringWhereInput | null;
  disabled?: BooleanWhereInput | null;
  expirationDate?: DateWhereInput | null;
  hardwareInfoList?: ArrayWhereInput | null;
  hardwareSummary?: ObjectWhereInput | null;
  id?: IdWhereInput | null;
  licenseInfo?: ObjectWhereInput | null;
  licenseType?: StringWhereInput | null;
  name?: StringWhereInput | null;
  numCPUs?: NumberWhereInput | null;
  numGPUs?: NumberWhereInput | null;
  numNPUs?: NumberWhereInput | null;
  objectId?: IdWhereInput | null;
  pemInfo?: ObjectWhereInput | null;
  site?: SiteRelationWhereInput | null;
  status?: StringWhereInput | null;
  type?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
};
export type ArrayWhereInput = {
  containedBy?: ReadonlyArray<any | null> | null;
  contains?: ReadonlyArray<any | null> | null;
  equalTo?: any | null;
  exists?: boolean | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type NumberWhereInput = {
  equalTo?: number | null;
  exists?: boolean | null;
  greaterThan?: number | null;
  greaterThanOrEqualTo?: number | null;
  in?: ReadonlyArray<number | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type SiteRelationWhereInput = {
  exists?: boolean | null;
  have?: SiteWhereInput | null;
  haveNot?: SiteWhereInput | null;
};
export type SiteCalendarQuery$variables = {
  where?: SiteWhereInput | null;
};
export type SiteCalendarQuery$data = {
  readonly sites: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly contractExpirationDate: any | null;
        readonly customer: {
          readonly id: string;
          readonly name: string;
        } | null;
        readonly id: string;
        readonly name: string;
        readonly objectId: string;
        readonly supportExpirationDate: any | null;
      } | null;
    } | null> | null;
  };
};
export type SiteCalendarQuery = {
  response: SiteCalendarQuery$data;
  variables: SiteCalendarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "SiteConnection",
    "kind": "LinkedField",
    "name": "sites",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SiteEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectId",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "supportExpirationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractExpirationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteCalendarQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteCalendarQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "3fc4415d362109638286fe167402a079",
    "id": null,
    "metadata": {},
    "name": "SiteCalendarQuery",
    "operationKind": "query",
    "text": "query SiteCalendarQuery(\n  $where: SiteWhereInput\n) {\n  sites(where: $where) {\n    count\n    edges {\n      node {\n        id\n        objectId\n        name\n        supportExpirationDate\n        contractExpirationDate\n        customer {\n          name\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed0ae66b5f9920573f892578aceaddfd";

export default node;

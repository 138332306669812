import React, { PropsWithChildren } from "react";
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import Markdown from "markdown-to-jsx";
import _ from "lodash";

const styles = StyleSheet.create({
  body: {
    padding: 35,
    fontFamily: "Pretendard",
    fontSize: 15,
  },
  title: {
    fontSize: 24,
    color: "white",
    fontWeight: "bold",
  },
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footerContainer: {
    flex: 1,
    justifyContent: "flex-end",
  },
});

Font.register({
  family: "Pretendard",
  fonts: [
    {
      src: "/Pretendard-Regular.ttf",
    },
    {
      src: "/Pretendard-Bold.ttf",
      fontWeight: 700,
    },
  ],
});

const Subtitle: React.FC<PropsWithChildren> = ({ children, ...props }) => (
  <Text style={styles.subtitle} {...props}>
    {children}
  </Text>
);

interface PDFLicenseCertificateProps {
  certificateSeq: string;
  publishedAt: string;
  customerName: string;
  contentMd?: string;
}

const PDFLicenseCertificate: React.FC<PDFLicenseCertificateProps> = ({
  contentMd,
  certificateSeq,
  publishedAt,
  customerName,
}) => {
  return (
    <Document title="hello">
      <Page style={styles.body} wrap size={"A4"}>
        <View style={{ backgroundColor: "#59A474", padding: 10 }}>
          <Text style={styles.title}>Certificate of Software License</Text>
        </View>
        <View
          style={{
            border: "1px solid #888",
            padding: 5,
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <DescriptionItem label="인증번호">{certificateSeq}</DescriptionItem>
          <DescriptionItem label="발행일">{publishedAt}</DescriptionItem>
          <DescriptionItem label="사용기관">{customerName}</DescriptionItem>
        </View>
        {/* content container */}
        <View
          style={{
            flex: 100,
            justifyContent: "space-between",
          }}
        >
          <Text>
            래블업 주식회사는 본 소프트웨어 사용권 인증서를 통해 위
            "사용기관"에서 아래 "사용 기간" 동안 "사용 범위"에 대해
            "소프트웨어"를 사용할 권리를 인증하며, "소프트웨어"를 정당하게
            사용하고 있음을 증명합니다. 또한 아래 "기술 지원"을 보증합니다.
          </Text>

          <View style={{ flexDirection: "column" }}>
            {contentMd && markdownToPdfComponents(contentMd)}
          </View>

          {/* <View style={{ alignItems: "center" }}>
            <Text>2---년 00월 00일</Text>
          </View> */}
          <View style={{ alignItems: "center", margin: 60, marginTop: 0 }}>
            <View style={{ fontWeight: "bold" }}>
              <Text>서울특별시 강남구 선릉로100길 34, 4층 403호</Text>
              <Text style={{ letterSpacing: 15, fontSize: 20 }}>
                래블업 주식회사
              </Text>
              <Text style={{ letterSpacing: 19 }}>대표이사 신정규 </Text>
              {/* <View style={{ position: "absolute", right: 0, bottom: 0 }}>
                <Text>(인)</Text>
              </View> */}
              <View
                style={{
                  position: "absolute",
                  right: -8,
                  // right: 100,
                  bottom: -12,
                  width: 40,
                  height: 40,
                  // borderRadius: 20,
                  // border: "1px solid red",
                }}
              >
                <Image src={"/lablup-stamp.png"} />
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            borderTop: "3px solid #59A474",
            paddingTop: 10,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <Text style={{ fontWeight: "bold" }}>
                Lablup Inc. : Make AI Accessible
              </Text>
              <Text style={{ fontSize: 12 }}>
                #403, 34, Seolluengro 100-gil, Gangnam-gu, Seoul, 06161,
                Republic of Korea
              </Text>
              <Text style={{ fontSize: 12 }}>
                Business registration number: KR864-88-00080
              </Text>
            </View>
            <View>
              <Image src={"/lablup.png"} style={{ width: 100 }} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

interface DescriptionItemProps {
  label: string;
  children: string;
}
const DescriptionItem: React.FC<DescriptionItemProps> = ({
  label,
  children,
}) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <View style={{ minWidth: 80 }}>
        <Text style={{ fontWeight: "bold", width: 80 }}>{label}</Text>
      </View>
      <Text>:</Text>
      <View style={{ paddingLeft: 10 }}>
        <Text>{children}</Text>
      </View>
    </View>
  );
};

const markdownToPdfComponents = (markdown: string) => {
  return (
    <Markdown
      options={{
        forceBlock: true,
        overrides: {
          h1: {
            component: ({ children, ...props }) => (
              <Text style={{ fontSize: 24, fontWeight: "bold" }} {...props}>
                {children}
              </Text>
            ),
          },
          h2: {
            component: ({ children, ...props }) => (
              <Text style={{ fontSize: 20, fontWeight: "bold" }} {...props}>
                {children}
              </Text>
            ),
          },
          h3: {
            component: ({ children, ...props }) => (
              <Text style={{ fontSize: 16, fontWeight: "bold" }} {...props}>
                {children}
              </Text>
            ),
          },

          p: {
            component: ({ children, ...props }) => (
              <Text style={{ fontSize: 14 }} {...props}>
                {children}
              </Text>
            ),
          },
          // FIXME: strong does not work with nested components such as ul, ol
          strong: {
            component: ({ children, ...props }) => (
              <Text style={{ fontWeight: "bold" }} {...props}>
                {children}
              </Text>
            ),
          },
          ul: {
            component: ({ children, ...props }) => {
              return (
                <View
                  style={{
                    marginLeft: 14,
                  }}
                  {...props}
                >
                  {children}
                </View>
              );
            },
          },
          ol: {
            component: ({ children, ...props }) => {
              return (
                <View
                  style={{
                    paddingLeft: 14,
                    flexDirection: "column",
                  }}
                  {...props}
                >
                  {_.map(children, (child, index) => {
                    return {
                      ...child,
                      props: {
                        index: index + 1,
                        ...child.props,
                      },
                    };
                  })}
                </View>
              );
            },
          },
          li: {
            component: ({ children, index, ...props }) => {
              return (
                <View>
                  {_.map(children, (child, i) => {
                    return typeof child === "string" ? (
                      <Text key={"k" + i}>
                        {index ? `${index})` : "-"} {child}
                      </Text>
                    ) : (
                      child
                    );
                  })}
                </View>
              );
            },
          },
        },
      }}
    >
      {markdown}
    </Markdown>
  );
};
export default PDFLicenseCertificate;

import {
  Alert,
  DatePicker,
  Form,
  Input,
  Modal,
  ModalProps,
  UploadFile,
  UploadProps,
  Upload,
  Button,
  Typography,
  Spin,
  Checkbox,
  Radio,
  Descriptions,
  theme,
} from "antd";
import React, { useMemo, useState } from "react";
import { GraphQLTaggedNode, useFragment, useMutation } from "react-relay";
import WhiteSpace from "../WhiteSpace";
import graphql from "babel-plugin-relay/macro";
import { LicenseFormModalMutation } from "./__generated__/LicenseFormModalMutation.graphql";
import { openJSONErrorModal } from "../../helpers";
import dayjs, { Dayjs } from "dayjs";
import { RcFile } from "antd/es/upload";
import {
  ExclamationCircleOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { LicenseFormModalSiteFragment$key } from "./__generated__/LicenseFormModalSiteFragment.graphql";
import { LicenseFormModalLicenseFragment$key } from "./__generated__/LicenseFormModalLicenseFragment.graphql";
import { LicenseFormModalUpdateMutation } from "./__generated__/LicenseFormModalUpdateMutation.graphql";
import { useRequest } from "ahooks";
import BOSpin from "../BOSpin";
import { getHardwareSummary } from "../../helpers/licenseHelpers";
import { info } from "console";
import DescriptionsItem from "antd/es/descriptions/Item";

export interface LicenseFormInput {
  name: string;
  description: string;
  hardwareInfo: object;
  expirationDate: Dayjs;
  disabled: boolean;
  type: "fixed" | "floating";
}

interface LicenseFormModalProps extends ModalProps {
  onRequestClose: (completed: boolean) => void;
  siteFrgmt: LicenseFormModalSiteFragment$key | null;
  licenseFrgmt?: LicenseFormModalLicenseFragment$key | null;
}

// description: { type: 'String' },
//       site: { type: 'Pointer', targetClass: 'Site', required: true },
//       hardwareInfo: { type: 'Object' },
//       licenseInfo: { type: 'Object' },
//       expirationDate: { type: 'Date' },
//       disabled: { type: 'Boolean', defaultValue: false },
const LicenseFormModal: React.FC<LicenseFormModalProps> = ({
  onRequestClose,
  siteFrgmt,
  licenseFrgmt,
  ...modalProps
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm<LicenseFormInput>();
  const type = Form.useWatch("type", form);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [hardwareInfo, setHardwareInfo] = useState<object>();
  const [commitCreate, isInFlightCrete] =
    useMutation<LicenseFormModalMutation>(graphql`
      mutation LicenseFormModalMutation($input: CreateLicenseInput!) {
        createLicense(input: $input) {
          license {
            id
            name
            description
            hardwareInfoList {
              ... on Element {
                value
              }
            }
            licenseInfo
            type
            numCPUs
            numGPUs
            author {
              id
              username
            }
            site {
              id
              mainLicense {
                id
                ...LicenseDetailFragment
                ...LicenseFormModalLicenseFragment
              }
            }
            expirationDate
            ...LicenseDetailFragment
            ...LicenseFormModalLicenseFragment
          }
        }
      }
    `);
  const [commitUpdate, isInFlightUpdate] =
    useMutation<LicenseFormModalUpdateMutation>(graphql`
      mutation LicenseFormModalUpdateMutation($input: UpdateLicenseInput!) {
        updateLicense(input: $input) {
          license {
            id
            hardwareInfoList {
              ... on Element {
                value
              }
            }
            licenseInfo
            type
            numCPUs
            numGPUs
            expirationDate
            site {
              id
              mainLicense {
                id
                ...LicenseDetailFragment
                ...LicenseFormModalLicenseFragment
              }
            }
            expirationDate
            ...LicenseDetailFragment
            ...LicenseFormModalLicenseFragment
          }
        }
      }
    `);
  const site = useFragment(
    graphql`
      fragment LicenseFormModalSiteFragment on Site {
        id
        contractExpirationDate
      }
    `,
    siteFrgmt
  );
  const license = useFragment(
    graphql`
      fragment LicenseFormModalLicenseFragment on License {
        id
        name
        description
        hardwareInfoList {
          ... on Element {
            value
          }
        }
        type
        expirationDate
        numCPUs
        numGPUs
        site {
          id
          name
          numCPUs
          numGPUs
          mainLicense {
            id
            numCPUs
            numGPUs
            hardwareInfoList {
              ... on Element {
                value
              }
            }
          }
        }
      }
    `,
    licenseFrgmt || null
  );

  const resetFile = () => {
    setFileList([]);
    setHardwareInfo(undefined);
  };

  function readFile(file: RcFile) {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.onerror = reject;
      fr.readAsText(file);
    });
  }

  const uploadProps: UploadProps = {
    fileList,
    accept: ".json",
    onRemove(file) {
      setFileList((curList) => _.filter(curList, (i) => i.uid !== file.uid));
    },
    onChange({ file, fileList, event }) {
      setFileList(fileList);
    },
    beforeUpload(file) {
      return false;
    },
    maxCount: 1000,
    multiple: true,
  };

  const {
    data: hardwareInfoList,
    error: hardwareLoadError,
    loading: loadingHardwareInfoList,
  } = useRequest(
    () => {
      return Promise.all(
        _.map(
          fileList,
          (file) => file.originFileObj && readFile(file.originFileObj)
        )
      ).then((results) => {
        const infoList = _.map(results, (x, i) => ({
          name: fileList[i].name,
          content: JSON.parse(x + ""),
          lastModified: fileList[i].lastModified,
          size: fileList[i].size,
        }));
        // const { numCPUs, numGPUs } = _.reduce(
        //   infoList,
        //   (acc, file) => {
        //     acc.numCPUs += file.content.numCPUs;
        //     acc.numGPUs += file.content.accelerators
        //       ? file.content.accelerators.length
        //       : 0;
        //     return acc;
        //   },
        //   {
        //     numCPUs: 0,
        //     numGPUs: 0,
        //   }
        // );
        return infoList;
      });
    },
    {
      manual: false,
      refreshDeps: [_.map(fileList, (f) => f.uid).join(",")],
    }
  );

  const hardwareSummary = useMemo(() => {
    return getHardwareSummary(hardwareInfoList || [], type);
  }, [hardwareInfoList, type]);

  return (
    <Modal
      title="License Editor"
      okText={"Save"}
      destroyOnClose={true}
      confirmLoading={isInFlightCrete || isInFlightUpdate}
      okButtonProps={{
        disabled:
          loadingHardwareInfoList ||
          (hardwareSummary.errors && hardwareSummary.errors?.length > 0),
      }}
      onOk={() => {
        return form.validateFields().then((values) => {
          license?.id
            ? commitUpdate({
                variables: {
                  input: {
                    id: license.id,
                    fields: {
                      hardwareInfoList: hardwareInfoList,
                      description: values.description,
                      expirationDate:
                        values.expirationDate &&
                        dayjs(values.expirationDate)
                          .tz("Asia/Seoul")
                          .endOf("day")
                          .toISOString(),
                      type: values.type,
                    },
                  },
                },
                onCompleted(response, errors) {
                  if (errors) {
                    openJSONErrorModal(errors);
                  } else {
                    onRequestClose(true);
                    resetFile();
                  }
                },
                onError(errors) {
                  openJSONErrorModal(errors);
                },
              })
            : commitCreate({
                variables: {
                  input: {
                    fields: {
                      name: values.name,
                      description: values.description,
                      hardwareInfoList: hardwareInfoList,
                      expirationDate:
                        values.expirationDate &&
                        dayjs(values.expirationDate)
                          .tz("Asia/Seoul")
                          .endOf("day")
                          .toISOString(),
                      site: {
                        link: site?.id || license?.site.id,
                      },
                      type: values.type,
                    },
                  },
                },
                onCompleted(response, errors) {
                  if (errors) {
                    openJSONErrorModal(errors);
                  } else {
                    onRequestClose(true);
                    resetFile();
                  }
                },
                onError(errors) {
                  openJSONErrorModal(errors);
                },
              });
        });
      }}
      maskClosable={false}
      onCancel={() => {
        onRequestClose(false);
        resetFile();
      }}
      {...modalProps}
    >
      <WhiteSpace />
      <Form
        form={form}
        preserve={false}
        labelCol={{ span: 6 }}
        initialValues={
          (license
            ? {
                name: license.name,
                description: license.description,
                expirationDate:
                  license.expirationDate && dayjs(license.expirationDate),
                type: license.type || "fixed",
              }
            : site
            ? {
                type: "fixed",
                expirationDate: site.contractExpirationDate
                  ? dayjs(site.contractExpirationDate)
                  : undefined,
              }
            : {
                type: "fixed",
              }) as LicenseFormInput
        }
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Name" disabled={!!license?.id} />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>
        <Form.Item label="License Type" name="type">
          <Radio.Group>
            <Radio value="fixed">fixed</Radio>
            <Radio value="floating">floating</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Hardware file"
          name="hardwareInfo"
          help={hardwareLoadError && "Please check hardware file(s)"}
          validateStatus={hardwareLoadError && "error"}
          extra={
            loadingHardwareInfoList ? (
              <div>
                <BOSpin /> loading files...
              </div>
            ) : hardwareSummary.errors ? (
              <Alert
                message="Error"
                description={"Hardware file(s) and license type do not match."}
                type="error"
                showIcon
                style={{ marginTop: token.marginXS }}
              />
            ) : (
              <>
                <Descriptions
                  size="small"
                  bordered
                  column={1}
                  style={{ marginTop: token.marginXS }}
                >
                  <DescriptionsItem label="CPUs">
                    {hardwareSummary.numCPUs}
                  </DescriptionsItem>
                  <DescriptionsItem label="Accelerators">
                    {_.map(hardwareSummary.accelerators, (value, key) => {
                      return (
                        <>
                          {key}: {value}
                          <br />
                        </>
                      );
                    })}
                  </DescriptionsItem>
                  <DescriptionsItem label="Models">
                    {_.map(hardwareSummary.modelNames, (value, key) => {
                      return (
                        <>
                          {key}: {value}
                          <br />
                        </>
                      );
                    })}
                  </DescriptionsItem>
                </Descriptions>
              </>
            )
          }
        >
          {/* <Input.TextArea placeholder="Description" /> */}
          <Upload.Dragger {...uploadProps}>
            {/* <Button icon={<UploadOutlined />}>Upload</Button> */}
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item
          label="Expiration date"
          name="expirationDate"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LicenseFormModal;

import { Alert, Form, Input, Modal, ModalProps, Select } from "antd";
import React from "react";
import { GraphQLTaggedNode, useFragment, useMutation } from "react-relay";
import { useState } from "react";
import WhiteSpace from "../WhiteSpace";
import graphql from "babel-plugin-relay/macro";
import {
  ContactFormModalMutation,
  CreateContactInput,
} from "./__generated__/ContactFormModalMutation.graphql";
import { openJSONErrorModal } from "../../helpers";
import CustomerSelect from "../CustomerSelect";
import { ContactFormModalContactFragment$key } from "./__generated__/ContactFormModalContactFragment.graphql";
import { ContactFormModalUpdateMutation } from "./__generated__/ContactFormModalUpdateMutation.graphql";
interface ContactFormModalProps extends ModalProps {
  contactFrgmt?: ContactFormModalContactFragment$key | null;
  customerId?: string;
  onRequestClose: (contactId?: string) => void;
}
interface ContactFormInput {
  name: string;
  description: string;
  phone: string;
  customerId: string;
  email: string;
  countryCode: string;
}

const { Option } = Select;
const ContactFormModal: React.FC<ContactFormModalProps> = ({
  contactFrgmt,
  onRequestClose,
  customerId,
  ...modalProps
}) => {
  const [form] = Form.useForm<ContactFormInput>();
  const contact = useFragment(
    graphql`
      fragment ContactFormModalContactFragment on Contact {
        id
        name
        email
        customer {
          id
          name
        }
        author {
          id
          username
        }
        phone
        description
      }
    `,
    contactFrgmt || null
  );

  const [commitCreate, isInFlightCrete] =
    useMutation<ContactFormModalMutation>(graphql`
      mutation ContactFormModalMutation($input: CreateContactInput!) {
        createContact(input: $input) {
          contact {
            id
            ...ContactFormModalContactFragment
          }
        }
      }
    `);
  const [commitUpdate, isInFlightUpdate] =
    useMutation<ContactFormModalUpdateMutation>(graphql`
      mutation ContactFormModalUpdateMutation($input: UpdateContactInput!) {
        updateContact(input: $input) {
          contact {
            id
            ...ContactFormModalContactFragment
          }
        }
      }
    `);

  return (
    <Modal
      title="Contact Editor"
      okText={contact ? "Save" : "Create"}
      destroyOnClose={true}
      onOk={() => {
        form.validateFields().then((values) => {
          const input: CreateContactInput = {
            fields: {
              customer: {
                link: values.customerId,
              },
              name: values.name,
              //   description: values.description,
              phone: values.phone,
              email: values.email,
            },
          };
          contact
            ? commitUpdate({
                variables: {
                  input: {
                    id: contact.id,
                    ...input,
                  },
                },
                onCompleted(response, errors) {
                  if (errors) {
                    openJSONErrorModal(errors);
                  } else {
                    onRequestClose(response.updateContact?.contact.id);
                  }
                },
                onError(errors) {
                  openJSONErrorModal(errors);
                },
              })
            : commitCreate({
                variables: {
                  input,
                },
                onCompleted(response, errors) {
                  if (errors) {
                    openJSONErrorModal(errors);
                  } else {
                    onRequestClose(response.createContact?.contact.id);
                  }
                },
                onError(errors) {
                  openJSONErrorModal(errors);
                },
              });
        });
      }}
      maskClosable={false}
      onCancel={() => onRequestClose()}
      {...modalProps}
    >
      <WhiteSpace />
      <Form
        form={form}
        preserve={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={
          contact
            ? ({
                email: contact.email,
                name: contact.name,
                phone: contact.phone,
                description: contact.description,
                customerId: customerId || contact.customer?.id,
              } as ContactFormInput)
            : {
                customerId: customerId,
              }
        }
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            {
              pattern: /^(?:\(\+\d{2,3}\)|\+\d{2,3})?\s*(?:\d[-\s]?){8,14}\d$/,
              message: "Please enter a valid phone number",
              required: false,
            },
          ]}
        >
          <Input placeholder="Phone number" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
            },
            {
              type: "email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>
        <Form.Item
          label="Customer"
          name="customerId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CustomerSelect disabled={!!customerId} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ContactFormModal;

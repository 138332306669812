/**
 * @generated SignedSource<<e72a963bf90626358218b3424457aa0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type authUserFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserView_user">;
  readonly " $fragmentType": "authUserFragment";
};
export type authUserFragment$key = {
  readonly " $data"?: authUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"authUserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "authUserFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserView_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "cb1594a60ff3f32e6e659d0e9d78ad1d";

export default node;

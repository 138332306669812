/**
 * @generated SignedSource<<9b82eb7cc570b4007dac86d59bc1c163>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LicenseFormModalLicenseFragment$data = {
  readonly description: string | null;
  readonly expirationDate: any;
  readonly hardwareInfoList: ReadonlyArray<{
    readonly value?: any;
  } | null> | null;
  readonly id: string;
  readonly name: string | null;
  readonly numCPUs: number | null;
  readonly numGPUs: number | null;
  readonly site: {
    readonly id: string;
    readonly mainLicense: {
      readonly hardwareInfoList: ReadonlyArray<{
        readonly value?: any;
      } | null> | null;
      readonly id: string;
      readonly numCPUs: number | null;
      readonly numGPUs: number | null;
    } | null;
    readonly name: string;
    readonly numCPUs: number | null;
    readonly numGPUs: number | null;
  };
  readonly type: string | null;
  readonly " $fragmentType": "LicenseFormModalLicenseFragment";
};
export type LicenseFormModalLicenseFragment$key = {
  readonly " $data"?: LicenseFormModalLicenseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LicenseFormModalLicenseFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "hardwareInfoList",
  "plural": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "type": "Element",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numCPUs",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numGPUs",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LicenseFormModalLicenseFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expirationDate",
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "License",
          "kind": "LinkedField",
          "name": "mainLicense",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "License",
  "abstractKey": null
};
})();

(node as any).hash = "9b8a8d3d9af2355450e0699dc5102bfd";

export default node;

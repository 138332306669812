import { IdcardOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, theme, Typography } from "antd";
import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useAuth } from "../hooks/auth";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { UserView_user$key } from "./__generated__/UserView_user.graphql";
import { UserViewQuery } from "./__generated__/UserViewQuery.graphql";
import Flex from "./Flex";
import WhiteSpace from "./WhiteSpace";
import _ from "lodash";
import RoleUserView from "./RoleUserView";

interface UserViewProps {
  collapsed: boolean;
}
const UserView: React.FC<UserViewProps> = ({ collapsed = false }) => {
  const { user: me, logout, isInFlightLogout } = useAuth();
  const { token } = theme.useToken();
  const user = useFragment(
    graphql`
      fragment UserView_user on User {
        id
        username
        name
      }
    `,
    me as UserView_user$key
  );

  const { viewer } = useLazyLoadQuery<UserViewQuery>(
    graphql`
      query UserViewQuery {
        viewer {
          ...RoleUserViewFragment
        }
      }
    `,
    {
      id: user.id,
    }
  );

  return collapsed ? (
    <Avatar
      size="default"
      icon={<UserOutlined style={{ color: token.colorText }} />}
      style={{
        backgroundColor: token.colorBorderBg,
      }}
    />
  ) : (
    <Flex
      direction="column"
      align="stretch"
      style={{
        backgroundColor: token.colorBgElevated,
        padding: token.paddingXS,
        borderRadius: token.borderRadius,
      }}
    >
      <Flex direction="row">
        <Avatar
          size="default"
          icon={<UserOutlined style={{ color: token.colorTextSecondary }} />}
          style={{
            backgroundColor: token.colorBorderBg,
            borderColor: token.colorTextSecondary,
          }}
        />
        <WhiteSpace direction="row" size="sm" />
        <Typography.Text>
          {user?.name || user.username?.split("@")[0]}
        </Typography.Text>
      </Flex>
      <Flex
        direction="row"
        justify="center"
        style={{ color: token.colorTextSecondary }}
      >
        <IdcardOutlined />
        <WhiteSpace direction="row" size="sm" />
        <Typography.Text type="secondary">
          <RoleUserView viewerFrgmt={viewer} />
        </Typography.Text>
      </Flex>
      <WhiteSpace />
      <Button
        size="small"
        loading={isInFlightLogout}
        // type="text"
        onClick={() => {
          logout();
        }}
      >
        Logout
      </Button>
    </Flex>
  );
};

export default UserView;

import {
  CarOutlined,
  EditOutlined,
  FilePdfOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Descriptions, Typography } from "antd";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import Flex from "../components/Flex";
import graphql from "babel-plugin-relay/macro";
import { SiteDetailQuery } from "./__generated__/SiteDetailQuery.graphql";
import { Link, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import WhiteSpace from "../components/WhiteSpace";
import LicenseList from "../components/LicenseList";
import LicenseDetail from "../components/LicenseDetail";
import SiteFormModal from "../components/modals/SiteFormModal";
import { useToggle } from "ahooks";
import BackOfficeBreadcrumb from "../components/BackOfficeBreadcrumb";
import LicenseFormModal from "../components/modals/LicenseFormModal";
import { useUpdatableState } from "../hooks";
import { useTransition } from "react";
import _ from "lodash";
import ContactFormModal from "../components/modals/ContactFormModal";
import { ContactFormModalContactFragment$key } from "../components/modals/__generated__/ContactFormModalContactFragment.graphql";
import UserNameWithHoverDetail from "../components/UserNameWithHoverDetail";
import UsersOfObjectRoleView from "../components/UsersOfObjectRoleView";
import { useAuth } from "../hooks/auth";

const SiteDetail = () => {
  const { roleNames } = useAuth();
  const [isEditing, { toggle: toggleIsEditing }] = useToggle(false);
  const [openLicenseModal, { toggle: toggleOpenLicenseModal }] =
    useToggle(false);
  const { siteObjectId } = useParams<"siteObjectId">();
  const navigate = useNavigate();
  const [licenseListFetchKey, updateLicenseListFetchKey] =
    useUpdatableState("fetch");
  const [editingContactFrgmt, setEditingContactFrgmt] =
    useState<ContactFormModalContactFragment$key>();

  const [selectedLicenseId, setSelectedLicenseId] = useState<string>();
  const { site, selectedLicense } = useLazyLoadQuery<SiteDetailQuery>(
    graphql`
      query SiteDetailQuery(
        $id: ID!
        $selectedId: ID!
        $skipSelected: Boolean!
      ) {
        site(id: $id) {
          id
          objectId
          name
          description
          numGPUs
          numCPUs
          numNPUs
          customer {
            id
            objectId
            name
          }
          author {
            id
            username
            name
            email
            ...UserNameWithHoverDetailFragment
          }
          # assignee {
          #   id
          #   username
          # }
          contacts(first: 100, order: updatedAt_DESC) {
            count
            edges {
              node {
                id
                name
                ...ContactFormModalContactFragment
              }
            }
          }
          mainLicense {
            id
            objectId
            numCPUs
            numGPUs
          }
          contractStartDate
          contractExpirationDate
          supportExpirationDate
          createdAt
          updatedAt
          mainLicense {
            ...LicenseDetailFragment
          }
          ...SiteFormModalSiteFragment
          ...LicenseFormModalSiteFragment
        }
        selectedLicense: license(id: $selectedId) @skip(if: $skipSelected) {
          objectId
          ...LicenseDetailFragment
        }
      }
    `,
    {
      id: siteObjectId || "",
      selectedId: selectedLicenseId || "",
      skipSelected: !selectedLicenseId,
    },
    {
      fetchKey: licenseListFetchKey,
      fetchPolicy: "store-and-network",
    }
  );

  const [isPendingTransition, startTransition] = useTransition();

  return (
    <>
      <Typography.Title level={2}>
        <CarOutlined /> Site : {site?.name} @{" "}
        <Link to={`/customers/${site.customer?.objectId}`}>
          {site?.customer?.name}
        </Link>
      </Typography.Title>
      <Flex direction="column" align="stretch">
        <BackOfficeBreadcrumb />
        <WhiteSpace />
        <Flex justify="between">
          <Typography.Title level={4}>Info</Typography.Title>
          <Button
            onClick={() => {
              toggleIsEditing();
            }}
            icon={<EditOutlined />}
          >
            Edit "{site.name}" site
          </Button>
        </Flex>
        <WhiteSpace size="sm" />
        <div>
          <Descriptions
            size="small"
            bordered
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Description" span={3}>
              {site.description}
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              {dayjs(site?.createdAt).format("lll")}
            </Descriptions.Item>
            <Descriptions.Item label="Updated">
              {dayjs(site?.updatedAt).format("lll")}
            </Descriptions.Item>
            <Descriptions.Item label="Author">
              <UserNameWithHoverDetail userFrgmt={site.author} />
            </Descriptions.Item>
            {/* <Descriptions.Item label="Assignee">
              {site.assignee?.username || (
                <Typography.Text type="secondary">Unassigned</Typography.Text>
              )}
            </Descriptions.Item> */}
            <Descriptions.Item label="Contract">
              {site.contractStartDate &&
                dayjs(site.contractStartDate).format("ll")}
              {" ~ "}
              {site.contractExpirationDate &&
                dayjs(site.contractExpirationDate).format("ll")}
            </Descriptions.Item>
            <Descriptions.Item label="Support Expiration">
              {site.supportExpirationDate &&
                dayjs(site.supportExpirationDate).format("ll")}
            </Descriptions.Item>
            <Descriptions.Item label="Contacts">
              {_.map(site.contacts.edges, (edge) => (
                <React.Fragment key={edge?.node?.id}>
                  <Typography.Link
                    onClick={() =>
                      setEditingContactFrgmt(edge?.node || undefined)
                    }
                  >
                    {edge?.node?.name}
                  </Typography.Link>
                  {edge?.node?.id !== _.last(site.contacts.edges)?.node?.id &&
                    ", "}
                </React.Fragment>
              ))}
            </Descriptions.Item>
            <Descriptions.Item label="Number of CPU / GPU / NPU">
              {site.numCPUs || "0"} / {site.numGPUs || "0"} /{" "}
              {site.numNPUs || "0"}
            </Descriptions.Item>
            {roleNames.includes("accountManager") && (
              <Descriptions.Item label="Additional authorized ">
                <UsersOfObjectRoleView
                  className="Site"
                  objectId={siteObjectId || ""}
                />
              </Descriptions.Item>
            )}
            {/* <Descriptions.Item label="Number of GPU/CPU">
              {site.numCPUs || "0"} CPU(s)/ {site.numGPUs || "0"} GPU(s)
              {!!site.mainLicense &&
                (site.numCPUs !== site.mainLicense?.numCPUs ||
                  site.numGPUs !== site.mainLicense?.numGPUs) && (
                  <Flex>
                    <Typography.Text type="danger">
                      {" "}
                      Please match with main license ({
                        site.mainLicense.numCPUs
                      }{" "}
                      CPU(s) / {site.mainLicense.numGPUs} GPU(s))
                    </Typography.Text>
                    <Button
                      icon={<EditOutlined />}
                      size="small"
                      onClick={() => toggleIsEditing()}
                    />
                  </Flex>
                )}
            </Descriptions.Item> */}
          </Descriptions>
        </div>
        <WhiteSpace size="xxl" />
        <Flex justify="between">
          <Typography.Title level={4}>License List</Typography.Title>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => toggleOpenLicenseModal()}
          >
            Create License
          </Button>
        </Flex>
        <WhiteSpace size="sm" />
        <LicenseDetail
          licenseFrgmt={selectedLicense || site.mainLicense}
          loading={isPendingTransition}
          titleExtraContent={
            <Button
              size="small"
              icon={<FilePdfOutlined />}
              onClick={() => {
                navigate(
                  `/sites/${site.objectId}/${
                    (selectedLicense || site.mainLicense)?.objectId
                  }`
                );
              }}
            >
              Certificate
            </Button>
          }
        />
        <WhiteSpace size="sm" />
        <LicenseList
          mainId={site.mainLicense?.id}
          fetchKey={licenseListFetchKey}
          where={{
            site: {
              have: {
                id: {
                  equalTo: site.id,
                },
              },
            },
          }}
          // defaultSelectedId={site.mainLicense?.id}
          selectedLicenseId={selectedLicenseId || site.mainLicense?.id}
          onSelectLicense={(id) => {
            startTransition(() => {
              setSelectedLicenseId(id);
            });
          }}
        />
      </Flex>
      <SiteFormModal
        siteFrgmt={site}
        open={isEditing}
        onRequestClose={() => {
          toggleIsEditing();
        }}
      />
      <LicenseFormModal
        open={openLicenseModal}
        siteFrgmt={site}
        onRequestClose={(completed) => {
          console.log("completed", completed);
          if (completed) {
            updateLicenseListFetchKey();
          }
          toggleOpenLicenseModal();
        }}
        destroyOnClose
      />
      <ContactFormModal
        open={!!editingContactFrgmt}
        contactFrgmt={editingContactFrgmt}
        onRequestClose={(completed) => {
          setEditingContactFrgmt(undefined);
          if (completed) {
            // updateFetchKey();
            // message.success("save successfully");
          }
        }}
      />
    </>
  );
};

export default SiteDetail;

import {
  LockOutlined,
  LoginOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  Button,
  Layout,
  Popconfirm,
  Typography,
  message,
} from "antd";
import _ from "lodash";
import React from "react";
import { useMutation } from "react-relay";
import { useLocation, useNavigate } from "react-router-dom";
import { openJSONErrorModal } from "../helpers";
import { useAuth } from "../hooks/auth";
import graphql from "babel-plugin-relay/macro";
import { LoginResetPasswordMutation } from "./__generated__/LoginResetPasswordMutation.graphql";
import { loginRequest } from "../msAuth/authConfig";
import { callMsGraph } from "../msAuth/graph";
import { useMsal } from "@azure/msal-react";
import Flex from "../components/Flex";
import lablupLogo from "../assets/lablup.png";

const Login: React.FC = () => {
  const {
    login,
    loginViaMS,
    logOutViaMS,
    isInFlightLogIn: isInFlightLogin,
  } = useAuth();
  const { instance, accounts } = useMsal();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as {
    from?: {
      pathname?: string;
    };
  };
  const from = state?.from?.pathname || "/";

  const [form] = Form.useForm();

  const [commitResetPassword, isPendingResetPassword] =
    useMutation<LoginResetPasswordMutation>(graphql`
      mutation LoginResetPasswordMutation($email: String!) {
        resetPassword(input: { email: $email }) {
          ok
        }
      }
    `);
  return (
    <Layout style={{ height: "100vh" }}>
      <Flex direction="column" style={{ width: 360, margin: "auto" }}>
        <img src={lablupLogo} alt="Logo" style={{ width: 340 }} />
        <Typography.Title level={2}>BackOffice</Typography.Title>
        <Button
          type="primary"
          size="large"
          icon={<LoginOutlined />}
          onClick={() => {
            loginViaMS((data, error) => {
              if (error) {
                if (
                  error[0] &&
                  error[0].message === "Invalid username/password."
                ) {
                  form.setFields([
                    {
                      name: "password",
                      errors: ["Invalid username/password."],
                    },
                    {
                      name: "username",
                      errors: ["Invalid username/password."],
                    },
                  ]);
                } else {
                  openJSONErrorModal(error);
                }
              } else {
                navigate(from, { replace: true });
              }
            });
          }}
        >
          Sign in using Lablup Account
        </Button>
      </Flex>
    </Layout>
  );
};

export default Login;

import {
  BarsOutlined,
  CalendarOutlined,
  CarOutlined,
  ContactsOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Row,
  Button,
  Col,
  Table,
  Typography,
  Input,
  Select,
  message,
  Statistic,
  Card,
  theme,
  Calendar,
  Tag,
  Segmented,
} from "antd";
import dayjs from "dayjs";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  CustomerWhereInput,
  SiteListQuery,
  SiteOrder,
  SiteWhereInput,
  UserWhereInput,
} from "./__generated__/SiteListQuery.graphql";
import _ from "lodash";
import {
  extractNonNullableNodesOnConnection,
  mergeWhereInputWithAND,
} from "../helpers";
import { Suspense, useReducer, useState, useTransition } from "react";
import {
  useParseAntdTablePagination,
  useWhereInputList,
} from "../hooks/parseQueryAntdTable";
import Flex from "../components/Flex";
import WhiteSpace from "../components/WhiteSpace";
import WhereInputTagList from "../components/WhereInputTagList";
import { useToggle, useUpdateEffect } from "ahooks";
import { useUpdatableState } from "../hooks";
import SiteFormModal from "../components/modals/SiteFormModal";
import CustomerSelect from "../components/CustomerSelect";
import { useNavigate } from "react-router-dom";
import UserSelect from "../components/UserSelect";
import {
  JsonParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import BackOfficeBreadcrumb from "../components/BackOfficeBreadcrumb";
import SiteCalendar from "../components/SiteCalendar";
import LicenseMiniView from "../components/LicenseMiniView";
import ExpirationDateTag from "../components/ExpirationDateTag";
import UserNameWithHoverDetail from "../components/UserNameWithHoverDetail";
import StatisticNumberOfXPU, {
  FallbackStatisticNumberOfXPU,
} from "../components/StatisticNumberOfXPU";
const { Title } = Typography;

const paginationParams = withDefault(JsonParam, {
  after: 0,
  skip: 0,
  first: 10,
  order: ["updatedAt_DESC"],
});

const whereParams = withDefault(JsonParam, []);
const listModeParams = withDefault(StringParam, "list");
const SiteList = () => {
  const { token } = theme.useToken();

  const [query, setQuery] = useQueryParams({
    pagination: paginationParams,
    where: whereParams,
    customer: JsonParam,
    author: JsonParam,
  });
  const [fetchKey, updateFetchKey] = useUpdatableState("default");
  const [paginationState, paginationAction] =
    useParseAntdTablePagination<SiteOrder>(query.pagination);

  const [searchWhereInputs, searchWhereInputAction] =
    useWhereInputList<SiteWhereInput>(query.where);
  const [selectedCustomerWhere, setSelectedCustomerWhere] =
    useState<CustomerWhereInput | null>(query.customer);
  const [selectedAuthorWhere, setSelectedAuthWhere] =
    useState<UserWhereInput | null>(query.author);
  const [selectedStaticsWhereKey, setSelectedStaticsWhereKey] =
    useState<string>();
  // const [listMode, { toggle: toggleListMode }] = useToggle("list", "calendar");

  const [listMode, setListMode] = useQueryParam("mode", listModeParams);

  useUpdateEffect(() => {
    setQuery({
      pagination: paginationState.parse,
      customer: selectedCustomerWhere,
      author: selectedAuthorWhere,
      where: searchWhereInputs,
    });
  }, [
    JSON.stringify(paginationState.parse),
    JSON.stringify(selectedCustomerWhere),
    JSON.stringify(selectedAuthorWhere),
    JSON.stringify(searchWhereInputs),
  ]);

  const staticsWhereMap: {
    [key: string]: SiteWhereInput;
  } = {
    activeWhere: {
      supportExpirationDate: {
        //WARN: do not use dayjs().toISOString() here. It will cause infinite rendering.
        greaterThanOrEqualTo: dayjs().endOf("day").toISOString(),
      },
    },
    expireInAMonthWhere: {
      AND: [
        {
          supportExpirationDate: {
            greaterThanOrEqualTo: dayjs().endOf("day").toISOString(),
          },
        },
        {
          supportExpirationDate: {
            //WARN: do not use dayjs().toISOString() here. It will cause infinite rendering.
            lessThanOrEqualTo: dayjs()
              .add(1, "month")
              .endOf("day")
              .toISOString(),
          },
        },
      ],
    },
  };

  const mergedWhereInput = mergeWhereInputWithAND<SiteWhereInput>(
    ...searchWhereInputs,
    selectedCustomerWhere && {
      customer: {
        have: selectedCustomerWhere,
      },
    },
    selectedAuthorWhere && {
      author: {
        have: selectedAuthorWhere,
      },
    },
    staticsWhereMap[selectedStaticsWhereKey || ""]
  );

  const { sites, activeSites, expireInAMonthSites } =
    useLazyLoadQuery<SiteListQuery>(
      graphql`
        query SiteListQuery(
          $first: Int
          $skip: Int
          $where: SiteWhereInput
          $order: [SiteOrder!]
          $activeWhere: SiteWhereInput
          $expireInAMonthWhere: SiteWhereInput
        ) {
          sites(first: $first, skip: $skip, order: $order, where: $where) {
            count
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
            }
            edges {
              node {
                id
                objectId
                numCPUs
                numGPUs
                numNPUs
                customer {
                  id
                  name
                }
                createdAt
                updatedAt
                name
                description
                author {
                  id
                  username
                  name
                  ...UserNameWithHoverDetailFragment
                }
                contacts(first: 1, order: updatedAt_DESC) {
                  count
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                assignee {
                  id
                  username
                }
                supportExpirationDate
                contractExpirationDate
                mainLicense {
                  ...LicenseMiniViewFragment
                }
              }
            }
          }
          activeSites: sites(where: $activeWhere) {
            count
          }
          expireInAMonthSites: sites(where: $expireInAMonthWhere) {
            count
          }
        }
      `,
      {
        where: mergedWhereInput,
        expireInAMonthWhere: staticsWhereMap.expireInAMonthWhere,
        activeWhere: staticsWhereMap.activeWhere,
        ...paginationState.parse,
      },
      {
        fetchKey,
        fetchPolicy: "store-and-network",
      }
    );

  const [isPendingLoading, startLoadingTransition] = useTransition();
  const [isWherePending, startWhereTransition] = useTransition();
  const [searchField, setSearchField] = useState("name");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isOpenForm, { toggle: toggleOpenForm }] = useToggle(false);
  const navigate = useNavigate();
  const moveToSiteDetail = (siteObjectId: string) => {
    navigate(`/sites/${siteObjectId}`);
  };
  return (
    <>
      <Title level={2}>
        <CarOutlined /> Sites
      </Title>
      <Flex direction="column" align="stretch">
        <BackOfficeBreadcrumb />
        <WhiteSpace />
        <Flex direction="row" gap={"xs"}>
          <Suspense fallback={<FallbackStatisticNumberOfXPU />}>
            <StatisticNumberOfXPU />
          </Suspense>

          <Card
            onClick={() => {
              startWhereTransition(() => {
                setSelectedStaticsWhereKey(
                  selectedStaticsWhereKey === "activeWhere"
                    ? undefined
                    : "activeWhere"
                );
              });
            }}
            style={{
              cursor: "pointer",
              ...(selectedStaticsWhereKey === "activeWhere" ||
              !selectedStaticsWhereKey
                ? {
                    opacity: 1,
                  }
                : {
                    opacity: 0.3,
                  }),
            }}
          >
            <Statistic
              title="Active T/S"
              value={activeSites.count}
              valueStyle={{ color: token.colorSuccess }}
            />
          </Card>

          <Card
            onClick={() => {
              startWhereTransition(() => {
                setSelectedStaticsWhereKey(
                  selectedStaticsWhereKey === "expireInAMonthWhere"
                    ? undefined
                    : "expireInAMonthWhere"
                );
              });
            }}
            style={{
              cursor: "pointer",
              ...(selectedStaticsWhereKey === "expireInAMonthWhere" ||
              !selectedStaticsWhereKey
                ? {
                    opacity: 1,
                  }
                : {
                    opacity: 0.3,
                  }),
            }}
          >
            <Statistic
              title="Expire T/S in a month"
              value={expireInAMonthSites.count}
              valueStyle={{ color: token.colorError }}
            />
          </Card>
        </Flex>
        <WhiteSpace />
        <Flex justify="between">
          <Flex direction="row">
            <Input.Search
              loading={isWherePending}
              addonBefore={
                <Select value={searchField} onSelect={setSearchField}>
                  <Select.Option value="name">Name</Select.Option>
                  <Select.Option value="description">Desc</Select.Option>
                </Select>
              }
              placeholder={`Search by ${searchField}`}
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              onSearch={(value, event) => {
                if (value.length > 0) {
                  startWhereTransition(() => {
                    searchWhereInputAction.push({
                      [searchField]: {
                        matchesRegex: "(?i)" + value,
                      },
                    });
                  });
                  setSearchKeyword("");
                }
              }}
              style={{ width: 250 }}
            />
            <WhiteSpace direction="row" />
            <CustomerSelect
              style={{ width: 150 }}
              loading={isWherePending}
              placeholder="Customer Filter"
              defaultValue={query.customer?.id?.equalTo}
              allowClear
              onChangeSelectedWhere={(where) => {
                startWhereTransition(() => {
                  setSelectedCustomerWhere(where);
                });
              }}
            />
            <WhiteSpace direction="row" />
            <UserSelect
              loading={isWherePending}
              placeholder="Author Filter"
              defaultValue={query.author?.id?.equalTo}
              allowClear
              onChangeSelectedWhere={(where) => {
                startWhereTransition(() => {
                  setSelectedAuthWhere(where);
                });
              }}
              style={{ width: 200 }}
            />
          </Flex>
          <Flex>
            <Segmented
              value={listMode}
              onChange={(value) => {
                //@ts-ignore
                setListMode(value);
              }}
              options={[
                {
                  value: "list",
                  label: "List",
                  icon: <BarsOutlined />,
                },
                {
                  value: "calendar",
                  label: "Calendar",
                  icon: <CalendarOutlined />,
                },
              ]}
            />
            <WhiteSpace direction="row" size="md" />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                toggleOpenForm();
              }}
            >
              Create Site
            </Button>
          </Flex>
        </Flex>
        <WhiteSpace />
        {searchWhereInputs.length > 0 && (
          <>
            <WhereInputTagList
              whereInputs={searchWhereInputs}
              onRemove={(i) => {
                startWhereTransition(() => {
                  searchWhereInputAction.remove(i);
                });
              }}
            />
            <WhiteSpace />
          </>
        )}
        {listMode === "list" ? (
          <Row>
            <Col span={24}>
              <Table
                rowKey={(record) => record?.id}
                loading={isPendingLoading}
                showSorterTooltip={false}
                pagination={{
                  pageSize: paginationState.antd.pageSize,
                  current: paginationState.antd.current,
                  total: sites?.count || 0,
                  showSizeChanger: true,
                  showTotal(total, range) {
                    return `${range[0]}-${range[1]} of ${total} items`;
                  },
                }}
                scroll={{ x: 1300 }}
                onChange={(pagination, filter, sorter) => {
                  startLoadingTransition(() => {
                    paginationAction.onChangeAntdTable(pagination, sorter);
                  });
                }}
                size="small"
                columns={[
                  {
                    title: "Name",
                    dataIndex: "name",
                    sorter: true,
                    sortOrder: paginationState.antd.sortOrder["name"],
                    render(value, record, index) {
                      return (
                        <Flex direction="column" align="start">
                          <Typography.Link
                            onClick={() => {
                              moveToSiteDetail(record.objectId);
                            }}
                          >
                            {value}
                          </Typography.Link>
                          <Typography.Text
                            type="secondary"
                            style={{ fontSize: token.fontSizeSM }}
                          >
                            {record.objectId}
                          </Typography.Text>
                        </Flex>
                      );
                    },
                    fixed: "left",
                  },
                  {
                    title: (
                      <Flex direction="column" align="stretch">
                        <span>Customer</span>
                      </Flex>
                    ),

                    dataIndex: ["customer", "name"],
                  },
                  {
                    title: "CPU / GPU / NPU",
                    render(value, record, index) {
                      return (
                        <Flex direction="row">
                          {record.numGPUs || "-"}
                          {" / "}
                          {record.numCPUs || "-"}
                          {" / "}
                          {record.numNPUs || "-"}
                        </Flex>
                      );
                    },
                  },
                  {
                    title: "Contract Expiration",
                    dataIndex: "contractExpirationDate",
                    render(value, record, index) {
                      return value ? dayjs(value).format("ll") : "-";
                    },
                    sorter: true,
                    sortOrder:
                      paginationState.antd.sortOrder["contractExpirationDate"],
                  },
                  {
                    title: "Support Expiration",
                    dataIndex: "supportExpirationDate",
                    render(value, record, index) {
                      if (!value) return "-";
                      return <ExpirationDateTag isoString={value} />;
                    },
                    sorter: true,
                    sortOrder:
                      paginationState.antd.sortOrder["supportExpirationDate"],
                  },
                  {
                    title: "License",
                    dataIndex: "mainLicense",
                    render(value) {
                      return <LicenseMiniView licenseFrgmt={value} />;
                    },
                  },
                  // {
                  //   title: "Status",
                  //   render(value, record, index) {
                  //     return (
                  //       <>
                  //         {record.contractExpirationDate && (
                  //           <Tag>
                  //             Contract:{" "}
                  //             {dayjs(record.contractExpirationDate).format("ll")}
                  //           </Tag>
                  //         )}
                  //         <Tag>Support {}</Tag>
                  //       </>
                  //     );
                  //   },
                  //   // dataIndex: "not_existed",
                  // },
                  // {
                  //   title: "Description",
                  //   dataIndex: "description",
                  //   render(value, record, index) {
                  //     return <Typography.Text ellipsis>{value}</Typography.Text>;
                  //   },
                  // },
                  {
                    title: "Updated",
                    dataIndex: "updatedAt",
                    render(value, record, index) {
                      return dayjs(value).format("lll");
                    },
                    sorter: true,
                    sortOrder: paginationState.antd.sortOrder["updatedAt"],
                  },
                  {
                    title: "Author",
                    dataIndex: ["author", "username"],
                    render(value, record, index) {
                      return (
                        <UserNameWithHoverDetail userFrgmt={record.author} />
                      );
                    },
                    // render(value, record, index) {
                    //   return record.author?.name || record.author?.username;
                    // },
                  },
                  {
                    title: "Contact",
                    dataIndex: "contacts",
                    render(value, record, index) {
                      return record.contacts.count;
                    },
                  },

                  // {
                  //   title: (
                  //     <Flex direction="column" align="stretch">
                  //       <span>Assignee</span>
                  //     </Flex>
                  //   ),
                  //   dataIndex: ["assignee", "username"],
                  // },
                ]}
                dataSource={extractNonNullableNodesOnConnection(sites)}
                sortDirections={["descend", "ascend", null]}
              />
            </Col>
          </Row>
        ) : (
          <SiteCalendar whereInput={mergedWhereInput || null} />
        )}
      </Flex>
      <SiteFormModal
        open={isOpenForm}
        onRequestClose={(completed) => {
          toggleOpenForm();
          if (completed) {
            updateFetchKey();
            message.success("save successfully");
          }
        }}
        destroyOnClose={false}
      />
    </>
  );
};

export default SiteList;

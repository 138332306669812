import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import _ from "lodash";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const breadcrumbNameMap: Record<string, string> = {
  "/sites": "Sites",
  "/customers": "Customers",
  "/contacts": "Contacts",
};

const BackOfficeBreadcrumb: React.FC<{
  aliasMap?: Record<string, string>;
}> = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((value, idx) => {
    const url = `/${pathSnippets.slice(0, idx + 1).join("/")}`;
    const title = breadcrumbNameMap[url] || value;
    return (
      <Breadcrumb.Item key={url}>
        {_.last(pathSnippets) === value ? title : <Link to={url}>{title}</Link>}
      </Breadcrumb.Item>
    );
  });

  return (
    <Breadcrumb>
      <Breadcrumb.Item key="home">
        <Link to="/">
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>
      {extraBreadcrumbItems}
    </Breadcrumb>
  );
};

export default BackOfficeBreadcrumb;

/**
 * @generated SignedSource<<ae753dc65d5de09f9bce397ed27773b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<UserWhereInput> | null;
  NOR?: ReadonlyArray<UserWhereInput> | null;
  OR?: ReadonlyArray<UserWhereInput> | null;
  authData?: ObjectWhereInput | null;
  createdAt?: DateWhereInput | null;
  email?: StringWhereInput | null;
  emailVerified?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  password?: StringWhereInput | null;
  protectedFields?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
  username?: StringWhereInput | null;
};
export type ObjectWhereInput = {
  equalTo?: KeyValueInput | null;
  exists?: boolean | null;
  greaterThan?: KeyValueInput | null;
  greaterThanOrEqualTo?: KeyValueInput | null;
  in?: ReadonlyArray<KeyValueInput | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: KeyValueInput | null;
  lessThanOrEqualTo?: KeyValueInput | null;
  notEqualTo?: KeyValueInput | null;
  notIn?: ReadonlyArray<KeyValueInput | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
  key: string;
  value: any;
};
export type SelectInput = {
  key: string;
  query: SubqueryInput;
};
export type SubqueryInput = {
  className: string;
  where: any;
};
export type DateWhereInput = {
  equalTo?: any | null;
  exists?: boolean | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type StringWhereInput = {
  equalTo?: string | null;
  exists?: boolean | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: ReadonlyArray<string | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  matchesRegex?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
  notInQueryKey?: SelectInput | null;
  options?: string | null;
  text?: TextInput | null;
};
export type TextInput = {
  search: SearchInput;
};
export type SearchInput = {
  caseSensitive?: boolean | null;
  diacriticSensitive?: boolean | null;
  language?: string | null;
  term: string;
};
export type BooleanWhereInput = {
  equalTo?: boolean | null;
  exists?: boolean | null;
  inQueryKey?: SelectInput | null;
  notEqualTo?: boolean | null;
  notInQueryKey?: SelectInput | null;
};
export type IdWhereInput = {
  equalTo?: string | null;
  exists?: boolean | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: ReadonlyArray<string | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type UserSelectQuery$variables = {
  selectedUserId: string;
  skipCurrentUser: boolean;
  where?: UserWhereInput | null;
};
export type UserSelectQuery$data = {
  readonly user?: {
    readonly email: string | null;
    readonly id: string;
    readonly name: string | null;
    readonly objectId: string;
    readonly username: string | null;
  };
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly email: string | null;
        readonly id: string;
        readonly name: string | null;
        readonly objectId: string;
        readonly username: string | null;
      } | null;
    } | null> | null;
  };
};
export type UserSelectQuery = {
  response: UserSelectQuery$data;
  variables: UserSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedUserId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipCurrentUser"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "UserConnection",
    "kind": "LinkedField",
    "name": "users",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "condition": "skipCurrentUser",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "selectedUserId"
          }
        ],
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSelectQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserSelectQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "672d797ed29400b804b64ad5af94b939",
    "id": null,
    "metadata": {},
    "name": "UserSelectQuery",
    "operationKind": "query",
    "text": "query UserSelectQuery(\n  $where: UserWhereInput\n  $skipCurrentUser: Boolean!\n  $selectedUserId: ID!\n) {\n  users(where: $where) {\n    edges {\n      node {\n        id\n        objectId\n        username\n        name\n        email\n      }\n    }\n  }\n  user(id: $selectedUserId) @skip(if: $skipCurrentUser) {\n    id\n    objectId\n    username\n    name\n    email\n  }\n}\n"
  }
};
})();

(node as any).hash = "01085a6f545462343ceeaf202907e5b5";

export default node;

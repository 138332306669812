import { Tag, TagProps, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";

export interface ExpirationDateTagProps extends TagProps {
  isoString: string | null | undefined;
}
const ExpirationDateTag: React.FC<ExpirationDateTagProps> = ({
  isoString,
  ...tagProps
}) => {
  if (isoString === null || isoString === undefined) return null;
  return dayjs(isoString).isBefore(dayjs().add(1, "month")) ? (
    <Tag color="red" {...tagProps}>
      {dayjs(isoString).format("ll")} : {dayjs().diff(dayjs(isoString), "day")}d
    </Tag>
  ) : (
    <Typography.Text>{dayjs(isoString).format("ll")}</Typography.Text>
  );
};

export default ExpirationDateTag;

import { BlobProvider } from "@react-pdf/renderer";
import { MutableRefObject, useRef, useState } from "react";
import PDFLicenseCertificate from "../components/PDFLicenseCertificate";
import { Document, Page, pdfjs } from "react-pdf";

import _ from "lodash";
import Flex from "../components/Flex";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import PDFContainer from "../components/PDFContainer";
import { Button, Card, Form, Input, Typography, message } from "antd";
import LicenseDetail from "../components/LicenseDetail";
import { useLazyLoadQuery, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { useParams } from "react-router-dom";
import { LicenseCertificationPageQuery } from "./__generated__/LicenseCertificationPageQuery.graphql";
import { useDebounceFn } from "ahooks";
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import BackOfficeBreadcrumb from "../components/BackOfficeBreadcrumb";
import { LicenseCertificationPageMutation } from "./__generated__/LicenseCertificationPageMutation.graphql";
import { openJSONErrorModal } from "../helpers";
//@ts-ignore

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

type PDFFile = string | File | null;

const LicenseCertificationPage = () => {
  const canvas = useRef<HTMLCanvasElement>();
  const [form] = Form.useForm();
  const [isPDFLoading, setIsPDFLoading] = useState(true);
  const { licenseObjectId } = useParams<{
    licenseObjectId: string;
  }>();

  const { license } = useLazyLoadQuery<LicenseCertificationPageQuery>(
    graphql`
      query LicenseCertificationPageQuery($licenseObjectId: ID!) {
        license(id: $licenseObjectId) {
          id
          objectId
          updatedAt
          createdAt
          hardwareSummary
          certificateOptions
          ...LicenseDetailFragment
          site {
            id
            name
            customer {
              name
            }
          }
        }
      }
    `,
    {
      licenseObjectId: licenseObjectId || "",
    }
  );

  const [commitUseMutation, isPendingMutation] =
    useMutation<LicenseCertificationPageMutation>(graphql`
      mutation LicenseCertificationPageMutation(
        $licenseId: ID!
        $certificateOptions: Object
      ) {
        updateLicense(
          input: {
            id: $licenseId
            fields: { certificateOptions: $certificateOptions }
          }
        ) {
          license {
            id
            objectId
            certificateOptions
            updatedAt
          }
        }
      }
    `);

  const [, setState] = useState(0);
  const { run: debouncedReRender } = useDebounceFn(
    () => {
      setState((v) => v + 1);
    },
    {
      wait: 500,
    }
  );

  const defaultContentMd = _.trim(`
- 소프트웨어 : Backend.AI Enterprise EssentialR2
- 사용 범위
${_.map(
  license.hardwareSummary?.modelNames,
  (value, key) => `  1. ${key} ${value}식`
).join("\n")}
  1. 비고 : 
- 라이센스 모델 : Perpetual
- 사용 기간 : 영구
- 기술 지원 : 1년간 유지보수 포함
- 라이센스 키 : 난수화 형태로 사용 기관 사이트 내 적용 완료
`);

  return (
    <>
      <Typography.Title level={2}>
        <FilePdfOutlined /> Certificate
      </Typography.Title>

      <Flex direction="column" align="stretch" gap="sm">
        <BackOfficeBreadcrumb />
        <LicenseDetail licenseFrgmt={license} />
        <Flex
          direction="row"
          justify="start"
          align="stretch"
          gap={"sm"}
          style={{ height: "100%" }}
        >
          <Card style={{ flex: 1 }}>
            <Flex direction="column" align="stretch">
              <Form
                onValuesChange={(changedValue) => {
                  debouncedReRender();
                }}
                form={form}
                initialValues={
                  license.certificateOptions || {
                    certificateSeq: `제 ${dayjs(
                      license.createdAt
                    ).year()} - BA - ${license.objectId}호`,
                    publishedAt: dayjs(license.createdAt).format(
                      "YYYY년 M월 D일"
                    ),
                    contentMd: defaultContentMd,
                    customerName: license?.site?.customer?.name,
                  }
                }
                layout="vertical"
              >
                <Form.Item label="인증번호" name="certificateSeq">
                  <Input />
                </Form.Item>
                <Form.Item label="발행일" name="publishedAt">
                  <Input />
                </Form.Item>
                <Form.Item label="사용기관" name="customerName">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="내용"
                  name="contentMd"
                  help="Support markdown related to listing only"
                >
                  <Input.TextArea style={{ minHeight: 250 }} />
                </Form.Item>
              </Form>
            </Flex>
          </Card>
          <Card bordered style={{ flex: 1 }}>
            <BlobProvider
              document={
                <PDFLicenseCertificate
                  certificateSeq={form.getFieldValue("certificateSeq")}
                  customerName={form.getFieldValue("customerName")}
                  publishedAt={form.getFieldValue("publishedAt")}
                  contentMd={form.getFieldValue("contentMd")}
                />
              }
            >
              {({ blob, url }) => {
                return url ? (
                  <Flex direction="column" gap={"sm"}>
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      loading={isPendingMutation}
                      disabled={isPDFLoading}
                      onClick={() => {
                        form.validateFields().then((values) => {
                          commitUseMutation({
                            variables: {
                              licenseId: license.id,
                              certificateOptions: values,
                            },
                            onCompleted(response, errors) {
                              if (errors) {
                                openJSONErrorModal(errors);
                              } else {
                                var link = document.createElement("a");
                                link.setAttribute(
                                  "download",
                                  `certificate_${license.site.customer?.name}_${license.site.name}_${license.objectId}_${values.certificateSeq}.pdf`
                                );
                                link.href = url;
                                document.body.appendChild(link);
                                link.click();
                                link.remove();
                                // message.success("Saved");
                              }
                            },
                          });
                        });
                      }}
                    >
                      Save & Download
                    </Button>
                    <PDFContainer
                      style={{
                        flex: 1,
                        aspectRatio: "0.7071135624",
                        border: "1px solid #888",
                      }}
                    >
                      <Document
                        file={url}
                        key={url}
                        onLoadStart={() => {
                          setIsPDFLoading(true);
                        }}
                        onLoadError={() => {
                          setIsPDFLoading(false);
                        }}
                        onLoadSuccess={({ numPages }: { numPages: number }) => {
                          // setNumPages(numPages);
                          setIsPDFLoading(false);
                        }}
                        loading={<Typography.Text></Typography.Text>}
                        error={<Typography.Text></Typography.Text>}
                      >
                        <Page
                          pageNumber={1}
                          //@ts-ignore
                          canvasRef={canvas}
                          loading={<Typography.Text></Typography.Text>}
                          onRenderSuccess={() => {
                            canvas && drawWaterMarkOnCanvas(canvas);
                          }}
                        />
                      </Document>
                    </PDFContainer>
                  </Flex>
                ) : (
                  <Flex direction="column" gap={"sm"}>
                    <Button loading type="primary" icon={<DownloadOutlined />}>
                      Loading
                    </Button>
                  </Flex>
                );
              }}
            </BlobProvider>
          </Card>
        </Flex>
      </Flex>
    </>
  );
};

function degreesToRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

function drawWaterMarkOnCanvas(
  canvasRef: MutableRefObject<HTMLCanvasElement | undefined>
) {
  if (!canvasRef.current) {
    return;
  }
  var context = canvasRef.current.getContext("2d");
  var { width, height } = canvasRef.current;

  if (context) {
    context.save();
    const angle = 45;
    context.translate(width / 2 - 150, height / 2 + 150);
    context.rotate(degreesToRadians(angle));
    context.globalCompositeOperation = "multiply";
    context.textAlign = "center";
    context.font = "500px sans-serif";
    context.fillStyle = "rgba(0, 0, 0, .25)";
    context.fillText("DRAFT", 0, 0);
    context.restore();
  }
}

export default LicenseCertificationPage;

import { Form, Input, Modal, ModalProps } from "antd";
import React from "react";
import { UseMutationConfig, useFragment, useMutation } from "react-relay";
import WhiteSpace from "../WhiteSpace";
import graphql from "babel-plugin-relay/macro";
import { openJSONErrorModal } from "../../helpers";
import { CustomerFormModalCustomerFragment$key } from "./__generated__/CustomerFormModalCustomerFragment.graphql";
import {
  CreateCustomerInput,
  CustomerFormModalMutation,
} from "./__generated__/CustomerFormModalMutation.graphql";
import { CustomerFormModalUpdateMutation } from "./__generated__/CustomerFormModalUpdateMutation.graphql";

interface CustomerFormModalProps extends ModalProps {
  customerFrgmt?: CustomerFormModalCustomerFragment$key | null;
  onRequestClose: (completed: boolean) => void;
}
interface CustomerFormInput {
  name: string;
  description: string;
}
const CustomerFormModal: React.FC<CustomerFormModalProps> = ({
  customerFrgmt,
  onRequestClose,
  ...modalProps
}) => {
  const [form] = Form.useForm<CustomerFormInput>();

  const customer = useFragment(
    graphql`
      fragment CustomerFormModalCustomerFragment on Customer {
        id
        objectId
        createdAt
        updatedAt
        author {
          id
          name
        }
        description
        name
      }
    `,
    customerFrgmt || null
  );
  const [commitCreate, isInFlightCrete] =
    useMutation<CustomerFormModalMutation>(graphql`
      mutation CustomerFormModalMutation($input: CreateCustomerInput!) {
        createCustomer(input: $input) {
          customer {
            id
            ...CustomerFormModalCustomerFragment
          }
        }
      }
    `);
  const [commitUpdate, isInFlightUpdate] =
    useMutation<CustomerFormModalUpdateMutation>(graphql`
      mutation CustomerFormModalUpdateMutation($input: UpdateCustomerInput!) {
        updateCustomer(input: $input) {
          customer {
            id
            ...CustomerFormModalCustomerFragment
          }
        }
      }
    `);
  console.log(customer);
  return (
    <Modal
      title="Customer Editor"
      okText={"Save"}
      destroyOnClose={true}
      onOk={() => {
        form.validateFields().then((values) => {
          const input: CreateCustomerInput = {
            fields: {
              name: values.name,
              description: values.description,
            },
          };
          const handlers: Partial<
            Pick<
              UseMutationConfig<
                CustomerFormModalUpdateMutation | CustomerFormModalMutation
              >,
              "onCompleted" | "onError"
            >
          > = {
            onCompleted(response, errors) {
              if (errors) {
                openJSONErrorModal(errors);
              } else {
                onRequestClose(true);
              }
            },
            onError(errors) {
              openJSONErrorModal(errors);
            },
          };
          customer
            ? commitUpdate({
                variables: {
                  input: {
                    id: customer.id,
                    ...input,
                  },
                },
                ...handlers,
              })
            : commitCreate({
                variables: {
                  input,
                },
                ...handlers,
              });
        });
      }}
      maskClosable={false}
      onCancel={() => onRequestClose(false)}
      {...modalProps}
    >
      <WhiteSpace />
      <Form
        form={form}
        preserve={false}
        initialValues={
          customer
            ? ({
                name: customer.name,
                description: customer.description,
              } as CustomerFormInput)
            : {}
        }
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CustomerFormModal;

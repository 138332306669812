/**
 * @generated SignedSource<<2c01f42802b125e32e44633c2b29f271>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateSiteInput = {
  clientMutationId?: string | null;
  fields?: CreateSiteFieldsInput | null;
};
export type CreateSiteFieldsInput = {
  ACL?: ACLInput | null;
  assignee?: UserPointerInput | null;
  author?: UserPointerInput | null;
  contacts?: ContactRelationInput | null;
  contractExpirationDate?: any | null;
  contractProductType?: string | null;
  contractStartDate?: any | null;
  customer?: CustomerPointerInput | null;
  deletedAt?: any | null;
  deletedBy?: UserPointerInput | null;
  description?: string | null;
  isDeleted?: boolean | null;
  mainLicense?: LicensePointerInput | null;
  name: string;
  numCPUs?: number | null;
  numGPUs?: number | null;
  numNPUs?: number | null;
  purchaseOrderId?: string | null;
  supportExpirationDate?: any | null;
};
export type ACLInput = {
  public?: PublicACLInput | null;
  roles?: ReadonlyArray<RoleACLInput> | null;
  users?: ReadonlyArray<UserACLInput> | null;
};
export type UserACLInput = {
  read: boolean;
  userId: string;
  write: boolean;
};
export type RoleACLInput = {
  read: boolean;
  roleName: string;
  write: boolean;
};
export type PublicACLInput = {
  read: boolean;
  write: boolean;
};
export type UserPointerInput = {
  createAndLink?: CreateUserFieldsInput | null;
  link?: string | null;
};
export type CreateUserFieldsInput = {
  ACL?: ACLInput | null;
  authData?: any | null;
  email?: string | null;
  emailVerified?: boolean | null;
  name?: string | null;
  password?: string | null;
  protectedFields?: string | null;
  username?: string | null;
};
export type ContactRelationInput = {
  add?: ReadonlyArray<string> | null;
  createAndAdd?: ReadonlyArray<CreateContactFieldsInput> | null;
  remove?: ReadonlyArray<string> | null;
};
export type CreateContactFieldsInput = {
  ACL?: ACLInput | null;
  author?: UserPointerInput | null;
  customer?: CustomerPointerInput | null;
  description?: string | null;
  email: string;
  name: string;
  phone?: string | null;
};
export type CustomerPointerInput = {
  createAndLink?: CreateCustomerFieldsInput | null;
  link?: string | null;
};
export type CreateCustomerFieldsInput = {
  ACL?: ACLInput | null;
  author?: UserPointerInput | null;
  description?: string | null;
  name: string;
};
export type LicensePointerInput = {
  createAndLink?: CreateLicenseFieldsInput | null;
  link?: string | null;
};
export type CreateLicenseFieldsInput = {
  ACL?: ACLInput | null;
  author?: UserPointerInput | null;
  certificateOptions?: any | null;
  description?: string | null;
  disabled?: boolean | null;
  expirationDate: any;
  hardwareInfoList?: ReadonlyArray<any | null> | null;
  hardwareSummary?: any | null;
  licenseInfo?: any | null;
  licenseType?: string | null;
  name?: string | null;
  numCPUs?: number | null;
  numGPUs?: number | null;
  numNPUs?: number | null;
  pemInfo?: any | null;
  site: SitePointerInput;
  status?: string | null;
  type?: string | null;
};
export type SitePointerInput = {
  createAndLink?: CreateSiteFieldsInput | null;
  link?: string | null;
};
export type SiteFormModalMutation$variables = {
  input: CreateSiteInput;
};
export type SiteFormModalMutation$data = {
  readonly createSite: {
    readonly site: {
      readonly " $fragmentSpreads": FragmentRefs<"SiteFormModalSiteFragment">;
    };
  } | null;
};
export type SiteFormModalMutation = {
  response: SiteFormModalMutation$data;
  variables: SiteFormModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  }
],
v5 = [
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteFormModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSitePayload",
        "kind": "LinkedField",
        "name": "createSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SiteFormModalSiteFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteFormModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSitePayload",
        "kind": "LinkedField",
        "name": "createSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "assignee",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  },
                  {
                    "kind": "Literal",
                    "name": "order",
                    "value": "updatedAt_DESC"
                  }
                ],
                "concreteType": "ContactConnection",
                "kind": "LinkedField",
                "name": "contacts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Contact",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "contacts(first:100,order:\"updatedAt_DESC\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractProductType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractStartDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractExpirationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "supportExpirationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "purchaseOrderId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numCPUs",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numGPUs",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numNPUs",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f52c83b505769b82c084db780ea203d7",
    "id": null,
    "metadata": {},
    "name": "SiteFormModalMutation",
    "operationKind": "mutation",
    "text": "mutation SiteFormModalMutation(\n  $input: CreateSiteInput!\n) {\n  createSite(input: $input) {\n    site {\n      ...SiteFormModalSiteFragment\n      id\n    }\n  }\n}\n\nfragment SiteFormModalSiteFragment on Site {\n  id\n  name\n  description\n  assignee {\n    id\n    username\n  }\n  customer {\n    id\n    name\n  }\n  contacts(first: 100, order: updatedAt_DESC) {\n    count\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  author {\n    id\n    username\n  }\n  contractProductType\n  contractStartDate\n  contractExpirationDate\n  supportExpirationDate\n  purchaseOrderId\n  numCPUs\n  numGPUs\n  numNPUs\n}\n"
  }
};
})();

(node as any).hash = "bf281e4f0a2d9ff35b833d5d532672b0";

export default node;

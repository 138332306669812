/**
 * @generated SignedSource<<e068ff1eef248b5eb99b340a9b86a151>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LicenseCertificationPageQuery$variables = {
  licenseObjectId: string;
};
export type LicenseCertificationPageQuery$data = {
  readonly license: {
    readonly certificateOptions: any | null;
    readonly createdAt: any;
    readonly hardwareSummary: any | null;
    readonly id: string;
    readonly objectId: string;
    readonly site: {
      readonly customer: {
        readonly name: string;
      } | null;
      readonly id: string;
      readonly name: string;
    };
    readonly updatedAt: any;
    readonly " $fragmentSpreads": FragmentRefs<"LicenseDetailFragment">;
  };
};
export type LicenseCertificationPageQuery = {
  response: LicenseCertificationPageQuery$data;
  variables: LicenseCertificationPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "licenseObjectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "licenseObjectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hardwareSummary",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "certificateOptions",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numCPUs",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numGPUs",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "hardwareInfoList",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "type": "Element",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v2/*: any*/)
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LicenseCertificationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "License",
        "kind": "LinkedField",
        "name": "license",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LicenseDetailFragment"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LicenseCertificationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "License",
        "kind": "LinkedField",
        "name": "license",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "License",
                "kind": "LinkedField",
                "name": "mainLicense",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractExpirationDate",
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expirationDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "licenseInfo",
            "storageKey": null
          },
          (v11/*: any*/),
          (v10/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a4e0cc008cb3f1a7e1b5e582f32fbbc7",
    "id": null,
    "metadata": {},
    "name": "LicenseCertificationPageQuery",
    "operationKind": "query",
    "text": "query LicenseCertificationPageQuery(\n  $licenseObjectId: ID!\n) {\n  license(id: $licenseObjectId) {\n    id\n    objectId\n    updatedAt\n    createdAt\n    hardwareSummary\n    certificateOptions\n    ...LicenseDetailFragment\n    site {\n      id\n      name\n      customer {\n        name\n        id\n      }\n    }\n  }\n}\n\nfragment LicenseDetailFragment on License {\n  id\n  objectId\n  name\n  description\n  site {\n    id\n    objectId\n    name\n    mainLicense {\n      id\n    }\n    ...LicenseFormModalSiteFragment\n  }\n  expirationDate\n  licenseInfo\n  hardwareInfoList {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  hardwareSummary\n  numGPUs\n  numCPUs\n  status\n  ...LicenseFormModalLicenseFragment\n}\n\nfragment LicenseFormModalLicenseFragment on License {\n  id\n  name\n  description\n  hardwareInfoList {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  type\n  expirationDate\n  numCPUs\n  numGPUs\n  site {\n    id\n    name\n    numCPUs\n    numGPUs\n    mainLicense {\n      id\n      numCPUs\n      numGPUs\n      hardwareInfoList {\n        __typename\n        ... on Element {\n          value\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment LicenseFormModalSiteFragment on Site {\n  id\n  contractExpirationDate\n}\n"
  }
};
})();

(node as any).hash = "1d4e8ef2ec17b7c146bfa4e5fad82c6b";

export default node;

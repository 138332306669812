import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { Button, Layout, Menu } from "antd";
import {
  CarOutlined,
  ContactsOutlined,
  HomeOutlined,
  LoadingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Suspense } from "react";
import { useLocalStorageState } from "ahooks";
import UserView from "./UserView";
import { useAuth } from "../hooks/auth";
import _ from "lodash";

const { Sider, Content } = Layout;

function MainLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [sideCollapsed, setSideCollapsed] = useLocalStorageState<boolean>(
    "sideCollapsed",
    {
      defaultValue: false,
    }
  );
  const { roleNames } = useAuth();

  return (
    <Layout>
      <Sider
        collapsed={sideCollapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          top: 0,
          left: 0,
        }}
      >
        <div
          style={{
            padding: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={sideCollapsed ? {} : { position: "absolute", left: 16 }}>
            <Button
              size="small"
              type="text"
              style={{
                color: "white",
                borderWidth: 0,
              }}
              icon={
                sideCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
              }
              onClick={() => {
                setSideCollapsed((v) => !v);
              }}
            />
          </div>
          {sideCollapsed ? null : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="https://www.lablup.com/assets/lablup.png"
                style={{ width: "80px" }}
                alt="Lablup Inc. Logo"
              />
              <span style={{ color: "white" }}>BACKOFFICE</span>
            </div>
          )}
        </div>
        <div
          style={
            sideCollapsed
              ? { paddingLeft: 24, paddingTop: 16 }
              : { padding: 16 }
          }
        >
          <UserView collapsed={sideCollapsed} />
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={[location.pathname.slice(1)]} // remove first '/'.
          items={[
            {
              label: "Home",
              icon: <HomeOutlined />,
              key: "",
            },
            {
              type: "divider",
            },
            {
              type: "divider",
            },
            _.some(roleNames, (r) =>
              ["accountManager", "accountMember"].includes(r || "")
            )
              ? {
                  type: "group",
                  label: "Customer Management",
                  children: [
                    {
                      label: "Customers",
                      icon: <ContactsOutlined />,
                      key: "customers",
                    },
                    {
                      label: "Sites",
                      icon: <CarOutlined />,
                      key: "sites",
                    },
                    {
                      label: "Contacts",
                      icon: <PhoneOutlined />,
                      key: "contacts",
                    },
                  ],
                }
              : null,

            // {
            //   label: "Licenses",
            //   icon: <SafetyCertificateOutlined />,
            //   key: "licenses",
            // },
          ]}
          onClick={({ keyPath }) => {
            navigate(keyPath.join("/"));
          }}
        ></Menu>
      </Sider>
      <Layout>
        <Content style={{ padding: 16 }}>
          <Suspense fallback={<LoadingOutlined spin />}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;

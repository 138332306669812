import { Spin, SpinProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const BOSpin: React.FC<SpinProps> = (props) => (
  <Spin
    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    {...props}
  />
);

export default BOSpin;

/**
 * @generated SignedSource<<54d8118955123f815ceae303c63bd71a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CustomerWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<CustomerWhereInput> | null;
  NOR?: ReadonlyArray<CustomerWhereInput> | null;
  OR?: ReadonlyArray<CustomerWhereInput> | null;
  author?: UserRelationWhereInput | null;
  createdAt?: DateWhereInput | null;
  description?: StringWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  updatedAt?: DateWhereInput | null;
};
export type ObjectWhereInput = {
  equalTo?: KeyValueInput | null;
  exists?: boolean | null;
  greaterThan?: KeyValueInput | null;
  greaterThanOrEqualTo?: KeyValueInput | null;
  in?: ReadonlyArray<KeyValueInput | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: KeyValueInput | null;
  lessThanOrEqualTo?: KeyValueInput | null;
  notEqualTo?: KeyValueInput | null;
  notIn?: ReadonlyArray<KeyValueInput | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
  key: string;
  value: any;
};
export type SelectInput = {
  key: string;
  query: SubqueryInput;
};
export type SubqueryInput = {
  className: string;
  where: any;
};
export type UserRelationWhereInput = {
  exists?: boolean | null;
  have?: UserWhereInput | null;
  haveNot?: UserWhereInput | null;
};
export type UserWhereInput = {
  ACL?: ObjectWhereInput | null;
  AND?: ReadonlyArray<UserWhereInput> | null;
  NOR?: ReadonlyArray<UserWhereInput> | null;
  OR?: ReadonlyArray<UserWhereInput> | null;
  authData?: ObjectWhereInput | null;
  createdAt?: DateWhereInput | null;
  email?: StringWhereInput | null;
  emailVerified?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  password?: StringWhereInput | null;
  protectedFields?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
  username?: StringWhereInput | null;
};
export type DateWhereInput = {
  equalTo?: any | null;
  exists?: boolean | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type StringWhereInput = {
  equalTo?: string | null;
  exists?: boolean | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: ReadonlyArray<string | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  matchesRegex?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
  notInQueryKey?: SelectInput | null;
  options?: string | null;
  text?: TextInput | null;
};
export type TextInput = {
  search: SearchInput;
};
export type SearchInput = {
  caseSensitive?: boolean | null;
  diacriticSensitive?: boolean | null;
  language?: string | null;
  term: string;
};
export type BooleanWhereInput = {
  equalTo?: boolean | null;
  exists?: boolean | null;
  inQueryKey?: SelectInput | null;
  notEqualTo?: boolean | null;
  notInQueryKey?: SelectInput | null;
};
export type IdWhereInput = {
  equalTo?: string | null;
  exists?: boolean | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: ReadonlyArray<string | null> | null;
  inQueryKey?: SelectInput | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
  notInQueryKey?: SelectInput | null;
};
export type CustomerSelectQuery$variables = {
  where?: CustomerWhereInput | null;
};
export type CustomerSelectQuery$data = {
  readonly customers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly objectId: string;
      } | null;
    } | null> | null;
  };
};
export type CustomerSelectQuery = {
  response: CustomerSelectQuery$data;
  variables: CustomerSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "CustomerConnection",
    "kind": "LinkedField",
    "name": "customers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerSelectQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerSelectQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ac04d4bd80eba3d82e0b33dd8857d9b9",
    "id": null,
    "metadata": {},
    "name": "CustomerSelectQuery",
    "operationKind": "query",
    "text": "query CustomerSelectQuery(\n  $where: CustomerWhereInput\n) {\n  customers(where: $where) {\n    edges {\n      node {\n        id\n        objectId\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9bf10c330a3c04533e2626e2cdea06f4";

export default node;

import React from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { LicenseMiniViewFragment$key } from "./__generated__/LicenseMiniViewFragment.graphql";
import { Tag } from "antd";
import ExpirationDateTag from "./ExpirationDateTag";
const LicenseMiniView: React.FC<{
  licenseFrgmt: LicenseMiniViewFragment$key | null;
}> = ({ licenseFrgmt }) => {
  const license = useFragment(
    graphql`
      fragment LicenseMiniViewFragment on License {
        id
        objectId
        expirationDate
        status
      }
    `,
    licenseFrgmt
  );

  if (license === null) return null;
  return (
    <ExpirationDateTag
      isoString={license.expirationDate}
      style={{ margin: 0 }}
    />
  );
};

export default LicenseMiniView;

import {
  LoadingOutlined,
  LockOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Form, Input, Button, Layout, Spin } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/auth";

const FullScreenLoadingFallback: React.FC = () => (
  <div
    style={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
  </div>
);

export default FullScreenLoadingFallback;

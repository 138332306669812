import { useLocalStorageState } from "ahooks";
import { ConfigProvider } from "antd";
import { Suspense, useMemo, useState } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import MainLayout from "./components/MainLayout";
import {
  AuthProviderWithErrorBoundary,
  RequireAuth,
  USER_TOKEN_LOCAL_STORAGE_KEY,
} from "./hooks/auth";
import { createRelayEnvironment } from "./RelayEnvironment";
import CustomerList from "./screens/CustomerList";
import ContactList from "./screens/ContactList";
import FullScreenLoadingFallback from "./screens/FullScreenLoadingFallback";
import Home from "./screens/Home";
import LicenseListScreen from "./screens/LicenseListScren";
import Login from "./screens/Login";
import NoMatch from "./screens/NoMatch";
import SiteDetail from "./screens/SiteDetail";
import SiteList from "./screens/SiteList";
import CustomerDetail from "./components/CustomerDetail";
import LicenseCertificationPage from "./screens/LicenseCertificationPage";

const App = () => {
  const [relayEnvironment, setRelayEnvironment] = useState(
    useMemo(() => createRelayEnvironment(), [])
  );
  const onReset = () => {
    setAuthToken(null);
    setRelayEnvironment(createRelayEnvironment());
  };
  const [authToken, setAuthToken] = useLocalStorageState<string | null>(
    USER_TOKEN_LOCAL_STORAGE_KEY
  );
  return (
    <RecoilRoot>
      {/* <RecoilRelayEnvironmentProvider
        environment={relayEnvironment}
        environmentKey={environmentKey}
      > */}
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#37B076",
              colorLink: "#37B076",
              colorLinkHover: "#71b98c",
            },
            components: {
              Tag: {
                borderRadiusSM: 1,
              },
              //   Collapse: {
              //     colorFillAlter: "#FAFAFA",
              //     borderRadiusLG: 0,
              //   },
              //   Menu: {
              //     colorItemBgSelected: "transparent",
              //     colorItemTextSelected: "rgb(114,235,81)", //"#37B076",
              //     radiusItem: 0,
              //   },
            },
          }}
        >
          <Suspense fallback={<FullScreenLoadingFallback />}>
            <AuthProviderWithErrorBoundary
              onLogin={() => {}}
              onLogout={() => {
                console.log("### onLogout");
                onReset();
              }}
              onInvalidToken={() => {
                console.log("### onInvalidToken");
                // console.log("onInvalidToken");
                onReset();
              }}
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <MainLayout />
                    </RequireAuth>
                  }
                >
                  <Route path="/" element={<Home />} />
                  <Route path="/sites" element={<SiteList />} />
                  <Route path="/sites/:siteObjectId" element={<SiteDetail />} />
                  <Route
                    path="/sites/:siteObjectId/:licenseObjectId"
                    element={<LicenseCertificationPage />}
                  />
                  <Route path="/licenses" element={<LicenseListScreen />} />
                  <Route path="/customers" element={<CustomerList />} />
                  <Route
                    path="/customers/:customerObjectId"
                    element={<CustomerDetail />}
                  />
                  <Route path="/contacts" element={<ContactList />} />
                </Route>
                <Route path="login" element={<Login />} />
                <Route path="*" element={<NoMatch />} />
              </Routes>
            </AuthProviderWithErrorBoundary>
          </Suspense>
        </ConfigProvider>
      </RelayEnvironmentProvider>
      {/* </RecoilRelayEnvironmentProvider> */}
    </RecoilRoot>
  );
};

export default App;

import {
  Environment,
  Network,
  RecordSource,
  Store,
  FetchFunction,
} from "relay-runtime";
import _ from "lodash";
import { getCurrentUserTokenOnOutsideOfReact } from "./hooks/auth";
import { EnvironmentKey } from "recoil-relay";

const GRAPHQL_SERVER_URL = process.env.REACT_APP_API_ENDPOINT + "/graphql/";
const SERVER_APP_ID = process.env.REACT_APP_API_SERVER_ID || "myAppId";

export const environmentKey = new EnvironmentKey("Backend.AI Environment");
//@ts-ignore
const fetchRelay: FetchFunction = async (
  params,
  variables,
  cacheConfig,
  uploadables
) => {
  // console.debug(
  //   `fetching query ${params.name} with ${JSON.stringify(variables)} ${
  //     params.text
  //   }`
  // );
  // console.log(cacheConfig, uploadables);

  // default headers
  // TODO: 필요시 현재 사용자 sessionToken headers 지정

  const token = getCurrentUserTokenOnOutsideOfReact();
  const headers: {
    [key in string]: string;
  } = token
    ? {
        "X-Parse-Application-Id": SERVER_APP_ID,
        "X-Parse-Session-Token": token,
      }
    : {
        "X-Parse-Application-Id": SERVER_APP_ID,
      };

  let body: any;
  if (uploadables) {
    body = body = new FormData();
    body.append(
      "operations",
      JSON.stringify({
        query: params.text,
        variables,
      })
    );

    const keys = _.keys(uploadables);
    const map = _.reduce(
      keys,
      (result, v, idx) => {
        result[idx.toString()] = [v];
        return result;
      },
      {} as {
        [key: string]: string[];
      }
    );
    body.append("map", JSON.stringify(map));

    _.each(keys, (v, idx) => {
      // body.append(idx.toString(), uploadables[v]);
      body.append(`${idx}`, uploadables[v]);
    });
  } else {
    headers["Content-Type"] = "application/json";
    body = JSON.stringify({
      query: params.text,
      variables,
    });
  }

  return fetch(GRAPHQL_SERVER_URL, {
    method: "POST",
    headers,
    body: body,
  }).then(async (response) => {
    if (response.status === 400) {
      return response
        .clone()
        .json()
        .catch(() => {
          // handle when response is text.
          const errorText = response.text();
          throw errorText;
        })
        .then((res) => {
          // TODO: to set the rule of error message with ErrorBoundary
          if (res.code === 209) {
            throw new Error("InvalidSessionToken");
          }
          throw res;
        });
    } else {
      const data = await response.json();
      // if (data.errors && data.errors.length) {
      //   throw data.errors;
      // }

      return data;
    }
  });
  // .catch((err) => {
  //   console.log(err);
  // });
};

// Export a singleton instance of Relay Environment configured with our network function:
export const createRelayEnvironment = () => {
  return new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
    // handlerProvider: null
  });
};

/**
 * @generated SignedSource<<998c879f4b828714502bf9d7f52a25dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserView_user$data = {
  readonly id: string;
  readonly name: string | null;
  readonly username: string | null;
  readonly " $fragmentType": "UserView_user";
};
export type UserView_user$key = {
  readonly " $data"?: UserView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserView_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "82fd16b6a5491f48691588ba60587f32";

export default node;

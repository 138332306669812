import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { useParams } from "react-router-dom";
import { CustomerDetailQuery } from "./__generated__/CustomerDetailQuery.graphql";
import { Button, Descriptions, Typography } from "antd";
import { CarOutlined, EditOutlined } from "@ant-design/icons";
import BackOfficeBreadcrumb from "./BackOfficeBreadcrumb";
import Flex from "./Flex";
import WhiteSpace from "./WhiteSpace";
import { CustomerFormModalCustomerFragment$key } from "./modals/__generated__/CustomerFormModalCustomerFragment.graphql";
import CustomerFormModal from "./modals/CustomerFormModal";
import { useToggle } from "ahooks";
import UsersOfObjectRoleView from "./UsersOfObjectRoleView";
import { useAuth } from "../hooks/auth";
const CustomerDetail = () => {
  const { roleNames } = useAuth();
  const { customerObjectId } = useParams<"customerObjectId">();
  const [isOpenForm, { toggle: toggleOpenForm, set: setIsOpenForm }] =
    useToggle(false);
  const { customer } = useLazyLoadQuery<CustomerDetailQuery>(
    graphql`
      query CustomerDetailQuery($id: ID!) {
        customer(id: $id) {
          id
          name
          description
          ...CustomerFormModalCustomerFragment
        }
      }
    `,
    {
      id: customerObjectId || "not-exist",
    }
  );
  return (
    <>
      <Typography.Title level={2}>
        <CarOutlined /> Customer : {customer.name}
      </Typography.Title>
      <Flex direction="column" align="stretch">
        <BackOfficeBreadcrumb />
        <WhiteSpace />
        <Flex justify="between">
          <Typography.Title level={4}>Info</Typography.Title>
          <Button
            onClick={() => {
              toggleOpenForm();
            }}
            icon={<EditOutlined />}
          >
            Edit
          </Button>
        </Flex>
      </Flex>
      <WhiteSpace />
      <div>
        <Descriptions
          size="small"
          bordered
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
          <Descriptions.Item label="Name">{customer.name}</Descriptions.Item>
          <Descriptions.Item label="Description" span={3}>
            {customer.description || ""}
          </Descriptions.Item>
          {roleNames.includes("accountManager") && (
            <Descriptions.Item label="Additional authorized ">
              <UsersOfObjectRoleView
                className="Customer"
                objectId={customerObjectId || ""}
              />
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
      <CustomerFormModal
        open={isOpenForm}
        customerFrgmt={customer}
        onRequestClose={(completed) => {
          toggleOpenForm();
          // if (completed) {
          //   updateFetchKey();
          //   message.success("save successfully");
          // }
        }}
      />
    </>
  );
};

export default CustomerDetail;

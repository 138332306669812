/**
 * @generated SignedSource<<2821b28465b75ed0aa508b1d65fc8912>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UsersOfObjectRoleViewRemoveMutation$variables = {
  id: string;
  userIds?: ReadonlyArray<string> | null;
};
export type UsersOfObjectRoleViewRemoveMutation$data = {
  readonly updateRole: {
    readonly role: {
      readonly id: string;
      readonly objectId: string;
    };
  } | null;
};
export type UsersOfObjectRoleViewRemoveMutation = {
  response: UsersOfObjectRoleViewRemoveMutation$data;
  variables: UsersOfObjectRoleViewRemoveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "remove",
                    "variableName": "userIds"
                  }
                ],
                "kind": "ObjectValue",
                "name": "users"
              }
            ],
            "kind": "ObjectValue",
            "name": "fields"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateRolePayload",
    "kind": "LinkedField",
    "name": "updateRole",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "objectId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersOfObjectRoleViewRemoveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UsersOfObjectRoleViewRemoveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4e0fdd484efaf44b187eae626a3fe3c9",
    "id": null,
    "metadata": {},
    "name": "UsersOfObjectRoleViewRemoveMutation",
    "operationKind": "mutation",
    "text": "mutation UsersOfObjectRoleViewRemoveMutation(\n  $id: ID!\n  $userIds: [ID!]\n) {\n  updateRole(input: {id: $id, fields: {users: {remove: $userIds}}}) {\n    role {\n      id\n      objectId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c15fec403bff26675934e4bac00a4636";

export default node;

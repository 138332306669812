import React from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { LicenseDetailFragment$key } from "./__generated__/LicenseDetailFragment.graphql";
import Flex from "./Flex";
import {
  Button,
  Descriptions,
  Spin,
  Tag,
  theme,
  Tooltip,
  Typography,
} from "antd";
import CodeButtons from "./CodeButtons";
import _ from "lodash";
import WhiteSpace from "./WhiteSpace";
import { DownloadOutlined, StarOutlined } from "@ant-design/icons";
import LicenseFormModal from "./modals/LicenseFormModal";
import { useToggle } from "ahooks";
import dayjs from "dayjs";
import JSZip from "jszip";
import { useNavigate } from "react-router-dom";

interface LicenseDetailProps {
  licenseFrgmt: LicenseDetailFragment$key | null;
  loading?: boolean;
  titleExtraContent?: React.ReactNode;
}
const LicenseDetail: React.FC<LicenseDetailProps> = ({
  licenseFrgmt,
  loading = false,
  titleExtraContent,
}) => {
  const { token } = theme.useToken();
  const [openLicenseModal, { toggle: toggleOpenLicenseModal }] =
    useToggle(false);
  const navigate = useNavigate();
  const license = useFragment(
    graphql`
      fragment LicenseDetailFragment on License {
        id
        objectId
        name
        description
        site {
          id
          objectId
          name
          mainLicense {
            id
          }
          ...LicenseFormModalSiteFragment
        }
        expirationDate
        licenseInfo
        hardwareInfoList {
          __typename
          ... on Element {
            value
          }
        }
        hardwareSummary
        numGPUs
        numCPUs
        status
        ...LicenseFormModalLicenseFragment
      }
    `,
    licenseFrgmt
  );

  const licenseFileName = `${_.snakeCase(license?.site.name)}-${_.snakeCase(
    license?.name || license?.objectId
  )}-${license?.expirationDate}.json`;
  const hwInfoZipFileName = `${_.snakeCase(license?.site.name)}-${_.snakeCase(
    license?.name || license?.objectId
  )}-hw-info.zip`;

  const downloadAllHardwareInfoAsZip = () => {
    var zip = new JSZip();

    _.each(license?.hardwareInfoList, (hardwareInfo) => {
      if (hardwareInfo?.__typename === "Element") {
        zip.file(
          hardwareInfo.value?.name,
          JSON.stringify(hardwareInfo.value?.content, null, 2)
        );
      }
    });
    zip.generateAsync({ type: "blob" }).then(function (content) {
      const element = document.createElement("a");
      const file = new Blob([content], {
        type: "application/zip",
      });
      const url = URL.createObjectURL(file);
      element.href = url;
      element.download = hwInfoZipFileName;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      URL.revokeObjectURL(url);
    });
  };
  return (
    <Flex
      style={{
        background: token.colorBgContainer,
        padding: token.paddingSM,
        borderRadius: token.borderRadius,
      }}
      align="stretch"
      direction="column"
    >
      <Spin spinning={loading} indicator={<span></span>}>
        <Flex direction="row" justify="between">
          <Typography.Title level={5}>License Detail</Typography.Title>
          <Flex>{titleExtraContent}</Flex>
        </Flex>
        {license ? (
          <Descriptions
            bordered
            size="small"
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Object Id">
              {license?.objectId}
            </Descriptions.Item>
            <Descriptions.Item label="Expiration">
              {license.expirationDate &&
                dayjs(license.expirationDate).format("ll")}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              <Tag>{_.capitalize(license.status + "")}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Name">
              <Flex direction="row">
                {license?.name}
                <WhiteSpace direction="row" size="xs" />
                {license.site.mainLicense?.id === license.id && (
                  <Tag color="gold" icon={<StarOutlined />}>
                    Main
                  </Tag>
                )}
              </Flex>
            </Descriptions.Item>
            <Descriptions.Item label="Description" span={3}>
              {license.description}
            </Descriptions.Item>

            <Descriptions.Item label="License" span={3}>
              {license.licenseInfo ? (
                <Flex direction="row">
                  {licenseFileName}
                  <WhiteSpace direction="row" />
                  <CodeButtons
                    code={JSON.stringify(license?.licenseInfo, null, 2)}
                    filename={licenseFileName}
                    language="json"
                  />
                </Flex>
              ) : (
                "-"
              )}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Flex gap="xs">
                  Hardware files{" "}
                  <Tooltip title="Download all files as a zip.">
                    <Button
                      icon={<DownloadOutlined />}
                      onClick={downloadAllHardwareInfoAsZip}
                    >
                      ZIP
                    </Button>
                  </Tooltip>
                </Flex>
              }
              span={3}
            >
              {license.status === "draft" &&
              license.hardwareInfoList?.length === 0 ? (
                <Button
                  icon=""
                  onClick={() => {
                    toggleOpenLicenseModal();
                  }}
                >
                  Upload hardware files
                </Button>
              ) : (
                <Flex direction="row" wrap="wrap">
                  {_.map(license.hardwareInfoList, (hwInfo, idx) => {
                    //@ts-ignore
                    const fileName = hwInfo?.value?.name;
                    //@ts-ignore
                    const content = hwInfo?.value?.content;
                    return (
                      <li key={fileName + idx}>
                        <Flex direction="row">
                          {fileName}
                          <WhiteSpace direction="row" size="xs" />
                          <CodeButtons
                            buttonProps={{
                              size: "small",
                            }}
                            code={JSON.stringify(content, null, 2)}
                            filename={fileName}
                            language="json"
                          />
                          {_.last(license.hardwareInfoList) !== hwInfo && ","}
                          <WhiteSpace direction="row" />
                        </Flex>
                      </li>
                    );
                  })}
                </Flex>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="CPUs">
              {license.hardwareSummary?.numCPUs || "0"}
            </Descriptions.Item>
            <Descriptions.Item label="Accelerators">
              {_.map(license.hardwareSummary?.accelerators, (value, key) => {
                return (
                  <React.Fragment key={key}>
                    {key}: {value}
                    <br />
                  </React.Fragment>
                );
              })}
            </Descriptions.Item>
            <Descriptions.Item label="Models">
              {_.map(license.hardwareSummary?.modelNames, (value, key) => {
                return (
                  <React.Fragment key={key}>
                    {key}: {value}
                    <br />
                  </React.Fragment>
                );
              })}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Number of GPU/CPU">
              {license.numCPUs || "0"} CPU(s)/ {license.numGPUs || "0"} GPU(s)
            </Descriptions.Item> */}
          </Descriptions>
        ) : (
          <Typography.Text>No license selected</Typography.Text>
        )}
      </Spin>
      <LicenseFormModal
        open={openLicenseModal}
        // siteId={site.id}
        siteFrgmt={license?.site || null}
        licenseFrgmt={license || null}
        onRequestClose={(completed) => {
          console.log("completed", completed);
          if (completed) {
            // updateLicenseListFetchKey();
          }
          toggleOpenLicenseModal();
        }}
        destroyOnClose
      />
    </Flex>
  );
};

export default LicenseDetail;

import React, { useTransition } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { UsersOfObjectRoleViewQuery } from "./__generated__/UsersOfObjectRoleViewQuery.graphql";
import _ from "lodash";
import { Alert, Button, Checkbox, Popconfirm, Table, Tooltip } from "antd";
import Flex from "./Flex";
import { PlusOutlined } from "@ant-design/icons";
import UserNameWithHoverDetail from "./UserNameWithHoverDetail";
import { UsersOfObjectRoleViewRemoveMutation } from "./__generated__/UsersOfObjectRoleViewRemoveMutation.graphql";
import { UsersOfObjectRoleViewAddMutation } from "./__generated__/UsersOfObjectRoleViewAddMutation.graphql";
import { useUpdatableState } from "../hooks";
import UserSelectModal from "./UserSelectModal";
import { useToggle } from "ahooks";

const UsersOfObjectRoleView: React.FC<{
  objectId: string;
  className: string;
}> = ({ className, objectId }) => {
  const [fetchKey, updateFetchKey] = useUpdatableState("fetch");
  const [isOpenUserSelectModal, { toggle: toggleOpenUserSelectModal }] =
    useToggle(false);
  const { readerRoles, writerRoles } =
    useLazyLoadQuery<UsersOfObjectRoleViewQuery>(
      graphql`
        query UsersOfObjectRoleViewQuery(
          $readerRoleName: String!
          $writerRoleName: String!
        ) {
          readerRoles: roles(where: { name: { equalTo: $readerRoleName } }) {
            count
            edges {
              node {
                id
                objectId
                name
                users {
                  count
                  edges {
                    node {
                      id
                      objectId
                      ...UserNameWithHoverDetailFragment
                      email
                    }
                  }
                }
              }
            }
          }
          writerRoles: roles(where: { name: { equalTo: $writerRoleName } }) {
            count
            edges {
              node {
                id
                objectId
                name
                users {
                  count
                  edges {
                    node {
                      id
                      objectId
                      ...UserNameWithHoverDetailFragment
                      email
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        readerRoleName: `${className}Reader-${objectId}`,
        writerRoleName: `${className}Writer-${objectId}`,
      },
      {
        fetchKey: fetchKey,
        fetchPolicy: "network-only",
      }
    );

  const [isPendingTransition, startTransition] = useTransition();

  const readerRole = _.first(readerRoles.edges)?.node;
  const writerRole = _.first(writerRoles.edges)?.node;

  const readerItems = _.map(readerRole?.users?.edges, (userEdge) => {
    return {
      userId: userEdge?.node?.id,
      user: userEdge?.node,
      readerRole: readerRole,
    };
  });
  const writerItems = _.map(writerRole?.users?.edges, (userEdge) => {
    return {
      userId: userEdge?.node?.id,
      user: userEdge?.node,
      writerRole: writerRole,
    };
  });

  const dataSource = _.chain([...readerItems, ...writerItems])
    .groupBy((item) => item.userId)
    .mapValues((items) => _.merge({}, ...items))
    .values()
    .value();

  const [addUserToRoleMutation, isInFlightAddUserToRole] =
    useMutation<UsersOfObjectRoleViewAddMutation>(graphql`
      mutation UsersOfObjectRoleViewAddMutation($id: ID!, $userIds: [ID!]) {
        updateRole(input: { id: $id, fields: { users: { add: $userIds } } }) {
          role {
            id
            objectId
          }
        }
      }
    `);

  const [removeUserFromRoleMutation, isInFlightRemoveUserFromRole] =
    useMutation<UsersOfObjectRoleViewRemoveMutation>(graphql`
      mutation UsersOfObjectRoleViewRemoveMutation($id: ID!, $userIds: [ID!]) {
        updateRole(
          input: { id: $id, fields: { users: { remove: $userIds } } }
        ) {
          role {
            id
            objectId
          }
        }
      }
    `);

  return (
    <>
      <Table
        loading={
          isInFlightAddUserToRole ||
          isInFlightRemoveUserFromRole ||
          isPendingTransition
        }
        size="small"
        dataSource={dataSource}
        rowKey={"userId"}
        columns={[
          {
            title: (
              <Flex justify="between">
                <span>Users</span>
                <Tooltip title="Not implemented yet.">
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      toggleOpenUserSelectModal();
                    }}
                  />
                </Tooltip>
              </Flex>
            ),
            render(value, record) {
              return (
                <UserNameWithHoverDetail userFrgmt={record.user || null} />
              );
            },
          },
          {
            title: `R (${readerRole?.users.count || 0})`,
            render: (value, record) => {
              const hasRead = record.readerRole !== undefined;
              return (
                <Popconfirm
                  title="Permission change"
                  description={`Are you sure you want to change the permission?`}
                  onConfirm={() => {
                    const options = {
                      variables: {
                        id: readerRole?.id || "",
                        userIds: record.user.objectId
                          ? [record.user.objectId]
                          : [],
                      },
                      onCompleted: () => {
                        startTransition(() => {
                          updateFetchKey();
                        });
                      },
                    };

                    if (hasRead) {
                      removeUserFromRoleMutation(options);
                    } else {
                      addUserToRoleMutation(options);
                    }
                  }}
                  // onCancel={}
                  okText="Yes"
                  cancelText="No"
                >
                  <Checkbox
                    checked={hasRead}
                    // onChange={() => {

                    // }}
                  />
                </Popconfirm>
              );
            },
          },
          {
            title: `W (${writerRole?.users.count || 0})`,
            render: (value, record) => {
              const hasWrite = record.writerRole !== undefined;
              return (
                <Popconfirm
                  title="Permission change"
                  description={`Are you sure you want to change the permission?`}
                  onConfirm={() => {
                    const options = {
                      variables: {
                        id: writerRole?.id || "",
                        userIds: record.user.objectId
                          ? [record.user.objectId]
                          : [],
                      },
                      onCompleted: () => {
                        startTransition(() => {
                          updateFetchKey();
                        });
                      },
                    };
                    if (hasWrite) {
                      removeUserFromRoleMutation(options);
                    } else {
                      addUserToRoleMutation(options);
                    }
                  }}
                >
                  <Checkbox checked={hasWrite} />
                </Popconfirm>
              );
            },
          },
        ]}
        pagination={false}
      />
      <UserSelectModal
        title="Select users to add to role"
        selectProps={{
          mode: "multiple",
        }}
        description={
          className === "Site" ? (
            <Alert
              type="warning"
              showIcon
              message="When you add a user to a Site role, that user will be granted reader permission for the customer associated with that site."
            />
          ) : null
        }
        open={isOpenUserSelectModal}
        onOk={(ids) => {
          addUserToRoleMutation({
            variables: {
              id: readerRole?.id || "",
              userIds: ids,
            },
            onCompleted: () => {
              startTransition(() => {
                updateFetchKey();
              });
            },
          });
          toggleOpenUserSelectModal();
        }}
        onCancel={() => {
          toggleOpenUserSelectModal();
        }}
      />
    </>
  );
};

export default UsersOfObjectRoleView;

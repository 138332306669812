/**
 * @generated SignedSource<<d5df141994e4693cbce8b08241423f73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LicenseMiniViewFragment$data = {
  readonly expirationDate: any;
  readonly id: string;
  readonly objectId: string;
  readonly status: string | null;
  readonly " $fragmentType": "LicenseMiniViewFragment";
};
export type LicenseMiniViewFragment$key = {
  readonly " $data"?: LicenseMiniViewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LicenseMiniViewFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LicenseMiniViewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expirationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "License",
  "abstractKey": null
};

(node as any).hash = "e74f5dd58bbc75c908c4ef56d7d541b3";

export default node;

import React, { ReactElement, useEffect } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { Navigate, useLocation } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useLocalStorageState } from "ahooks";
import {
  authMutation,
  authMutation$data,
} from "./__generated__/authMutation.graphql";
import { authQuery } from "./__generated__/authQuery.graphql";
import {
  authUserFragment$data,
  authUserFragment$key,
} from "./__generated__/authUserFragment.graphql";
import { ErrorBoundary } from "react-error-boundary";
import { Button, Result } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { PayloadError } from "relay-runtime";
import {
  authLogoutMutation,
  authLogoutMutation$data,
} from "./__generated__/authLogoutMutation.graphql";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../msAuth/authConfig";
import { openJSONErrorModal } from "../helpers";
import {
  authLoginWithMutation,
  authLoginWithMutation$data,
} from "./__generated__/authLoginWithMutation.graphql";

interface AuthContextType {
  user: authUserFragment$data | null;
  roleNames: readonly (string | null)[];
  login: (
    username: string,
    password: string,
    callback: (
      data?: authMutation$data | null,
      error?: PayloadError[] | null
    ) => void
  ) => void;
  loginViaMS: (
    callback: (
      data?: authLoginWithMutation$data | null,
      error?: PayloadError[] | null
    ) => void
  ) => void;
  logOutViaMS: () => void;
  logout: (callback?: VoidFunction) => void;
  isInFlightLogIn: boolean;
  isInFlightLogout: boolean;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export const USER_TOKEN_LOCAL_STORAGE_KEY = "recoil-current-user-token";
// const currentUserAuthTokenState = atom({
//   key: "CurrentUserToken",
//   default: null,
//   effects: [localStorageEffect<string | null>(USER_TOKEN_LOCAL_STORAGE_KEY)],
// });

export const getCurrentUserTokenOnOutsideOfReact = () => {
  const savedValue = localStorage.getItem(USER_TOKEN_LOCAL_STORAGE_KEY);
  if (savedValue != null) {
    return JSON.parse(savedValue) || null;
  }
  return null;
};

interface AuthProviderProps {
  children: ReactElement;
  onLogin: VoidFunction;
  onLogout: VoidFunction;
  onInvalidToken: VoidFunction;
  invalidTokenFallback?: ReactElement;
}

const _esm = (x: any) => x.default;

// const viewerQuery = graphQLSelector({
//   key: "viewer",
//   environment: environmentKey,
//   //https://github.com/facebookexperimental/Recoil/issues/1998
//   // TODO: fix via eagerEsModules option
//   query: _esm(graphql`
//     query authViewQuery {
//       viewer {
//         user {
//           id
//         }
//         sessionToken
//       }
//     }
//   `),
//   variables: () => ({}),
//   mapResponse: (data) => {
//     return data.viewer;
//   },
// });

export const AuthProviderWithErrorBoundary: React.FC<AuthProviderProps> = (
  props
) => {
  const [authToken, setAuthToken] = useLocalStorageState<string | null>(
    USER_TOKEN_LOCAL_STORAGE_KEY
  );
  return (
    <ErrorBoundary
      onError={(error, info) => {
        if (error.message === "InvalidSessionToken") {
          props.onInvalidToken();
          setAuthToken(null);
        } else {
          throw error;
        }
      }}
      fallbackRender={({ error, resetErrorBoundary }) => {
        return (
          <Result
            status="warning"
            title="There is a problem with your request."
            extra={
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  resetErrorBoundary();
                  window.location.href = "/";
                }}
                icon={<ReloadOutlined />}
              >
                Reload the page
              </Button>
            }
          >
            <div className="desc">
              <h3>Error Details</h3>
              <p>{error.message}</p>
            </div>
          </Result>
        );
      }}
    >
      <AuthProvider {...props} />
    </ErrorBoundary>
  );
};
export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  onLogin,
  onLogout,
  onInvalidToken,
}) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const [authToken, setAuthToken] = useLocalStorageState<string | null>(
    USER_TOKEN_LOCAL_STORAGE_KEY
  );

  const { viewer } = useLazyLoadQuery<authQuery>(
    graphql`
      query authQuery($skip: Boolean!) {
        viewer @skip(if: $skip) {
          user {
            id
            objectId
            ...authUserFragment
          }
          sessionToken
          roleNames
        }
      }
    `,
    {
      skip: !authToken,
    },
    {
      fetchKey: authToken || "",
      fetchPolicy: "network-only",
    }
  );

  const user = useFragment(
    graphql`
      fragment authUserFragment on User {
        ...UserView_user
      }
    `,
    (viewer?.user || null) as authUserFragment$key | null
  );

  const [commitLogIn, isInFlightLogIn] = useMutation<authMutation>(graphql`
    mutation authMutation($username: String!, $password: String!) {
      logIn(input: { username: $username, password: $password }) {
        viewer {
          user {
            id
            username
            ...authUserFragment
          }
          sessionToken
        }
      }
    }
  `);

  const [commitLogInWith, isInFlightLogInWith] =
    useMutation<authLoginWithMutation>(graphql`
      mutation authLoginWithMutation($input: LogInWithInput!) {
        logInWith(input: $input) {
          viewer {
            user {
              id
              username
              ...authUserFragment
            }
            sessionToken
          }
        }
      }
    `);

  const [commitLogOut, isInFlightLogout] =
    useMutation<authLogoutMutation>(graphql`
      mutation authLogoutMutation {
        logOut(input: {}) {
          ok
        }
      }
    `);

  const loginViaMS = (
    callback?: (
      data: authLoginWithMutation$data | null,
      error: PayloadError[] | null
    ) => void
  ) => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        // console.log(response);
        // console.log(response.accessToken);
        commitLogInWith({
          variables: {
            input: {
              authData: {
                microsoft: {
                  id: response.uniqueId, // "user's microsoft id (string)", // required
                  access_token: response.accessToken,
                },
              },
            },
          },
          onCompleted: (data, error) => {
            setAuthToken(data.logInWith?.viewer.sessionToken || null);
            callback && callback(data, error);
          },
          onError: (error) => {
            callback && callback(null, error as any);
          },
        });
      })
      .catch((error) => {
        openJSONErrorModal(error);
      });
  };

  const logOutViaMS = () => {
    instance
      .logoutPopup()
      .then((res) => console.log(res))
      .catch((e) => openJSONErrorModal(e));
  };
  const login = (
    username: string,
    password: string,
    callback?: (
      data: authMutation$data | null,
      error: PayloadError[] | null
    ) => void
  ) => {
    throw "Prevent login";
    // commitLogIn({
    //   variables: {
    //     username,
    //     password,
    //   },
    //   onCompleted: (data, error) => {
    //     setAuthToken(data.logIn?.viewer.sessionToken || null);
    //     callback && callback(data, error);
    //   },
    //   onError: (error) => {
    //     callback && callback(null, error as any);
    //   },
    // });
  };

  const logout = (
    callback?: (
      data: authLogoutMutation$data,
      error: PayloadError[] | null
    ) => void
  ) => {
    instance.logoutPopup().then((res) => {
      commitLogOut({
        variables: {},
        onCompleted(data, errors) {
          if (data.logOut?.ok) {
            setAuthToken(null);
            onLogout();
          }
          callback && callback(data, errors);
        },
      });
    });
  };

  const value = {
    user,
    roleNames: viewer?.roleNames || [],
    login,
    loginViaMS,
    logout,
    logOutViaMS,
    isInFlightLogIn,
    isInFlightLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

/**
 * @generated SignedSource<<f4003e1b9884a4d5354c97283f595970>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LogInWithInput = {
  authData: any;
  clientMutationId?: string | null;
  fields?: UserLoginWithInput | null;
};
export type UserLoginWithInput = {
  ACL?: ACLInput | null;
  email?: string | null;
  emailVerified?: boolean | null;
  name?: string | null;
  protectedFields?: string | null;
};
export type ACLInput = {
  public?: PublicACLInput | null;
  roles?: ReadonlyArray<RoleACLInput> | null;
  users?: ReadonlyArray<UserACLInput> | null;
};
export type UserACLInput = {
  read: boolean;
  userId: string;
  write: boolean;
};
export type RoleACLInput = {
  read: boolean;
  roleName: string;
  write: boolean;
};
export type PublicACLInput = {
  read: boolean;
  write: boolean;
};
export type authLoginWithMutation$variables = {
  input: LogInWithInput;
};
export type authLoginWithMutation$data = {
  readonly logInWith: {
    readonly viewer: {
      readonly sessionToken: string;
      readonly user: {
        readonly id: string;
        readonly username: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"authUserFragment">;
      };
    };
  } | null;
};
export type authLoginWithMutation = {
  response: authLoginWithMutation$data;
  variables: authLoginWithMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sessionToken",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authLoginWithMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LogInWithPayload",
        "kind": "LinkedField",
        "name": "logInWith",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "authUserFragment"
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authLoginWithMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LogInWithPayload",
        "kind": "LinkedField",
        "name": "logInWith",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6284ac2ccd4918281172e4104342c0e6",
    "id": null,
    "metadata": {},
    "name": "authLoginWithMutation",
    "operationKind": "mutation",
    "text": "mutation authLoginWithMutation(\n  $input: LogInWithInput!\n) {\n  logInWith(input: $input) {\n    viewer {\n      user {\n        id\n        username\n        ...authUserFragment\n      }\n      sessionToken\n    }\n  }\n}\n\nfragment UserView_user on User {\n  id\n  username\n  name\n}\n\nfragment authUserFragment on User {\n  ...UserView_user\n}\n"
  }
};
})();

(node as any).hash = "4a16ab3117958bf4320e691a95b66956";

export default node;

import React from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { RoleUserViewFragment$key } from "./__generated__/RoleUserViewFragment.graphql";
import { Typography } from "antd";
import _ from "lodash";

const RoleUserView: React.FC<{
  viewerFrgmt: RoleUserViewFragment$key;
}> = ({ viewerFrgmt }) => {
  const viewer = useFragment(
    graphql`
      fragment RoleUserViewFragment on Viewer {
        roleNames
      }
    `,
    viewerFrgmt
  );

  const customRoleNames = _.map(
    _.filter(viewer.roleNames, (r) => r && r[0] === r[0].toLowerCase()),
    _.startCase
  );
  const roleNamesStr = customRoleNames.length ? customRoleNames[0] : "No Role";

  return (
    <Typography.Text
      type="secondary"
      style={{ fontSize: 11 }}
      title={viewer.roleNames?.join(", ")}
    >
      {roleNamesStr}{" "}
      {customRoleNames.length > 1 ? `(+${customRoleNames.length - 1})` : ""}
    </Typography.Text>
  );
};

export default RoleUserView;

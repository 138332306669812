/**
 * @generated SignedSource<<de763b9a34e974f190851689ff43b974>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateLicenseInput = {
  clientMutationId?: string | null;
  fields?: CreateLicenseFieldsInput | null;
};
export type CreateLicenseFieldsInput = {
  ACL?: ACLInput | null;
  author?: UserPointerInput | null;
  certificateOptions?: any | null;
  description?: string | null;
  disabled?: boolean | null;
  expirationDate: any;
  hardwareInfoList?: ReadonlyArray<any | null> | null;
  hardwareSummary?: any | null;
  licenseInfo?: any | null;
  licenseType?: string | null;
  name?: string | null;
  numCPUs?: number | null;
  numGPUs?: number | null;
  numNPUs?: number | null;
  pemInfo?: any | null;
  site: SitePointerInput;
  status?: string | null;
  type?: string | null;
};
export type ACLInput = {
  public?: PublicACLInput | null;
  roles?: ReadonlyArray<RoleACLInput> | null;
  users?: ReadonlyArray<UserACLInput> | null;
};
export type UserACLInput = {
  read: boolean;
  userId: string;
  write: boolean;
};
export type RoleACLInput = {
  read: boolean;
  roleName: string;
  write: boolean;
};
export type PublicACLInput = {
  read: boolean;
  write: boolean;
};
export type UserPointerInput = {
  createAndLink?: CreateUserFieldsInput | null;
  link?: string | null;
};
export type CreateUserFieldsInput = {
  ACL?: ACLInput | null;
  authData?: any | null;
  email?: string | null;
  emailVerified?: boolean | null;
  name?: string | null;
  password?: string | null;
  protectedFields?: string | null;
  username?: string | null;
};
export type SitePointerInput = {
  createAndLink?: CreateSiteFieldsInput | null;
  link?: string | null;
};
export type CreateSiteFieldsInput = {
  ACL?: ACLInput | null;
  assignee?: UserPointerInput | null;
  author?: UserPointerInput | null;
  contacts?: ContactRelationInput | null;
  contractExpirationDate?: any | null;
  contractProductType?: string | null;
  contractStartDate?: any | null;
  customer?: CustomerPointerInput | null;
  deletedAt?: any | null;
  deletedBy?: UserPointerInput | null;
  description?: string | null;
  isDeleted?: boolean | null;
  mainLicense?: LicensePointerInput | null;
  name: string;
  numCPUs?: number | null;
  numGPUs?: number | null;
  numNPUs?: number | null;
  purchaseOrderId?: string | null;
  supportExpirationDate?: any | null;
};
export type ContactRelationInput = {
  add?: ReadonlyArray<string> | null;
  createAndAdd?: ReadonlyArray<CreateContactFieldsInput> | null;
  remove?: ReadonlyArray<string> | null;
};
export type CreateContactFieldsInput = {
  ACL?: ACLInput | null;
  author?: UserPointerInput | null;
  customer?: CustomerPointerInput | null;
  description?: string | null;
  email: string;
  name: string;
  phone?: string | null;
};
export type CustomerPointerInput = {
  createAndLink?: CreateCustomerFieldsInput | null;
  link?: string | null;
};
export type CreateCustomerFieldsInput = {
  ACL?: ACLInput | null;
  author?: UserPointerInput | null;
  description?: string | null;
  name: string;
};
export type LicensePointerInput = {
  createAndLink?: CreateLicenseFieldsInput | null;
  link?: string | null;
};
export type LicenseFormModalMutation$variables = {
  input: CreateLicenseInput;
};
export type LicenseFormModalMutation$data = {
  readonly createLicense: {
    readonly license: {
      readonly author: {
        readonly id: string;
        readonly username: string | null;
      } | null;
      readonly description: string | null;
      readonly expirationDate: any;
      readonly hardwareInfoList: ReadonlyArray<{
        readonly value?: any;
      } | null> | null;
      readonly id: string;
      readonly licenseInfo: any | null;
      readonly name: string | null;
      readonly numCPUs: number | null;
      readonly numGPUs: number | null;
      readonly site: {
        readonly id: string;
        readonly mainLicense: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"LicenseDetailFragment" | "LicenseFormModalLicenseFragment">;
        } | null;
      };
      readonly type: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"LicenseDetailFragment" | "LicenseFormModalLicenseFragment">;
    };
  } | null;
};
export type LicenseFormModalMutation = {
  response: LicenseFormModalMutation$data;
  variables: LicenseFormModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "Element",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "licenseInfo",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numCPUs",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numGPUs",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "LicenseDetailFragment"
},
v12 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "LicenseFormModalLicenseFragment"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expirationDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "hardwareInfoList",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v2/*: any*/)
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractExpirationDate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hardwareSummary",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LicenseFormModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateLicensePayload",
        "kind": "LinkedField",
        "name": "createLicense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "License",
            "kind": "LinkedField",
            "name": "license",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "hardwareInfoList",
                "plural": true,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "License",
                    "kind": "LinkedField",
                    "name": "mainLicense",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LicenseFormModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateLicensePayload",
        "kind": "LinkedField",
        "name": "createLicense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "License",
            "kind": "LinkedField",
            "name": "license",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v14/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "License",
                    "kind": "LinkedField",
                    "name": "mainLicense",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v15/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Site",
                        "kind": "LinkedField",
                        "name": "site",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v15/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "License",
                            "kind": "LinkedField",
                            "name": "mainLicense",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v16/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/),
                      (v6/*: any*/),
                      (v14/*: any*/),
                      (v17/*: any*/),
                      (v9/*: any*/),
                      (v8/*: any*/),
                      (v18/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  (v3/*: any*/),
                  (v16/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v15/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6217e6e5dc1a3a78263d0b1e78dbac3a",
    "id": null,
    "metadata": {},
    "name": "LicenseFormModalMutation",
    "operationKind": "mutation",
    "text": "mutation LicenseFormModalMutation(\n  $input: CreateLicenseInput!\n) {\n  createLicense(input: $input) {\n    license {\n      id\n      name\n      description\n      hardwareInfoList {\n        __typename\n        ... on Element {\n          value\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      licenseInfo\n      type\n      numCPUs\n      numGPUs\n      author {\n        id\n        username\n      }\n      site {\n        id\n        mainLicense {\n          id\n          ...LicenseDetailFragment\n          ...LicenseFormModalLicenseFragment\n        }\n      }\n      expirationDate\n      ...LicenseDetailFragment\n      ...LicenseFormModalLicenseFragment\n    }\n  }\n}\n\nfragment LicenseDetailFragment on License {\n  id\n  objectId\n  name\n  description\n  site {\n    id\n    objectId\n    name\n    mainLicense {\n      id\n    }\n    ...LicenseFormModalSiteFragment\n  }\n  expirationDate\n  licenseInfo\n  hardwareInfoList {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  hardwareSummary\n  numGPUs\n  numCPUs\n  status\n  ...LicenseFormModalLicenseFragment\n}\n\nfragment LicenseFormModalLicenseFragment on License {\n  id\n  name\n  description\n  hardwareInfoList {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  type\n  expirationDate\n  numCPUs\n  numGPUs\n  site {\n    id\n    name\n    numCPUs\n    numGPUs\n    mainLicense {\n      id\n      numCPUs\n      numGPUs\n      hardwareInfoList {\n        __typename\n        ... on Element {\n          value\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment LicenseFormModalSiteFragment on Site {\n  id\n  contractExpirationDate\n}\n"
  }
};
})();

(node as any).hash = "19b561f6d389a87d993dfc14dbbb3897";

export default node;

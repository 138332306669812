import { useDynamicList } from "ahooks";
import _ from "lodash";
import { useReducer } from "react";

const DEFAULT_FIRST = 10;
const DEFAULT_SKIP = 0;
export const useParseAntdTablePagination = <
  // WhereInput,
  Order
>(initialValues: {
  order?: Order[];
  // "This is the number of objects that must be skipped to return."
  skip?: number;
  // "Returns the items in the list that come after the specified cursor."
  after?: number;
  // "Returns the first n items from the list."
  first?: number;
  // "Returns the items in the list that come before the specified cursor."
  before?: string;
  // "Returns the last n items from the list."
  last?: number;
}) => {
  const [state, dispatch] = useReducer(
    (state: typeof initialValues, newState: Partial<typeof initialValues>) => {
      return {
        ...state,
        ...newState,
      };
    },
    initialValues
  );

  const antdTableParams = {
    pageSize: state.first,
    current:
      ((state.skip || DEFAULT_SKIP) + (state.first || DEFAULT_FIRST)) /
      (state.first || DEFAULT_FIRST),
    sortOrder: _.chain(state.order)
      .map((x) => String(x).split("_"))
      .keyBy("0")
      .mapValues("1")
      .mapValues((x) => (x === "DESC" ? "descend" : "ascend"))
      .value(),
  };

  const onChangeAntdTable = (
    { pageSize = DEFAULT_FIRST, current = 1 },
    sorter: any
  ) => {
    dispatch({
      skip: (current - 1) * pageSize || 0,
      first: pageSize,
      //@ts-ignore
      order: _.castArray(sorter)
        .filter((s) => s.field && s.order)
        .map((s) => `${s.field}_${s.order === "ascend" ? "ASC" : "DESC"}`),
    });
  };

  return [
    {
      parse: state,
      antd: antdTableParams,
    },
    {
      updateState: dispatch,
      onChangeAntdTable,
    },
  ] as const;
};

export const useWhereInputList = <WhereInput>(initialValues: WhereInput[]) => {
  const { list, remove, push } = useDynamicList<WhereInput>(initialValues);

  return [
    list,
    {
      remove,
      push,
    },
  ] as const;
};

/**
 * @generated SignedSource<<aaf3cdb6b0971d5eb17df190f0ce8835>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LicenseFormModalSiteFragment$data = {
  readonly contractExpirationDate: any | null;
  readonly id: string;
  readonly " $fragmentType": "LicenseFormModalSiteFragment";
};
export type LicenseFormModalSiteFragment$key = {
  readonly " $data"?: LicenseFormModalSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LicenseFormModalSiteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LicenseFormModalSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractExpirationDate",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "1b1873b5e0b0df9b7400a3d6eecc7c7c";

export default node;

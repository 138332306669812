import {
  Button,
  Divider,
  RefSelectProps,
  Select,
  SelectProps,
  Space,
  Typography,
} from "antd";
import React, { useDeferredValue, useState, useTransition } from "react";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  ContactSelectQuery,
  ContactWhereInput,
} from "./__generated__/ContactSelectQuery.graphql";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import ContactFormModal from "./modals/ContactFormModal";

interface ContactSelectProps extends SelectProps {
  baseWhere?: ContactWhereInput;
  onChangeSelectedWhere?: (whereInput: ContactWhereInput[] | null) => void;
  value?: string[];
}

const ContactSelect = React.forwardRef<RefSelectProps, ContactSelectProps>(
  ({ baseWhere, onChangeSelectedWhere, onChange, ...selectProps }, ref) => {
    const [searchValue, setSearchValue] = useState("");

    const searchWhere: ContactWhereInput = {
      name: {
        matchesRegex: "(?i)" + searchValue,
      },
    };

    const deferredValue = useDeferredValue(selectProps.value);
    const currentValueWhere: ContactWhereInput[] | null =
      deferredValue && searchValue.length === 0
        ? [
            {
              id: {
                in: deferredValue,
              },
            },
          ]
        : null;

    const { contacts } = useLazyLoadQuery<ContactSelectQuery>(
      graphql`
        query ContactSelectQuery($where: [ContactWhereInput!]!) {
          contacts(where: { OR: $where }) {
            edges {
              node {
                id
                objectId
                name
                email
              }
            }
          }
        }
      `,
      {
        where: [
          ...(currentValueWhere ? currentValueWhere : []),
          {
            AND: [
              ...(baseWhere ? [baseWhere] : []),
              ...(searchWhere ? [searchWhere] : []),
            ],
          },
        ],
      },
      {
        fetchPolicy: "store-and-network",
      }
    );

    const [isPending, startTransition] = useTransition();
    const [isOpenContactFormModal, setIsOpenContactFormModal] = useState(false);

    const _mergedChange: SelectProps["onChange"] = (value, options) => {
      onChange && onChange(value, options);
      onChangeSelectedWhere &&
        onChangeSelectedWhere(
          value.length > 0
            ? value.map((id: string) => ({
                id: {
                  equalTo: id,
                },
              }))
            : null
        );
    };
    return (
      <>
        <Select
          ref={ref}
          onChange={_mergedChange}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setIsOpenContactFormModal(true);
                  }}
                >
                  Add new contact
                </Button>
              </Space>
            </>
          )}
          showSearch
          filterOption={false}
          onSearch={(value) => {
            startTransition(() => {
              setSearchValue(value);
            });
          }}
          {...selectProps}
        >
          {_.map(contacts.edges, (edge) => {
            return (
              <Select.Option key={edge?.node?.id} value={edge?.node?.id}>
                {edge?.node?.name}{" "}
                <Typography.Text type="secondary">
                  {edge?.node?.email}
                </Typography.Text>
              </Select.Option>
            );
          })}
        </Select>
        <ContactFormModal
          open={isOpenContactFormModal}
          customerId={baseWhere?.customer?.have?.id?.equalTo || undefined}
          onRequestClose={(contactId) => {
            setIsOpenContactFormModal(false);
            if (contactId) {
              if (selectProps.mode === "multiple") {
                _mergedChange([...(selectProps.value || []), contactId], {
                  label: contactId,
                });
              } else {
                _mergedChange(contactId, {
                  label: contactId,
                });
              }

              // updateFetchKey();
              // message.success("save successfully");
            }
          }}
        />
      </>
    );
  }
);

export default ContactSelect;

import React from "react";
import { useFragment } from "react-relay";

import graphql from "babel-plugin-relay/macro";
import { UserNameWithHoverDetailFragment$key } from "./__generated__/UserNameWithHoverDetailFragment.graphql";
import Flex from "./Flex";
import { Popover, Typography } from "antd";
const UserNameWithHoverDetail: React.FC<{
  userFrgmt: UserNameWithHoverDetailFragment$key | null;
}> = ({ userFrgmt }) => {
  const user = useFragment(
    graphql`
      fragment UserNameWithHoverDetailFragment on User {
        id
        name
        email
      }
    `,
    userFrgmt
  );
  return (
    <Popover
      trigger={["hover", "click"]}
      mouseEnterDelay={1}
      content={
        <Flex>
          <Typography.Text copyable>{user?.email}</Typography.Text>
          {/* <Typography.Text>{user?.id}</Typography.Text> */}
        </Flex>
      }
    >
      <Flex>{user?.name}</Flex>
    </Popover>
  );
};

export default UserNameWithHoverDetail;

import { Table, Tag } from "antd";
import React, { useState, useTransition } from "react";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  LicenseListQuery,
  LicenseOrder,
  LicenseWhereInput,
} from "./__generated__/LicenseListQuery.graphql";
import {
  extractNonNullableNodesOnConnection,
  mergeWhereInputWithAND as mergeWhereInputUnderAND,
} from "../helpers";
import { useParseAntdTablePagination } from "../hooks/parseQueryAntdTable";
import dayjs from "dayjs";
import _ from "lodash";
import WhiteSpace from "./WhiteSpace";
import Flex from "./Flex";
import { StarOutlined } from "@ant-design/icons";
import UserNameWithHoverDetail from "./UserNameWithHoverDetail";

export interface LicenseListProps {
  where?: LicenseWhereInput;
  fetchKey?: string;
  selectedLicenseId?: string;
  mainId?: string;
  onSelectLicense?: (licenseId: string) => void;
}
const LicenseList: React.FC<LicenseListProps> = ({
  where,
  selectedLicenseId,
  fetchKey = "default",
  mainId,
  onSelectLicense,
}) => {
  const [paginationState, paginationAction] =
    useParseAntdTablePagination<LicenseOrder>({
      after: 0,
      skip: 0,
      first: 10,
      order: ["createdAt_DESC", "updatedAt_DESC"],
    });
  const { licenses } = useLazyLoadQuery<LicenseListQuery>(
    graphql`
      query LicenseListQuery(
        $where: LicenseWhereInput
        $order: [LicenseOrder!]
      ) {
        licenses(where: $where, order: $order) {
          count
          edges {
            node {
              id
              objectId
              name
              description
              expirationDate
              disabled
              status
              createdAt
              site {
                id
                name
                objectId
                customer {
                  id
                  name
                }
              }
              author {
                ...UserNameWithHoverDetailFragment
              }
            }
          }
        }
      }
    `,
    {
      where: mergeWhereInputUnderAND(where),
      ...paginationState.parse,
    },
    {
      fetchKey: fetchKey,
      fetchPolicy: "store-and-network",
    }
  );
  const [isPendingLoading, startLoadingTransition] = useTransition();

  // const rowSelection = {
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
  //     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  //   },
  //   getCheckboxProps: (record: DataType) => ({
  //     disabled: record.name === 'Disabled User', // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // };

  const onChange = (licenseId: string) => {
    onSelectLicense && onSelectLicense(licenseId);
  };

  return (
    <Table
      rowKey={(record) => record.id}
      loading={isPendingLoading}
      rowSelection={{
        type: "radio",
        onChange: (selectedRowKeys: React.Key[]) => {
          onChange(selectedRowKeys[0] as string);
        },
        selectedRowKeys: selectedLicenseId ? [selectedLicenseId] : [],
      }}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            onChange(record.id);
          },
        };
      }}
      pagination={{
        pageSize: paginationState.antd.pageSize,
        current: paginationState.antd.current,
        total: licenses?.count || 0,
        showSizeChanger: true,
        showTotal(total, range) {
          return `${range[0]}-${range[1]} of ${total} items`;
        },
      }}
      onChange={(pagination, filter, sorter) => {
        startLoadingTransition(() => {
          paginationAction.onChangeAntdTable(pagination, sorter);
        });
      }}
      columns={[
        {
          title: "ObjectId",
          dataIndex: "objectId",
        },
        {
          title: "Name",
          dataIndex: "name",
          render(value, record, index) {
            return (
              <Flex direction="row">
                {value}
                {mainId === record.id && (
                  <>
                    <WhiteSpace size="xs" direction="row" />
                    <Tag color="gold" icon={<StarOutlined />}>
                      Main
                    </Tag>
                  </>
                )}
              </Flex>
            );
          },
        },
        // {
        //   title: "Description",
        //   dataIndex: "description",
        // },
        {
          title: "Status",
          dataIndex: "status",
          render(value, record, index) {
            return (
              <>
                <Tag color={value === "issued" ? "success" : undefined}>
                  {_.capitalize(value || "draft")}
                </Tag>
              </>
            );
          },
        },

        // {
        //   title: "Site",
        //   dataIndex: ["site", "name"],
        // },
        // {
        //   title: "Customer",
        //   dataIndex: ["site", "customer", "name"],
        // },
        {
          title: "Expires",
          dataIndex: "expirationDate",
          render(value, record, index) {
            return value && dayjs(value).format("ll");
          },
        },
        {
          title: "Author",
          render: (value, record, index) => (
            <UserNameWithHoverDetail userFrgmt={record.author} />
          ),
        },
        {
          title: "Created",
          dataIndex: "createdAt",
          render(value, record, index) {
            return dayjs(value).format("lll");
          },
        },
      ]}
      dataSource={extractNonNullableNodesOnConnection(licenses)}
      sortDirections={["descend", "ascend", null]}
    />
  );
};

export default LicenseList;

import { PlusOutlined, PhoneOutlined } from "@ant-design/icons";
import {
  Row,
  Button,
  Col,
  Table,
  Typography,
  Input,
  Select,
  message,
  theme,
} from "antd";
import dayjs from "dayjs";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  ContactListQuery,
  ContactOrder,
  ContactWhereInput,
  CustomerWhereInput,
} from "./__generated__/ContactListQuery.graphql";
import _ from "lodash";
import {
  extractNonNullableNodesOnConnection,
  mergeWhereInputWithAND,
} from "../helpers";
import { useState, useTransition } from "react";
import {
  useParseAntdTablePagination,
  useWhereInputList,
} from "../hooks/parseQueryAntdTable";
import Flex from "../components/Flex";
import WhiteSpace from "../components/WhiteSpace";
import WhereInputTagList from "../components/WhereInputTagList";
import { useUpdateEffect } from "ahooks";
import ContactFormModal from "../components/modals/ContactFormModal";
import { useUpdatableState } from "../hooks";
import BackOfficeBreadcrumb from "../components/BackOfficeBreadcrumb";
import { JsonParam, useQueryParams, withDefault } from "use-query-params";
import { useNavigate } from "react-router-dom";
import { ContactFormModalContactFragment$key } from "../components/modals/__generated__/ContactFormModalContactFragment.graphql";
import CustomerSelect from "../components/CustomerSelect";
import UserNameWithHoverDetail from "../components/UserNameWithHoverDetail";
const { Title } = Typography;

const paginationParams = withDefault(JsonParam, {
  after: 0,
  skip: 0,
  first: 10,
  order: ["updatedAt_DESC"],
});
const whereParams = withDefault(JsonParam, []);
const ContactList = () => {
  const [query, setQuery] = useQueryParams({
    pagination: paginationParams,
    where: whereParams,
    customer: JsonParam,
  });

  const { token } = theme.useToken();
  const [fetchKey, updateFetchKey] = useUpdatableState("default");
  const [paginationState, paginationAction] =
    useParseAntdTablePagination<ContactOrder>(query.pagination);
  const [whereInputs, whereInputAction] = useWhereInputList<ContactWhereInput>(
    query.where
  );
  const [selectedCustomerWhere, setSelectedCustomerWhere] =
    useState<CustomerWhereInput | null>(query.customer);
  const navigate = useNavigate();
  const [editingContactFrgmt, setEditingContactFrgmt] =
    useState<ContactFormModalContactFragment$key>();

  useUpdateEffect(() => {
    setQuery({ where: whereInputs, customer: selectedCustomerWhere });
  }, [
    JSON.stringify(paginationState.parse),
    JSON.stringify(whereInputs),
    JSON.stringify(selectedCustomerWhere),
  ]);

  const mergedWhereInput = mergeWhereInputWithAND<ContactWhereInput>(
    ...whereInputs,
    selectedCustomerWhere && {
      customer: {
        have: selectedCustomerWhere,
      },
    }
  );

  const { contacts } = useLazyLoadQuery<ContactListQuery>(
    graphql`
      query ContactListQuery(
        $first: Int
        $skip: Int
        $where: ContactWhereInput
        $order: [ContactOrder!]
      ) {
        contacts(first: $first, skip: $skip, order: $order, where: $where) {
          count
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
          }
          edges {
            node {
              id
              objectId
              createdAt
              updatedAt
              name
              customer {
                id
                name
              }
              #   description
              author {
                id
                username
                name
                ...UserNameWithHoverDetailFragment
              }
              phone
              email
              ...ContactFormModalContactFragment
            }
          }
        }
      }
    `,
    {
      where: mergedWhereInput,
      ...paginationState.parse,
    },
    {
      fetchKey,
      fetchPolicy: "store-and-network",
    }
  );

  const [isPendingLoading, startLoadingTransition] = useTransition();
  const [isWherePending, startWhereTransition] = useTransition();
  const [searchField, setSearchField] = useState("name");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isOpenForm, setIsOpenForm] = useState(false);
  return (
    <>
      <Title level={2}>
        <PhoneOutlined /> Contact
      </Title>
      <Flex direction="column" align="stretch">
        <BackOfficeBreadcrumb />
        <WhiteSpace />
        <Flex direction="row" justify="between">
          <Flex>
            <Input.Search
              loading={isWherePending}
              addonBefore={
                <Select
                  value={searchField}
                  onSelect={setSearchField}
                  showSearch
                  style={{ width: 80 }}
                >
                  <Select.Option value="name">Name</Select.Option>
                  <Select.Option value="email">Email</Select.Option>
                  <Select.Option value="phone">Phone</Select.Option>
                </Select>
              }
              placeholder={`Search by ${searchField}`}
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              onSearch={(value, event) => {
                if (value.length > 0) {
                  startWhereTransition(() => {
                    whereInputAction.push({
                      [searchField]: {
                        matchesRegex: "(?i)" + value,
                      },
                    });
                  });
                  setSearchKeyword("");
                }
              }}
            />
            <WhiteSpace direction="row" />
            <CustomerSelect
              style={{ width: 150 }}
              loading={isWherePending}
              placeholder="Customer Filter"
              defaultValue={query.customer?.id?.equalTo}
              allowClear
              onChangeSelectedWhere={(where) => {
                startWhereTransition(() => {
                  setSelectedCustomerWhere(where);
                });
              }}
            />
          </Flex>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsOpenForm(true);
            }}
          >
            Create New
          </Button>
        </Flex>
        <WhiteSpace />
        {whereInputs.length > 0 && (
          <>
            <WhereInputTagList
              whereInputs={whereInputs}
              onRemove={(i) => {
                startWhereTransition(() => {
                  whereInputAction.remove(i);
                });
              }}
            />
            <WhiteSpace />
          </>
        )}
        <Row>
          <Col span={24}>
            <Table
              rowKey={(record) => record?.id}
              loading={isPendingLoading}
              showSorterTooltip={false}
              scroll={{ x: 1300 }}
              pagination={{
                pageSize: paginationState.antd.pageSize,
                current: paginationState.antd.current,
                total: contacts?.count || 0,
                showSizeChanger: true,
                showTotal(total, range) {
                  return `${range[0]}-${range[1]} of ${total} items`;
                },
              }}
              onChange={(pagination, filter, sorter) => {
                startLoadingTransition(() => {
                  paginationAction.onChangeAntdTable(pagination, sorter);
                });
              }}
              columns={[
                {
                  title: "Name",
                  dataIndex: "name",
                  width: 150,
                  sorter: true,
                  sortOrder: paginationState.antd.sortOrder["name"],
                  render(value, record, index) {
                    return (
                      <Flex direction="column" align="start">
                        <Typography.Link
                          onClick={() => {
                            setEditingContactFrgmt(record);
                            // moveToSiteDetail(record.objectId);
                          }}
                        >
                          {value}
                        </Typography.Link>
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: token.fontSizeSM }}
                        >
                          {record.objectId}
                        </Typography.Text>
                      </Flex>
                    );
                  },
                  fixed: "left",
                },
                {
                  title: "Customer",
                  dataIndex: ["customer", "name"],
                  // render(value, record, index) {
                  //   return (
                  //     <Typography.Link
                  //       onClick={() => {
                  //         navigate(`/customer`)
                  //         // moveToSiteDetail(record.objectId);
                  //       }}
                  //     >
                  //       {value}
                  //     </Typography.Link>
                  //   );
                  // },
                },
                {
                  title: "Email",
                  dataIndex: "email",
                  sorter: true,
                  sortOrder: paginationState.antd.sortOrder["email"],
                },
                {
                  title: "Phone",
                  dataIndex: "phone",
                },
                {
                  title: "Created",
                  dataIndex: "createdAt",
                  render(value, record, index) {
                    return dayjs(value).format("ll");
                  },
                  sorter: true,
                  sortOrder: paginationState.antd.sortOrder["createdAt"],
                  responsive: ["xl"],
                },

                {
                  title: "Author",
                  render(value, record, index) {
                    return (
                      <UserNameWithHoverDetail
                        userFrgmt={record.author || null}
                      />
                    );
                  },
                },
              ]}
              dataSource={extractNonNullableNodesOnConnection(contacts)}
              sortDirections={["descend", "ascend", null]}
            />
          </Col>
        </Row>
      </Flex>
      <ContactFormModal
        open={isOpenForm || editingContactFrgmt !== undefined}
        contactFrgmt={editingContactFrgmt}
        onRequestClose={(completed) => {
          setEditingContactFrgmt(undefined);
          setIsOpenForm(false);
          if (completed) {
            updateFetchKey();
            message.success("save successfully");
          }
        }}
      />
    </>
  );
};

export default ContactList;

/**
 * @generated SignedSource<<00f266ecb8b7e6eca77bf3cf89b1f176>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateContactInput = {
  clientMutationId?: string | null;
  fields?: CreateContactFieldsInput | null;
};
export type CreateContactFieldsInput = {
  ACL?: ACLInput | null;
  author?: UserPointerInput | null;
  customer?: CustomerPointerInput | null;
  description?: string | null;
  email: string;
  name: string;
  phone?: string | null;
};
export type ACLInput = {
  public?: PublicACLInput | null;
  roles?: ReadonlyArray<RoleACLInput> | null;
  users?: ReadonlyArray<UserACLInput> | null;
};
export type UserACLInput = {
  read: boolean;
  userId: string;
  write: boolean;
};
export type RoleACLInput = {
  read: boolean;
  roleName: string;
  write: boolean;
};
export type PublicACLInput = {
  read: boolean;
  write: boolean;
};
export type UserPointerInput = {
  createAndLink?: CreateUserFieldsInput | null;
  link?: string | null;
};
export type CreateUserFieldsInput = {
  ACL?: ACLInput | null;
  authData?: any | null;
  email?: string | null;
  emailVerified?: boolean | null;
  name?: string | null;
  password?: string | null;
  protectedFields?: string | null;
  username?: string | null;
};
export type CustomerPointerInput = {
  createAndLink?: CreateCustomerFieldsInput | null;
  link?: string | null;
};
export type CreateCustomerFieldsInput = {
  ACL?: ACLInput | null;
  author?: UserPointerInput | null;
  description?: string | null;
  name: string;
};
export type ContactFormModalMutation$variables = {
  input: CreateContactInput;
};
export type ContactFormModalMutation$data = {
  readonly createContact: {
    readonly contact: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ContactFormModalContactFragment">;
    };
  } | null;
};
export type ContactFormModalMutation = {
  response: ContactFormModalMutation$data;
  variables: ContactFormModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactFormModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateContactPayload",
        "kind": "LinkedField",
        "name": "createContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ContactFormModalContactFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactFormModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateContactPayload",
        "kind": "LinkedField",
        "name": "createContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1676707979d04e8264b81906820c2f26",
    "id": null,
    "metadata": {},
    "name": "ContactFormModalMutation",
    "operationKind": "mutation",
    "text": "mutation ContactFormModalMutation(\n  $input: CreateContactInput!\n) {\n  createContact(input: $input) {\n    contact {\n      id\n      ...ContactFormModalContactFragment\n    }\n  }\n}\n\nfragment ContactFormModalContactFragment on Contact {\n  id\n  name\n  email\n  customer {\n    id\n    name\n  }\n  author {\n    id\n    username\n  }\n  phone\n  description\n}\n"
  }
};
})();

(node as any).hash = "ef22d080741b2347b81c47b7369811c5";

export default node;
